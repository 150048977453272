import React from 'react';
import { Platform } from 'react-native';

const { ConnectBtn } = Platform.select({
  ios: () => require('./StripeConnectNative'),
  android: () => require('./StripeConnectNative'),
  web: () => require('./StripeConnectWeb'),
})();

const { PaymentBtn } = Platform.select({
  ios: () => require('./StripePaymentNative'),
  android: () => require('./StripePaymentNative'),
  web: () => require('./StripePaymentWeb'),
})();

// console.log('import', Object.keys(Btn))
export const ConnectButton = props => <ConnectBtn {...props} />;

export const PaymentButton = props => <PaymentBtn {...props} />;
