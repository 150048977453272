const calculateParagraphHeight = (screenWidth, text) => {
  const padding = 16 * 2; // Total horizontal padding
  const usableWidth = screenWidth - padding; // Usable width within the container
  const fontSize = 16; // Default font size in pixels
  const lineHeight = 24; // Line height in pixels

  // Calculate the approximate width of each character (simplistic approach)
  const characterWidth = fontSize * 0.6; // Estimate character width based on font size
  const charactersPerLine = Math.floor(usableWidth / characterWidth); // Max number of characters per line

  // Calculate the number of lines needed for the given text
  const lines = Math.ceil(text.length / charactersPerLine);

  // Calculate total height of the component
  const totalHeight = lines * lineHeight; // Total height based on number of lines and line height

  return totalHeight;
};

export default calculateParagraphHeight;
