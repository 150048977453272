import * as React from 'react';
import { Provider as ThemeProvider } from '@draftbit/ui';
import {
  AnalyticsProvider,
  createClient,
} from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import { useFonts } from 'expo-font';
import * as Notifications from 'expo-notifications';
import * as SplashScreen from 'expo-splash-screen';
import {
  ActivityIndicator,
  AppState,
  Platform,
  StatusBar,
  Text,
  TextInput,
  View,
} from 'react-native';
import {
  SafeAreaProvider,
  initialWindowMetrics,
} from 'react-native-safe-area-context';
import { QueryClient, QueryClientProvider } from 'react-query';
import AppNavigator, { routingInstrumentation } from './AppNavigator';
import Fonts from './config/Fonts.js';
import { GlobalVariableProvider } from './config/GlobalVariableContext';
import cacheAssetsAsync from './config/cacheAssetsAsync';
import Draftbit from './themes/Draftbit';

SplashScreen.preventAutoHideAsync();

const segmentClient = createClient({
  writeKey: '1sSyz5lTAVetS4RpyBn1zmNhrKAnr41H',
  trackAppLifecycleEvents: true,
});

Sentry.init({
  dsn: 'https://57c311884a70704fd9614d9a21f6222c@o4507928787091456.ingest.us.sentry.io/4507929213534208',
  enableInExpoDevelopment: true,
  debug: false,
  integrations: [new Sentry.ReactNativeTracing({ routingInstrumentation })],
  attachScreenshot: true,
  tracesSampleRate: 1,
  _experiments: {
    profilesSampleRate: 1,
  },
});

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

const queryClient = new QueryClient();

import FlashMessage from 'react-native-flash-message';

const App = () => {
  const [areAssetsCached, setAreAssetsCached] = React.useState(false);

  const [fontsLoaded] = useFonts({
    Poppins_400Regular: Fonts.Poppins_400Regular,
    Poppins_600SemiBold: Fonts.Poppins_600SemiBold,
    Poppins_500Medium: Fonts.Poppins_500Medium,
    Poppins_200ExtraLight: Fonts.Poppins_200ExtraLight,
    Poppins_400Regular_Italic: Fonts.Poppins_400Regular_Italic,
    Poppins_300Light: Fonts.Poppins_300Light,
    Poppins_700Bold: Fonts.Poppins_700Bold,
  });

  React.useEffect(() => {
    async function prepare() {
      try {
        await cacheAssetsAsync();
      } catch (e) {
        console.warn(e);
      } finally {
        setAreAssetsCached(true);
      }
    }

    prepare();
  }, []);

  const isReady = areAssetsCached && fontsLoaded;
  const onLayoutRootView = React.useCallback(async () => {
    if (isReady) {
      await SplashScreen.hideAsync();
    }
  }, [isReady]);

  if (!isReady) {
    return null;
  }

  return (
    <>
      {Platform.OS === 'android' ? <StatusBar translucent={true} /> : null}
      <AnalyticsProvider client={segmentClient}>
        <SafeAreaProvider
          initialMetrics={initialWindowMetrics}
          onLayout={onLayoutRootView}
        >
          <GlobalVariableProvider>
            <QueryClientProvider client={queryClient}>
              <ThemeProvider
                themes={[Draftbit]}
                breakpoints={{}}
                initialThemeName={Draftbit.name}
              >
                <AppNavigator />
                <FlashMessage position="top" />
              </ThemeProvider>
            </QueryClientProvider>
          </GlobalVariableProvider>
        </SafeAreaProvider>
      </AnalyticsProvider>
    </>
  );
};

export default Sentry.wrap(App);
