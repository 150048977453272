import * as Clipboard from 'expo-clipboard';
import * as ShowMessage from '../custom-files/ShowMessage';

const copyToClipboard = async text => {
  await Clipboard.setStringAsync(text);

  ShowMessage.myMessage({ message: `URL is copied to your clipboard` });
};

export default copyToClipboard;
