import React from 'react';
import {
  Button,
  Icon,
  LinearGradient,
  LinearProgress,
  Pressable,
  ScreenContainer,
  Surface,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BalanceServiceApi from '../apis/BalanceServiceApi.js';
import AddressPickerBlock from '../components/AddressPickerBlock';
import WideHeaderBlock from '../components/WideHeaderBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as Collapse from '../custom-files/Collapse';
import isLoggedIn from '../global-functions/isLoggedIn';
import trackAction from '../global-functions/trackAction';
import trackScreen from '../global-functions/trackScreen';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { isNearBy: false };

const AddPromotionSelectTypeScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const segment = useAnalytics();
  const [availableBalance, setAvailableBalance] = React.useState(0);
  const [balanceLoaded, setBalanceLoaded] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [selectedAddress, setSelectedAddress] = React.useState(null);
  const [showInboxTypePanel, setShowInboxTypePanel] = React.useState(false);
  const onAddressSelect = address => {
    console.log(address);

    setSelectedAddress(address);

    setProgress(25);
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (!isLoggedIn(Variables)) {
        navigation.navigate('AuthLoginScreen');
      }
      if (Constants['USER_DATA']?.role === 'user') {
        navigation.navigate('AuthLoginScreen');
      }
      trackScreen(segment, 'Add Promotion Select Type');
      if (!(props.route?.params?.isNearBy ?? defaultProps.isNearBy)) {
        setProgress(25);
      } else {
      }
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasSafeArea={true}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: [
            { minWidth: Breakpoints.Mobile, value: palettes.App.White },
            { minWidth: Breakpoints.Desktop, value: palettes.App.CustomGray },
          ],
        },
        dimensions.width
      )}
    >
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <WideHeaderBlock
            balance={availableBalance}
            currentScreen={'my_promotions'}
          />
        )}
      </>
      {/* Header */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            {...GlobalStyles.ViewStyles(theme)['Header'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['Header'].style,
              dimensions.width
            )}
          >
            {/* AbsoluteTitle */}
            <View
              {...GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].style,
                dimensions.width
              )}
            >
              <Image
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                resizeMode={'contain'}
                source={imageSource(Images['MailboxxGreenLogo'])}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { height: 30, width: 120 }
                  ),
                  dimensions.width
                )}
              />
            </View>
            {/* BackButton */}
            <Pressable
              onPress={() => {
                try {
                  if (props.route?.params?.isNearBy ?? defaultProps.isNearBy) {
                    if (selectedAddress) {
                      setProgress(0);
                      setSelectedAddress(null);
                    } else {
                      navigation.goBack();
                    }
                  } else {
                    navigation.goBack();
                  }
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              {...GlobalStyles.PressableStyles(theme)['BackButton'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.PressableStyles(theme)['BackButton'].style,
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={palettes.App['Custom Color_9']}
                  name={'Ionicons/chevron-back'}
                  size={28}
                />
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['HeaderTitleText'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['HeaderTitleText'].style,
                    dimensions.width
                  )}
                >
                  {'Back'}
                </Text>
              </View>
            </Pressable>
          </View>
        )}
      </>
      {/* Body */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignSelf: { minWidth: Breakpoints.Desktop, value: 'center' },
            backgroundColor: {
              minWidth: Breakpoints.Desktop,
              value: theme.colors.background.brand,
            },
            flex: [
              { minWidth: Breakpoints.Mobile, value: 1 },
              { minWidth: Breakpoints.Desktop, value: 1 },
            ],
            maxWidth: { minWidth: Breakpoints.Desktop, value: 1024 },
            width: { minWidth: Breakpoints.Desktop, value: '100%' },
          },
          dimensions.width
        )}
      >
        <BalanceServiceApi.FetchGetBalanceGET
          handlers={{
            onData: fetchData => {
              try {
                setAvailableBalance(fetchData?.balance);
                setBalanceLoaded(true);
              } catch (err) {
                Sentry.captureException(err);
                console.error(err);
              }
            },
          }}
        >
          {({ loading, error, data, refetchGetBalance }) => {
            const fetchData = data?.json;
            if (loading) {
              return (
                <>
                  {/* Loading */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        flex: 1,
                        padding: 20,
                      },
                      dimensions.width
                    )}
                  >
                    <ActivityIndicator
                      animating={true}
                      hidesWhenStopped={true}
                      {...GlobalStyles.ActivityIndicatorStyles(theme)[
                        'Activity Indicator'
                      ].props}
                      size={'large'}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ActivityIndicatorStyles(theme)[
                          'Activity Indicator'
                        ].style,
                        dimensions.width
                      )}
                    />
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['TitleText'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['TitleText'].style,
                          {
                            marginBottom: 20,
                            marginTop: 20,
                            textAlign: 'center',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Please wait while loading...'}
                    </Text>
                  </View>
                </>
              );
            }

            if (error || data?.status < 200 || data?.status >= 300) {
              return <ActivityIndicator />;
            }

            return (
              <>
                <LinearProgress
                  animationDuration={500}
                  color={theme.colors.branding.primary}
                  indeterminate={false}
                  isAnimated={true}
                  showTrack={true}
                  thickness={10}
                  trackColor={theme.colors.border.brand}
                  lineCap={'square'}
                  trackLineCap={'square'}
                  value={progress}
                />
                <>
                  {!(
                    (props.route?.params?.isNearBy ?? defaultProps.isNearBy) &&
                    !selectedAddress &&
                    availableBalance > 0
                  ) ? null : (
                    <AddressPickerBlock
                      onAddressSelect={address => onAddressSelect(address)}
                    />
                  )}
                </>
                {/* NearByPromotionsView */}
                <>
                  {!(
                    availableBalance > 0 &&
                    (props.route?.params?.isNearBy ?? defaultProps.isNearBy) &&
                    selectedAddress
                  ) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 1, padding: 20 },
                        dimensions.width
                      )}
                    >
                      {/* Inbox Promotion View */}
                      <Surface
                        {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
                        elevation={3}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.SurfaceStyles(theme)['Surface'].style,
                            {
                              alignItems: 'center',
                              backgroundColor: theme.colors.background.brand,
                              borderRadius: 12,
                              flex: 1,
                              marginBottom: 10,
                              overflow: 'hidden',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        <Pressable
                          onPress={() => {
                            try {
                              trackAction(
                                Variables,
                                segment,
                                'Clicked Inbox Promotion Button',
                                'Near By Promotions'
                              );
                              setShowInboxTypePanel(!showInboxTypePanel);
                            } catch (err) {
                              Sentry.captureException(err);
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        >
                          {/* Linear Gradient 2 */}
                          <LinearGradient
                            endX={100}
                            endY={100}
                            {...GlobalStyles.LinearGradientStyles(theme)[
                              'Linear Gradient'
                            ].props}
                            color1={palettes.App.White}
                            color2={theme.colors.branding.primary}
                            startX={-50}
                            startY={-50}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.LinearGradientStyles(theme)[
                                  'Linear Gradient'
                                ].style,
                                { flexDirection: 'row' }
                              ),
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                { flex: 1 },
                                dimensions.width
                              )}
                            >
                              <Image
                                {...GlobalStyles.ImageStyles(theme)['Image']
                                  .props}
                                resizeMode={'contain'}
                                source={imageSource(Images['DirectInbox'])}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ImageStyles(theme)['Image']
                                      .style,
                                    { height: '100%', width: '100%' }
                                  ),
                                  dimensions.width
                                )}
                              />
                            </View>
                            {/* View 2 */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  flex: 1,
                                  justifyContent: 'center',
                                  padding: 8,
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['TitleText']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['TitleText']
                                      .style,
                                    {
                                      color: theme.colors.text.strong,
                                      fontSize: 18,
                                      marginBottom: 5,
                                      marginTop: 10,
                                      textAlign: 'left',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Inbox Promotion'}
                              </Text>
                              <>
                                {showInboxTypePanel ? null : (
                                  <Text
                                    accessible={true}
                                    {...GlobalStyles.TextStyles(theme)['Text']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        {
                                          color: theme.colors.text.strong,
                                          fontSize: 12,
                                          textAlign: 'left',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {
                                      'Deliver targeted direct mail to users using verified demographic and geographic data. Users engage with embedded links in ads and earn $0.50 for image ads and $1 for video ads.'
                                    }
                                  </Text>
                                )}
                              </>
                            </View>
                          </LinearGradient>
                        </Pressable>
                      </Surface>
                      <Utils.CustomCodeErrorBoundary>
                        <Collapse.Panel
                          showPanel={showInboxTypePanel}
                          setShowPanel={setShowInboxTypePanel}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              { flexDirection: 'row', gap: 8, height: 350 },
                              dimensions.width
                            )}
                          >
                            {/* Inbox-Zip View */}
                            <Surface
                              {...GlobalStyles.SurfaceStyles(theme)['Surface']
                                .props}
                              elevation={3}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.SurfaceStyles(theme)['Surface']
                                    .style,
                                  {
                                    alignItems: 'center',
                                    backgroundColor: palettes.App.White,
                                    borderColor: theme.colors.border.brand,
                                    borderRadius: 12,
                                    borderWidth: 1,
                                    flex: 1,
                                    marginBottom: 10,
                                    overflow: 'hidden',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              <Pressable
                                onPress={() => {
                                  try {
                                    trackAction(
                                      Variables,
                                      segment,
                                      'Clicked Zip Promotion Button',
                                      'Near By Promotions'
                                    );
                                    navigation.navigate(
                                      'AddPromotionFormScreen',
                                      {
                                        promoType: 'zip',
                                        for_explore: false,
                                        for_category: false,
                                        selectedAddress: selectedAddress,
                                      }
                                    );
                                  } catch (err) {
                                    Sentry.captureException(err);
                                    console.error(err);
                                  }
                                }}
                                style={StyleSheet.applyWidth(
                                  { height: '100%', width: '100%' },
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    { flex: 1 },
                                    dimensions.width
                                  )}
                                >
                                  {/* ImageView */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { flex: 1 },
                                      dimensions.width
                                    )}
                                  >
                                    <Image
                                      resizeMode={'cover'}
                                      {...GlobalStyles.ImageStyles(theme)[
                                        'Image'
                                      ].props}
                                      source={imageSource(Images['InboxZip'])}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.ImageStyles(theme)[
                                            'Image'
                                          ].style,
                                          { height: '100%', width: '100%' }
                                        ),
                                        dimensions.width
                                      )}
                                    />
                                  </View>
                                  {/* TextView */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { flex: 1, padding: 8 },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'TitleText'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'TitleText'
                                          ].style,
                                          {
                                            color: theme.colors.text.strong,
                                            fontSize: 18,
                                            marginBottom: 5,
                                            marginTop: 10,
                                            textAlign: 'center',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'Zip Promotion'}
                                    </Text>

                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          {
                                            color: theme.colors.text.strong,
                                            fontSize: 12,
                                            textAlign: 'center',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {
                                        'Create a promotion that is exclusive to residents of the specified zip codes.'
                                      }
                                    </Text>
                                  </View>
                                </View>
                              </Pressable>
                            </Surface>
                            {/* Inbox-GPS View */}
                            <Surface
                              {...GlobalStyles.SurfaceStyles(theme)['Surface']
                                .props}
                              elevation={3}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.SurfaceStyles(theme)['Surface']
                                    .style,
                                  {
                                    alignItems: 'center',
                                    backgroundColor: palettes.App.White,
                                    borderColor: theme.colors.border.brand,
                                    borderRadius: 12,
                                    borderWidth: 1,
                                    flex: 1,
                                    marginBottom: 10,
                                    overflow: 'hidden',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              <Pressable
                                onPress={() => {
                                  try {
                                    trackAction(
                                      Variables,
                                      segment,
                                      'Clicked Geo Promotion Button',
                                      'Near By Promotions'
                                    );
                                    navigation.navigate(
                                      'AddPromotionFormScreen',
                                      {
                                        promoType: 'gps',
                                        for_explore: false,
                                        for_category: false,
                                        selectedAddress: selectedAddress,
                                      }
                                    );
                                  } catch (err) {
                                    Sentry.captureException(err);
                                    console.error(err);
                                  }
                                }}
                                style={StyleSheet.applyWidth(
                                  { height: '100%', width: '100%' },
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    { flex: 1 },
                                    dimensions.width
                                  )}
                                >
                                  {/* ImageView */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { flex: 1 },
                                      dimensions.width
                                    )}
                                  >
                                    <Image
                                      resizeMode={'cover'}
                                      {...GlobalStyles.ImageStyles(theme)[
                                        'Image'
                                      ].props}
                                      source={imageSource(Images['InboxGps'])}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.ImageStyles(theme)[
                                            'Image'
                                          ].style,
                                          { height: '100%', width: '100%' }
                                        ),
                                        dimensions.width
                                      )}
                                    />
                                  </View>
                                  {/* TextView */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { flex: 1, padding: 8 },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'TitleText'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'TitleText'
                                          ].style,
                                          {
                                            color: theme.colors.text.strong,
                                            fontSize: 18,
                                            marginBottom: 5,
                                            marginTop: 10,
                                            textAlign: 'center',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'Geo Promotion'}
                                    </Text>

                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          {
                                            color: theme.colors.text.strong,
                                            fontSize: 12,
                                            textAlign: 'center',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {
                                        'Create a promotion that is targeted to people who live within a certain distance of a GPS location.\n'
                                      }
                                    </Text>
                                  </View>
                                </View>
                              </Pressable>
                            </Surface>
                          </View>
                        </Collapse.Panel>
                      </Utils.CustomCodeErrorBoundary>
                      {/* Explore Promotion View */}
                      <Surface
                        {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
                        elevation={3}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.SurfaceStyles(theme)['Surface'].style,
                            {
                              alignItems: 'center',
                              backgroundColor: theme.colors.background.brand,
                              borderRadius: 12,
                              flex: 1,
                              marginBottom: 10,
                              overflow: 'hidden',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        <Pressable
                          onPress={() => {
                            try {
                              trackAction(
                                Variables,
                                segment,
                                'Clicked Explore Promotion Button',
                                'Near By Promotions'
                              );
                              navigation.navigate('AddPromotionFormScreen', {
                                promoType: 'gps',
                                for_explore: true,
                                for_category: false,
                                selectedAddress: selectedAddress,
                              });
                            } catch (err) {
                              Sentry.captureException(err);
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        >
                          <LinearGradient
                            endX={100}
                            endY={100}
                            {...GlobalStyles.LinearGradientStyles(theme)[
                              'Linear Gradient'
                            ].props}
                            color1={palettes.App.White}
                            color2={theme.colors.branding.primary}
                            startX={-10}
                            startY={-10}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.LinearGradientStyles(theme)[
                                  'Linear Gradient'
                                ].style,
                                { flexDirection: 'row' }
                              ),
                              dimensions.width
                            )}
                          >
                            {/* View 2 */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  flex: 1,
                                  justifyContent: 'center',
                                  padding: 8,
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['TitleText']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['TitleText']
                                      .style,
                                    {
                                      color: theme.colors.text.strong,
                                      fontSize: 18,
                                      marginBottom: 5,
                                      textAlign: 'right',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Explore Promotion'}
                              </Text>
                              <>
                                {showInboxTypePanel ? null : (
                                  <Text
                                    accessible={true}
                                    {...GlobalStyles.TextStyles(theme)['Text']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        {
                                          color: theme.colors.text.strong,
                                          fontSize: 12,
                                          textAlign: 'right',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {
                                      "Deliver targeted mass mailings directly to users' phones. Users earn $0.05 per ad, with verification to ensure each ad is viewed, incentivizing engagement and turning every interaction into an opportunity."
                                    }
                                  </Text>
                                )}
                              </>
                            </View>

                            <View
                              style={StyleSheet.applyWidth(
                                { flex: 1 },
                                dimensions.width
                              )}
                            >
                              <Image
                                {...GlobalStyles.ImageStyles(theme)['Image']
                                  .props}
                                resizeMode={'contain'}
                                source={imageSource(Images['ExplorePromotion'])}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ImageStyles(theme)['Image']
                                      .style,
                                    { height: '100%', width: '100%' }
                                  ),
                                  dimensions.width
                                )}
                              />
                            </View>
                          </LinearGradient>
                        </Pressable>
                      </Surface>
                      {/* Category Promotion View */}
                      <Surface
                        {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
                        elevation={3}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.SurfaceStyles(theme)['Surface'].style,
                            {
                              alignItems: 'center',
                              backgroundColor: theme.colors.background.brand,
                              borderRadius: 12,
                              flex: 1,
                              marginBottom: 10,
                              overflow: 'hidden',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        <Pressable
                          onPress={() => {
                            try {
                              trackAction(
                                Variables,
                                segment,
                                'Clicked Category Promotion Button',
                                'Near By Promotions'
                              );
                              navigation.navigate('AddPromotionFormScreen', {
                                promoType: 'gps',
                                for_explore: false,
                                for_category: true,
                                selectedAddress: selectedAddress,
                              });
                            } catch (err) {
                              Sentry.captureException(err);
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        >
                          <LinearGradient
                            endX={100}
                            endY={100}
                            {...GlobalStyles.LinearGradientStyles(theme)[
                              'Linear Gradient'
                            ].props}
                            color1={palettes.App.White}
                            color2={palettes.App.customGreen}
                            startX={-10}
                            startY={-10}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.LinearGradientStyles(theme)[
                                  'Linear Gradient'
                                ].style,
                                { flexDirection: 'row' }
                              ),
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                { flex: 1 },
                                dimensions.width
                              )}
                            >
                              <Image
                                {...GlobalStyles.ImageStyles(theme)['Image']
                                  .props}
                                resizeMode={'contain'}
                                source={imageSource(
                                  Images['CategoriesPromotion']
                                )}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ImageStyles(theme)['Image']
                                      .style,
                                    { height: '100%', width: '100%' }
                                  ),
                                  dimensions.width
                                )}
                              />
                            </View>
                            {/* View 2 */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  flex: 1,
                                  justifyContent: 'center',
                                  padding: 8,
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['TitleText']
                                  .props}
                                adjustsFontSizeToFit={true}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['TitleText']
                                      .style,
                                    {
                                      color: theme.colors.text.strong,
                                      fontSize: 18,
                                      marginBottom: 5,
                                      marginTop: 10,
                                      textAlign: 'left',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Category Promotion'}
                              </Text>
                              <>
                                {showInboxTypePanel ? null : (
                                  <Text
                                    accessible={true}
                                    {...GlobalStyles.TextStyles(theme)['Text']
                                      .props}
                                    adjustsFontSizeToFit={true}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        {
                                          color: theme.colors.text.strong,
                                          fontSize: 12,
                                          textAlign: 'left',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {
                                      'Post authentic events, job listings, and more on our platform for only $5.00 per month. By advertising on Mailboxx, your content reaches a trusted and active user base, unlike free social media sites.'
                                    }
                                  </Text>
                                )}
                              </>
                            </View>
                          </LinearGradient>
                        </Pressable>
                      </Surface>
                    </View>
                  )}
                </>
                {/* GlobalPromotions */}
                <>
                  {!(
                    availableBalance > 0 &&
                    !(props.route?.params?.isNearBy ?? defaultProps.isNearBy)
                  ) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 1, padding: 20 },
                        dimensions.width
                      )}
                    >
                      {/* Inbox Promotion View */}
                      <Surface
                        {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
                        elevation={3}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.SurfaceStyles(theme)['Surface'].style,
                            {
                              alignItems: 'center',
                              backgroundColor: theme.colors.background.brand,
                              borderRadius: 12,
                              flex: 1,
                              marginBottom: 10,
                              overflow: 'hidden',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        <Pressable
                          onPress={() => {
                            try {
                              trackAction(
                                Variables,
                                segment,
                                'Clicked Inbox Promotion Button',
                                'Global Promotions'
                              );
                              navigation.navigate('AddPromotionFormScreen', {
                                promoType: 'global',
                                for_explore: false,
                                for_category: false,
                                selectedAddress: null,
                              });
                            } catch (err) {
                              Sentry.captureException(err);
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        >
                          {/* Linear Gradient 2 */}
                          <LinearGradient
                            endX={100}
                            endY={100}
                            {...GlobalStyles.LinearGradientStyles(theme)[
                              'Linear Gradient'
                            ].props}
                            color1={palettes.App.White}
                            color2={theme.colors.branding.primary}
                            startX={-50}
                            startY={-50}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.LinearGradientStyles(theme)[
                                  'Linear Gradient'
                                ].style,
                                { flexDirection: 'row' }
                              ),
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                { flex: 1 },
                                dimensions.width
                              )}
                            >
                              <Image
                                {...GlobalStyles.ImageStyles(theme)['Image']
                                  .props}
                                resizeMode={'contain'}
                                source={imageSource(Images['DirectInbox'])}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ImageStyles(theme)['Image']
                                      .style,
                                    { height: '100%', width: '100%' }
                                  ),
                                  dimensions.width
                                )}
                              />
                            </View>
                            {/* View 2 */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  flex: 1,
                                  justifyContent: 'center',
                                  padding: 8,
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['TitleText']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['TitleText']
                                      .style,
                                    {
                                      color: theme.colors.text.strong,
                                      fontSize: 18,
                                      marginBottom: 5,
                                      marginTop: 10,
                                      textAlign: 'left',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Inbox Promotion'}
                              </Text>
                              <>
                                {showInboxTypePanel ? null : (
                                  <Text
                                    accessible={true}
                                    {...GlobalStyles.TextStyles(theme)['Text']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        {
                                          color: theme.colors.text.strong,
                                          fontSize: 12,
                                          textAlign: 'left',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {
                                      'Deliver targeted direct mail to users using verified demographic and geographic data. Users engage with embedded links in ads and earn $0.50 for image ads and $1 for video ads.'
                                    }
                                  </Text>
                                )}
                              </>
                            </View>
                          </LinearGradient>
                        </Pressable>
                      </Surface>
                      {/* Explore Promotion View */}
                      <Surface
                        {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
                        elevation={3}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.SurfaceStyles(theme)['Surface'].style,
                            {
                              alignItems: 'center',
                              backgroundColor: theme.colors.background.brand,
                              borderRadius: 12,
                              flex: 1,
                              marginBottom: 10,
                              overflow: 'hidden',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        <Pressable
                          onPress={() => {
                            try {
                              trackAction(
                                Variables,
                                segment,
                                'Clicked Explore Promotion Button',
                                'Global Promotions'
                              );
                              navigation.navigate('AddPromotionFormScreen', {
                                promoType: 'global',
                                for_explore: true,
                                for_category: false,
                                selectedAddress: null,
                              });
                            } catch (err) {
                              Sentry.captureException(err);
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        >
                          <LinearGradient
                            endX={100}
                            endY={100}
                            {...GlobalStyles.LinearGradientStyles(theme)[
                              'Linear Gradient'
                            ].props}
                            color1={palettes.App.White}
                            color2={theme.colors.branding.primary}
                            startX={-10}
                            startY={-10}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.LinearGradientStyles(theme)[
                                  'Linear Gradient'
                                ].style,
                                { flexDirection: 'row' }
                              ),
                              dimensions.width
                            )}
                          >
                            {/* View 2 */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  flex: 1,
                                  justifyContent: 'center',
                                  padding: 8,
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['TitleText']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['TitleText']
                                      .style,
                                    {
                                      color: theme.colors.text.strong,
                                      fontSize: 18,
                                      marginBottom: 5,
                                      textAlign: 'right',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Explore Promotion'}
                              </Text>
                              <>
                                {showInboxTypePanel ? null : (
                                  <Text
                                    accessible={true}
                                    {...GlobalStyles.TextStyles(theme)['Text']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        {
                                          color: theme.colors.text.strong,
                                          fontSize: 12,
                                          textAlign: 'right',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {
                                      "Deliver targeted mass mailings directly to users' phones. Users earn $0.05 per ad, with verification to ensure each ad is viewed, incentivizing engagement and turning every interaction into an opportunity."
                                    }
                                  </Text>
                                )}
                              </>
                            </View>

                            <View
                              style={StyleSheet.applyWidth(
                                { flex: 1 },
                                dimensions.width
                              )}
                            >
                              <Image
                                {...GlobalStyles.ImageStyles(theme)['Image']
                                  .props}
                                resizeMode={'contain'}
                                source={imageSource(Images['ExplorePromotion'])}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ImageStyles(theme)['Image']
                                      .style,
                                    { height: '100%', width: '100%' }
                                  ),
                                  dimensions.width
                                )}
                              />
                            </View>
                          </LinearGradient>
                        </Pressable>
                      </Surface>
                      {/* Category Promotion View */}
                      <Surface
                        {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
                        elevation={3}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.SurfaceStyles(theme)['Surface'].style,
                            {
                              alignItems: 'center',
                              backgroundColor: theme.colors.background.brand,
                              borderRadius: 12,
                              flex: 1,
                              marginBottom: 10,
                              overflow: 'hidden',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        <Pressable
                          onPress={() => {
                            try {
                              trackAction(
                                Variables,
                                segment,
                                'Clicked Category Promotion Button',
                                'Global Promotions'
                              );
                              navigation.navigate('AddPromotionFormScreen', {
                                promoType: 'global',
                                for_explore: false,
                                for_category: true,
                                selectedAddress: null,
                              });
                            } catch (err) {
                              Sentry.captureException(err);
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        >
                          <LinearGradient
                            endX={100}
                            endY={100}
                            {...GlobalStyles.LinearGradientStyles(theme)[
                              'Linear Gradient'
                            ].props}
                            color1={palettes.App.White}
                            color2={palettes.App.customGreen}
                            startX={-10}
                            startY={-10}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.LinearGradientStyles(theme)[
                                  'Linear Gradient'
                                ].style,
                                { flexDirection: 'row' }
                              ),
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                { flex: 1 },
                                dimensions.width
                              )}
                            >
                              <Image
                                {...GlobalStyles.ImageStyles(theme)['Image']
                                  .props}
                                resizeMode={'contain'}
                                source={imageSource(
                                  Images['CategoriesPromotion']
                                )}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ImageStyles(theme)['Image']
                                      .style,
                                    { height: '100%', width: '100%' }
                                  ),
                                  dimensions.width
                                )}
                              />
                            </View>
                            {/* View 2 */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  flex: 1,
                                  justifyContent: 'center',
                                  padding: 8,
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['TitleText']
                                  .props}
                                adjustsFontSizeToFit={true}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['TitleText']
                                      .style,
                                    {
                                      color: theme.colors.text.strong,
                                      fontSize: 18,
                                      marginBottom: 5,
                                      marginTop: 10,
                                      textAlign: 'left',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Category Promotion'}
                              </Text>
                              <>
                                {showInboxTypePanel ? null : (
                                  <Text
                                    accessible={true}
                                    {...GlobalStyles.TextStyles(theme)['Text']
                                      .props}
                                    adjustsFontSizeToFit={true}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        {
                                          color: theme.colors.text.strong,
                                          fontSize: 12,
                                          textAlign: 'left',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {
                                      'Post authentic events, job listings, and more on our platform for only $5.00 per month. By advertising on Mailboxx, your content reaches a trusted and active user base, unlike free social media sites.'
                                    }
                                  </Text>
                                )}
                              </>
                            </View>
                          </LinearGradient>
                        </Pressable>
                      </Surface>
                    </View>
                  )}
                </>
                {/* No Balance View */}
                <>
                  {!(availableBalance < 1000) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { alignSelf: 'center', flex: 1, padding: 20 },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['TitleText'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['TitleText'].style,
                            { marginBottom: 20, textAlign: 'center' }
                          ),
                          dimensions.width
                        )}
                      >
                        {
                          'You must at least have 10 USD in your wallet to create a promotion.\n\nPlease credit your account first'
                        }
                      </Text>
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          try {
                            trackAction(
                              Variables,
                              segment,
                              'Clicked Add Balance Button',
                              undefined
                            );
                            navigation.navigate('BalanceScreen');
                          } catch (err) {
                            Sentry.captureException(err);
                            console.error(err);
                          }
                        }}
                        {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.ButtonStyles(theme)['Button'].style,
                          dimensions.width
                        )}
                        title={'Add Balance'}
                      />
                    </View>
                  )}
                </>
              </>
            );
          }}
        </BalanceServiceApi.FetchGetBalanceGET>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(AddPromotionSelectTypeScreen);
