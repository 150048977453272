const transformBusinessCategories = arr => {
  return arr.map(a => {
    return {
      label: a.name,
      value: a.id,
    };
  });
};

export default transformBusinessCategories;
