import React from 'react';
import {
  Button,
  Divider,
  Icon,
  IconButton,
  Pressable,
  ScreenContainer,
  SimpleStyleScrollView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import {
  ActivityIndicator,
  Image,
  Modal,
  Platform,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoServiceApi from '../apis/XanoServiceApi.js';
import AddressDisplayBlock from '../components/AddressDisplayBlock';
import NarrowHeaderWithDrawerBlock from '../components/NarrowHeaderWithDrawerBlock';
import WideHeaderBlock from '../components/WideHeaderBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as DrawerScreen from '../custom-files/DrawerScreen';
import isLoggedIn from '../global-functions/isLoggedIn';
import trackAction from '../global-functions/trackAction';
import trackScreen from '../global-functions/trackScreen';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const SettingsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [primaryAddress, setPrimaryAddress] = React.useState({});
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const xanoServiceDeleteUserDELETE = XanoServiceApi.useDeleteUserDELETE();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (!isLoggedIn(Variables)) {
        navigation.navigate('AuthLoginScreen');
      }
      trackScreen(segment, 'Settings');
      setGlobalVariableValue({
        key: 'SHOW_DRAWER',
        value: false,
      });
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasSafeArea={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App.CustomGray },
        dimensions.width
      )}
    >
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <WideHeaderBlock currentScreen={'settings'} />
        )}
      </>
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <NarrowHeaderWithDrawerBlock />
        )}
      </>
      {/* Drawer */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <Utils.CustomCodeErrorBoundary>
            <DrawerScreen.DrawerView
              currentScreen="settings"
              navigation={props.navigation}
            />
          </Utils.CustomCodeErrorBoundary>
        )}
      </>
      {/* Body */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: [
              { minWidth: Breakpoints.Mobile, value: 'center' },
              { minWidth: Breakpoints.Desktop, value: 'center' },
            ],
            backgroundColor: palettes.App.CustomGray,
            flex: 1,
          },
          dimensions.width
        )}
      >
        {/* InnerBody */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: theme.colors.background.brand,
              flex: 1,
              maxWidth: 1024,
              width: '100%',
            },
            dimensions.width
          )}
        >
          {/* ScrollView */}
          <SimpleStyleScrollView
            bounces={true}
            horizontal={false}
            keyboardShouldPersistTaps={'never'}
            nestedScrollEnabled={false}
            showsHorizontalScrollIndicator={true}
            showsVerticalScrollIndicator={true}
          >
            <View
              style={StyleSheet.applyWidth(
                { marginLeft: 20, marginRight: 20, marginTop: 40 },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      fontSize: 28,
                      marginBottom: 16,
                      textAlign: 'center',
                      textTransform: 'capitalize',
                    }
                  ),
                  dimensions.width
                )}
              >
                {Constants['USER_DATA']?.first_name}{' '}
                {Constants['USER_DATA']?.last_name}
              </Text>
              {/* GetPrimaryAddressFetch */}
              <XanoServiceApi.FetchGetPrimaryAddressGET
                handlers={{
                  onData: getPrimaryAddressFetchData => {
                    try {
                      /* hidden 'Log to Console' action */
                      setGlobalVariableValue({
                        key: 'PRIMARY_ADDRESS',
                        value: getPrimaryAddressFetchData,
                      });
                    } catch (err) {
                      Sentry.captureException(err);
                      console.error(err);
                    }
                  },
                }}
              >
                {({ loading, error, data, refetchGetPrimaryAddress }) => {
                  const getPrimaryAddressFetchData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <AddressDisplayBlock
                      city={getPrimaryAddressFetchData?.city}
                      country={getPrimaryAddressFetchData?.country}
                      house_number={getPrimaryAddressFetchData?.house_number}
                      isPrimary={getPrimaryAddressFetchData?.is_primary}
                      isVerified={getPrimaryAddressFetchData?.verified}
                      road={getPrimaryAddressFetchData?.road}
                      state={getPrimaryAddressFetchData?.state}
                      title={getPrimaryAddressFetchData?.title}
                      titleColor={palettes.Brand.Primary}
                      zip={getPrimaryAddressFetchData?.zip}
                    />
                  );
                }}
              </XanoServiceApi.FetchGetPrimaryAddressGET>
            </View>
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flexDirection: 'row',
                  justifyContent: 'space-evenly',
                  marginTop: 20,
                },
                dimensions.width
              )}
            >
              <Pressable
                onPress={() => {
                  try {
                    trackAction(
                      Variables,
                      segment,
                      'Clicked Addresses Button',
                      undefined
                    );
                    navigation.navigate('AddressesScreen');
                  } catch (err) {
                    Sentry.captureException(err);
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', width: 80 },
                    dimensions.width
                  )}
                >
                  <Image
                    resizeMode={'cover'}
                    {...GlobalStyles.ImageStyles(theme)['Image'].props}
                    source={imageSource(Images['Addresses'])}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ImageStyles(theme)['Image'].style,
                      dimensions.width
                    )}
                  />
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    ellipsizeMode={'tail'}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { marginTop: 5, textAlign: 'center' }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Addresses'}
                  </Text>
                </View>
              </Pressable>
              {/* Pressable 2 */}
              <Pressable
                onPress={() => {
                  try {
                    trackAction(
                      Variables,
                      segment,
                      'Clicked Accounts Button',
                      undefined
                    );
                    navigation.navigate('BankAccountsScreen');
                  } catch (err) {
                    Sentry.captureException(err);
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', width: 80 },
                    dimensions.width
                  )}
                >
                  <Image
                    resizeMode={'cover'}
                    {...GlobalStyles.ImageStyles(theme)['Image'].props}
                    source={imageSource(Images['Wallet'])}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ImageStyles(theme)['Image'].style,
                      dimensions.width
                    )}
                  />
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { marginTop: 5, textAlign: 'center' }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Accounts'}
                  </Text>
                </View>
              </Pressable>
            </View>
            {/* View 3 */}
            <View
              style={StyleSheet.applyWidth({ marginTop: 30 }, dimensions.width)}
            >
              <View>
                <View
                  style={StyleSheet.applyWidth(
                    { backgroundColor: theme.colors.border.brand, padding: 16 },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { fontFamily: 'Poppins_600SemiBold', marginLeft: 10 }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Get Help'}
                  </Text>
                </View>
                {/* UpdateProfileRow */}
                <Pressable
                  onPress={() => {
                    try {
                      trackAction(
                        Variables,
                        segment,
                        'Clicked Update Profile Button',
                        undefined
                      );
                      navigation.navigate('ProfileUpdateScreen');
                    } catch (err) {
                      Sentry.captureException(err);
                      console.error(err);
                    }
                  }}
                >
                  {/* Row */}
                  <View
                    {...GlobalStyles.ViewStyles(theme)['SettingsRow'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ViewStyles(theme)['SettingsRow'].style,
                      dimensions.width
                    )}
                  >
                    {/* View 2 */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginLeft: 10 },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { fontSize: 16 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Update Profile'}
                      </Text>
                    </View>

                    <View>
                      <Icon
                        color={theme.colors.branding.primary}
                        name={'AntDesign/right'}
                        size={20}
                      />
                    </View>
                  </View>
                  <Divider
                    {...GlobalStyles.DividerStyles(theme)['Divider'].props}
                    color={theme.colors.border.brand}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.DividerStyles(theme)['Divider'].style,
                        { width: '100%' }
                      ),
                      dimensions.width
                    )}
                  />
                </Pressable>
                {/* ChangePasswordRow */}
                <Pressable
                  onPress={() => {
                    try {
                      trackAction(
                        Variables,
                        segment,
                        'Clicked Change Password Button',
                        undefined
                      );
                      navigation.navigate('ChangePasswordScreen');
                    } catch (err) {
                      Sentry.captureException(err);
                      console.error(err);
                    }
                  }}
                >
                  {/* Row */}
                  <View
                    {...GlobalStyles.ViewStyles(theme)['SettingsRow'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ViewStyles(theme)['SettingsRow'].style,
                      dimensions.width
                    )}
                  >
                    {/* View 2 */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginLeft: 10 },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { fontSize: 16 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Change Password'}
                      </Text>
                    </View>

                    <View>
                      <Icon
                        color={theme.colors.branding.primary}
                        name={'AntDesign/right'}
                        size={20}
                      />
                    </View>
                  </View>
                  <Divider
                    {...GlobalStyles.DividerStyles(theme)['Divider'].props}
                    color={theme.colors.border.brand}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.DividerStyles(theme)['Divider'].style,
                        { width: '100%' }
                      ),
                      dimensions.width
                    )}
                  />
                </Pressable>
                {/* NotificationsRow */}
                <>
                  {Platform.OS === 'web' ? null : (
                    <Pressable
                      onPress={() => {
                        try {
                          trackAction(
                            Variables,
                            segment,
                            'Clicked Notifications Button',
                            undefined
                          );
                          navigation.navigate('NotificationScreen');
                        } catch (err) {
                          Sentry.captureException(err);
                          console.error(err);
                        }
                      }}
                    >
                      {/* Row */}
                      <View
                        {...GlobalStyles.ViewStyles(theme)['SettingsRow'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.ViewStyles(theme)['SettingsRow'].style,
                          dimensions.width
                        )}
                      >
                        {/* View 2 */}
                        <View
                          style={StyleSheet.applyWidth(
                            { marginLeft: 10 },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                { fontSize: 16 }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Notifications'}
                          </Text>
                        </View>

                        <View>
                          <Icon
                            color={theme.colors.branding.primary}
                            name={'AntDesign/right'}
                            size={20}
                          />
                        </View>
                      </View>
                      <Divider
                        {...GlobalStyles.DividerStyles(theme)['Divider'].props}
                        color={theme.colors.border.brand}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.DividerStyles(theme)['Divider'].style,
                            { width: '100%' }
                          ),
                          dimensions.width
                        )}
                      />
                    </Pressable>
                  )}
                </>
                {/* PrivacyPolicyRow */}
                <Pressable
                  onPress={() => {
                    try {
                      trackAction(
                        Variables,
                        segment,
                        'Clicked Privacy Policy',
                        undefined
                      );
                      navigation.navigate('PrivacyPolicyScreen');
                    } catch (err) {
                      Sentry.captureException(err);
                      console.error(err);
                    }
                  }}
                >
                  {/* Row */}
                  <View
                    {...GlobalStyles.ViewStyles(theme)['SettingsRow'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ViewStyles(theme)['SettingsRow'].style,
                      dimensions.width
                    )}
                  >
                    {/* View 2 */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginLeft: 10 },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { fontSize: 16 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Privacy Policy'}
                      </Text>
                    </View>

                    <View>
                      <Icon
                        color={theme.colors.branding.primary}
                        name={'AntDesign/right'}
                        size={20}
                      />
                    </View>
                  </View>
                  <Divider
                    {...GlobalStyles.DividerStyles(theme)['Divider'].props}
                    color={theme.colors.border.brand}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.DividerStyles(theme)['Divider'].style,
                        { width: '100%' }
                      ),
                      dimensions.width
                    )}
                  />
                </Pressable>
                {/* TermsRow */}
                <Pressable
                  onPress={() => {
                    try {
                      trackAction(
                        Variables,
                        segment,
                        'Clicked Terms Button',
                        undefined
                      );
                      navigation.navigate('TermsandConditionsScreen');
                    } catch (err) {
                      Sentry.captureException(err);
                      console.error(err);
                    }
                  }}
                >
                  {/* Row */}
                  <View
                    {...GlobalStyles.ViewStyles(theme)['SettingsRow'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ViewStyles(theme)['SettingsRow'].style,
                      dimensions.width
                    )}
                  >
                    {/* View 2 */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginLeft: 10 },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { fontSize: 16 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Terms'}
                      </Text>
                    </View>

                    <View>
                      <Icon
                        color={theme.colors.branding.primary}
                        name={'AntDesign/right'}
                        size={20}
                      />
                    </View>
                  </View>
                  <Divider
                    {...GlobalStyles.DividerStyles(theme)['Divider'].props}
                    color={theme.colors.border.brand}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.DividerStyles(theme)['Divider'].style,
                        { width: '100%' }
                      ),
                      dimensions.width
                    )}
                  />
                </Pressable>
                {/* DeleteAccountRow */}
                <Pressable
                  onPress={() => {
                    try {
                      trackAction(
                        Variables,
                        segment,
                        'Clicked Deactivate Account Button',
                        undefined
                      );
                      setShowDeleteModal(true);
                      setErrorMessage('');
                    } catch (err) {
                      Sentry.captureException(err);
                      console.error(err);
                    }
                  }}
                >
                  {/* Row */}
                  <View
                    {...GlobalStyles.ViewStyles(theme)['SettingsRow'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ViewStyles(theme)['SettingsRow'].style,
                      dimensions.width
                    )}
                  >
                    {/* View 2 */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginLeft: 10 },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              color: theme.colors.background.danger,
                              fontSize: 16,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {Constants['USER_DATA']?.role === 'business'
                          ? 'Deactivate'
                          : 'Delete'}
                        {' Account'}
                      </Text>
                    </View>

                    <View>
                      <Icon
                        color={theme.colors.background.danger}
                        name={'AntDesign/right'}
                        size={20}
                      />
                    </View>
                  </View>
                  <Divider
                    {...GlobalStyles.DividerStyles(theme)['Divider'].props}
                    color={theme.colors.border.brand}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.DividerStyles(theme)['Divider'].style,
                        { width: '100%' }
                      ),
                      dimensions.width
                    )}
                  />
                </Pressable>
                {/* SignoutRow */}
                <Pressable
                  onPress={() => {
                    try {
                      trackAction(
                        Variables,
                        segment,
                        'Clicked SignOut Button',
                        undefined
                      );
                      setGlobalVariableValue({
                        key: 'AuthToken',
                        value: '',
                      });
                      setGlobalVariableValue({
                        key: 'USER_DATA',
                        value: '',
                      });
                      navigation.navigate('AuthLoginScreen');
                    } catch (err) {
                      Sentry.captureException(err);
                      console.error(err);
                    }
                  }}
                >
                  {/* Row */}
                  <View
                    {...GlobalStyles.ViewStyles(theme)['SettingsRow'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ViewStyles(theme)['SettingsRow'].style,
                      dimensions.width
                    )}
                  >
                    {/* View 2 */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginLeft: 10 },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { fontSize: 16 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Sign Out'}
                      </Text>
                    </View>

                    <View>
                      <Icon
                        color={theme.colors.branding.primary}
                        name={'AntDesign/right'}
                        size={20}
                      />
                    </View>
                  </View>
                  <Divider
                    {...GlobalStyles.DividerStyles(theme)['Divider'].props}
                    color={theme.colors.border.brand}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.DividerStyles(theme)['Divider'].style,
                        { width: '100%' }
                      ),
                      dimensions.width
                    )}
                  />
                </Pressable>
              </View>
            </View>
          </SimpleStyleScrollView>
        </View>
      </View>
      {/* DeleteModal */}
      <Modal
        animationType={'none'}
        supportedOrientations={['portrait', 'landscape']}
        transparent={true}
        visible={showDeleteModal}
      >
        {/* BGView */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              backgroundColor: palettes.App.ViewBG,
              flex: 1,
              justifyContent: 'center',
              padding: 20,
            },
            dimensions.width
          )}
        >
          {/* Container */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors.background.brand,
                borderColor: palettes.App.CustomGray,
                borderRadius: 16,
                borderWidth: 1,
                maxWidth: 400,
                width: '90%',
              },
              dimensions.width
            )}
          >
            {/* Modal Header */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  borderBottomWidth: 1,
                  borderColor: palettes.App.CustomGray,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: 16,
                  paddingBottom: 8,
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { fontFamily: 'Poppins_700Bold' }
                  ),
                  dimensions.width
                )}
              >
                {'Are you sure?'}
              </Text>
              <IconButton
                onPress={() => {
                  try {
                    setShowDeleteModal(false);
                  } catch (err) {
                    Sentry.captureException(err);
                    console.error(err);
                  }
                }}
                size={32}
                icon={'Ionicons/close'}
              />
            </View>
            {/* Modal Body */}
            <View
              style={StyleSheet.applyWidth({ padding: 16 }, dimensions.width)}
            >
              <>
                {!(Constants['USER_DATA']?.role === 'user') ? null : (
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { fontFamily: 'Poppins_400Regular' }
                      ),
                      dimensions.width
                    )}
                  >
                    {
                      'Your account is about to be DELETED. This action is irreversible. Additionally, all of your data will be removed, including addresses, saved promotions, linked bank accounts, and uploaded images.\n\nDo you want to proceed?'
                    }
                  </Text>
                )}
              </>
              {/* BusinessMsgText */}
              <>
                {!(Constants['USER_DATA']?.role === 'business') ? null : (
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { fontFamily: 'Poppins_400Regular' }
                      ),
                      dimensions.width
                    )}
                  >
                    {
                      'Your account is about to be deactivated. If you want to reactivate your account in the future, you need to send an email to help@mailboxx.app in order to reactivate your account.\n\nDo you want to proceed?'
                    }
                  </Text>
                )}
              </>
              {/* errorMessage */}
              <>
                {!errorMessage ? null : (
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { color: theme.colors.background.danger }
                      ),
                      dimensions.width
                    )}
                  >
                    {errorMessage}
                  </Text>
                )}
              </>
            </View>
            {/* Modal Footer */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  padding: 16,
                },
                dimensions.width
              )}
            >
              {/* CancelView */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, marginRight: 4 },
                  dimensions.width
                )}
              >
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      trackAction(
                        Variables,
                        segment,
                        'Clicked on Cancel',
                        undefined
                      );
                      setShowDeleteModal(false);
                    } catch (err) {
                      Sentry.captureException(err);
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['OutlineButton'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ButtonStyles(theme)['OutlineButton'].style,
                    dimensions.width
                  )}
                  title={'Cancel'}
                />
              </View>
              {/* ConfirmView */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, marginLeft: 4 },
                  dimensions.width
                )}
              >
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    const handler = async () => {
                      try {
                        setIsDeleting(true);
                        const deleteUserResult = (
                          await xanoServiceDeleteUserDELETE.mutateAsync()
                        )?.json;
                        if (deleteUserResult?.message) {
                          setErrorMessage(deleteUserResult?.message);
                        } else {
                          setGlobalVariableValue({
                            key: 'AuthToken',
                            value: '',
                          });
                          setGlobalVariableValue({
                            key: 'USER_DATA',
                            value: '',
                          });
                          setGlobalVariableValue({
                            key: 'PRIMARY_ADDRESS',
                            value: {},
                          });
                          setShowDeleteModal(false);
                          trackAction(
                            Variables,
                            segment,
                            'Account Deactivated',
                            undefined
                          );
                          navigation.navigate('AuthLoginScreen');
                        }

                        setIsDeleting(false);
                      } catch (err) {
                        Sentry.captureException(err);
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  disabled={isDeleting}
                  loading={isDeleting}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      { backgroundColor: theme.colors.background.danger }
                    ),
                    dimensions.width
                  )}
                  title={`Yes, ${
                    Constants['USER_DATA']?.role === 'business'
                      ? 'Deactivate'
                      : 'Delete'
                  }!`}
                />
              </View>
            </View>
          </View>
        </View>
      </Modal>
    </ScreenContainer>
  );
};

export default withTheme(SettingsScreen);
