import React from 'react';
import {
  Icon,
  Link,
  Pressable,
  ScreenContainer,
  SimpleStyleScrollView,
  withTheme,
} from '@draftbit/ui';
import { H2 } from '@expo/html-elements';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import * as Linking from 'expo-linking';
import { Image, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import WideHeaderBlock from '../components/WideHeaderBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import trackAction from '../global-functions/trackAction';
import trackScreen from '../global-functions/trackScreen';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const GetInContactScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const segment = useAnalytics();
  const [switchValue, setSwitchValue] = React.useState(false);
  const [textInputValue, setTextInputValue] = React.useState('');
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      trackScreen(segment, 'Get in Contact');
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasBottomSafeArea={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: [
            { minWidth: Breakpoints.Mobile, value: palettes.App.White },
            { minWidth: Breakpoints.Desktop, value: palettes.App.CustomGray },
          ],
        },
        dimensions.width
      )}
    >
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <WideHeaderBlock currentScreen={'settings'} withGoBack={true} />
        )}
      </>
      {/* HeaderLast */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            {...GlobalStyles.ViewStyles(theme)['Header'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['Header'].style,
              dimensions.width
            )}
          >
            {/* AbsoluteTitle */}
            <View
              {...GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].style,
                dimensions.width
              )}
            >
              <Image
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                resizeMode={'contain'}
                source={imageSource(Images['MailboxxGreenLogo'])}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { height: 30, width: 120 }
                  ),
                  dimensions.width
                )}
              />
            </View>
            {/* BackButton */}
            <Pressable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              {...GlobalStyles.PressableStyles(theme)['BackButton'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.PressableStyles(theme)['BackButton'].style,
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={palettes.App['Custom Color_9']}
                  name={'Ionicons/chevron-back'}
                  size={28}
                />
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['HeaderTitleText'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['HeaderTitleText'].style,
                    dimensions.width
                  )}
                >
                  {'Back'}
                </Text>
              </View>
            </Pressable>
          </View>
        )}
      </>
      {/* Body */}
      <View
        {...GlobalStyles.ViewStyles(theme)['ResponsiveBody'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['ResponsiveBody'].style,
          dimensions.width
        )}
      >
        <SimpleStyleScrollView
          bounces={true}
          horizontal={false}
          keyboardShouldPersistTaps={'never'}
          nestedScrollEnabled={false}
          showsHorizontalScrollIndicator={true}
          showsVerticalScrollIndicator={true}
          style={StyleSheet.applyWidth(
            {
              justifyContent: 'center',
              marginTop: 10,
              paddingBottom: 25,
              paddingLeft: 20,
              paddingRight: 20,
            },
            dimensions.width
          )}
        >
          <H2
            selectable={false}
            {...GlobalStyles.H2Styles(theme)['H2'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.H2Styles(theme)['H2'].style, {
                color: theme.colors.branding.primary,
                fontFamily: 'Poppins_400Regular',
                marginBottom: 24,
                marginTop: 48,
                textAlign: 'center',
              }),
              dimensions.width
            )}
          >
            {'Contact Us'}
          </H2>

          <View
            style={StyleSheet.applyWidth(
              { flexDirection: 'row', marginBottom: 20 },
              dimensions.width
            )}
          >
            {/* Label */}
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  { fontFamily: 'Poppins_600SemiBold' }
                ),
                dimensions.width
              )}
            >
              {'Email: '}
            </Text>
            <Link
              accessible={true}
              onPress={() => {
                try {
                  trackAction(
                    Variables,
                    segment,
                    'Clicked Email Link',
                    undefined
                  );
                  Linking.openURL('mailto:help@mailboxx.app');
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              {...GlobalStyles.LinkStyles(theme)['Link'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.LinkStyles(theme)['Link'].style,
                  { textDecorationLine: 'underline' }
                ),
                dimensions.width
              )}
              title={'help@mailboxx.app'}
            />
          </View>
        </SimpleStyleScrollView>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(GetInContactScreen);
