import { Platform } from 'react-native';
import * as Lib from '../custom-files/Lib';

const startNavigation = (latitude, longitude) => {
  const url = Platform.select({
    ios: `maps://app?daddr=${latitude},${longitude}`,
    android: `google.navigation:q=${latitude},${longitude}`,
    web: `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`,
  });

  Lib.Linking.openURL(url).catch(err =>
    console.error('An error occurred', err)
  );
};

export default startNavigation;
