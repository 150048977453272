import React from 'react';
import ReelsPlayer from './ReelsPlayer';

export const ReelsWrapper = ({ items, onEndReached, onPageChange }) => {
  return (
    <ReelsPlayer
      items={items || []}
      pauseOnOptionsShow={false}
      onPageChange={onPageChange}
      onEndReached={onEndReached}
    />
  );
};
