// This import is required if you are defining react components in this module.
import React, { useEffect, useRef } from 'react';
import LottieView from 'lottie-react-native';
import Animations from '../config/Animations';
import imageSource from '../utils/imageSource';

// <CustomCode.MyExampleComponent />
export const UnlockButton = props => {
  const animationRef = useRef(null);

  const [playLottie, setPlayLottie] = props.stateVariables;
  useEffect(() => {
    if (playLottie) {
      animationRef.current?.reset();
      animationRef.current?.play();
      setPlayLottie(false);
    }
  }, [playLottie]);

  return (
    <>
      <LottieView
        autoPlay={false}
        loop={false}
        ref={animationRef}
        style={{
          width: props.width || 100,
          height: props.height || 100,
          backgroundColor: props.backgroundColor || 'none',
        }}
        source={imageSource(Animations['animation'])}
        onAnimationFinish={props.onComplete}
      />
    </>
  );
};

// export const UnlockButton = ()=> null
