import React from 'react';
import {
  Button,
  Icon,
  IconButton,
  Link,
  ScreenContainer,
  SimpleStyleFlatList,
  Surface,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import {
  ActivityIndicator,
  Image,
  Keyboard,
  Modal,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoServiceApi from '../apis/XanoServiceApi.js';
import AddressDisplayBlock from '../components/AddressDisplayBlock';
import WideHeaderBlock from '../components/WideHeaderBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as DrawerScreen from '../custom-files/DrawerScreen';
import * as ScreenComponents from '../custom-files/ScreenComponents';
import calculateParagraphHeight from '../global-functions/calculateParagraphHeight';
import trackAction from '../global-functions/trackAction';
import trackScreen from '../global-functions/trackScreen';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import openImagePickerUtil from '../utils/openImagePicker';
import useWindowDimensions from '../utils/useWindowDimensions';

const AddressesScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const [addressIdToDelete, setAddressIdToDelete] = React.useState('');
  const [currentlyUploadingId, setCurrentlyUploadingId] = React.useState(0);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [totalAddressCount, setTotalAddressCount] = React.useState(-1);
  const xanoServiceUploadAddressApprovalPOST =
    XanoServiceApi.useUploadAddressApprovalPOST();
  const xanoServiceDeleteAddressDELETE =
    XanoServiceApi.useDeleteAddressDELETE();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      trackScreen(segment, 'Addresses');
      setGlobalVariableValue({
        key: 'SHOW_DRAWER',
        value: false,
      });
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasSafeArea={true}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: {
            minWidth: Breakpoints.Desktop,
            value: palettes.App.CustomGray,
          },
        },
        dimensions.width
      )}
    >
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <WideHeaderBlock currentScreen={'myaddresses'} />
        )}
      </>
      {/* DrawerNew */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <Utils.CustomCodeErrorBoundary>
            <DrawerScreen.DrawerView
              currentScreen="myaddresses"
              navigation={props.navigation}
            />
          </Utils.CustomCodeErrorBoundary>
        )}
      </>
      {/* Header */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            {...GlobalStyles.ViewStyles(theme)['Header'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['Header'].style,
              dimensions.width
            )}
          >
            {/* MenuButton */}
            <IconButton
              onPress={() => {
                try {
                  Keyboard.dismiss();
                  setGlobalVariableValue({
                    key: 'SHOW_DRAWER',
                    value: true,
                  });
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              size={32}
              {...GlobalStyles.IconButtonStyles(theme)['MenuButton'].props}
              icon={'Entypo/menu'}
              style={StyleSheet.applyWidth(
                GlobalStyles.IconButtonStyles(theme)['MenuButton'].style,
                dimensions.width
              )}
            />
            {/* AbsoluteTitle */}
            <View
              {...GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].style,
                dimensions.width
              )}
            >
              <Image
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                resizeMode={'contain'}
                source={imageSource(Images['MailboxxGreenLogo'])}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { height: 30, width: 120 }
                  ),
                  dimensions.width
                )}
              />
            </View>
          </View>
        )}
      </>
      {/* Body */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignSelf: { minWidth: Breakpoints.Desktop, value: 'center' },
            backgroundColor: palettes.App.CustomGray,
            flex: 1,
            maxWidth: 1024,
            width: { minWidth: Breakpoints.Desktop, value: '100%' },
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: theme.colors.background.brand,
              flex: 1,
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 20,
            },
            dimensions.width
          )}
        >
          <XanoServiceApi.FetchGetAddressesGET
            handlers={{
              onData: fetchData => {
                try {
                  setTotalAddressCount(fetchData?.length);
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              },
            }}
          >
            {({ loading, error, data, refetchGetAddresses }) => {
              const fetchData = data?.json;
              if (loading) {
                return (
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', justifyContent: 'center' },
                      dimensions.width
                    )}
                  >
                    <ActivityIndicator
                      animating={true}
                      hidesWhenStopped={true}
                      {...GlobalStyles.ActivityIndicatorStyles(theme)[
                        'Activity Indicator'
                      ].props}
                      size={'large'}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ActivityIndicatorStyles(theme)[
                          'Activity Indicator'
                        ].style,
                        dimensions.width
                      )}
                    />
                  </View>
                );
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <>
                  <>
                    {!fetchData?.length ? null : (
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              fontFamily: [
                                {
                                  minWidth: Breakpoints.Desktop,
                                  value: 'Poppins_500Medium',
                                },
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'Poppins_500Medium',
                                },
                              ],
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'My Addresses'}
                      </Text>
                    )}
                  </>
                  {/* View 2 */}
                  <>
                    {!(fetchData?.length === 0) ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flex: 1,
                            justifyContent: 'center',
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                fontFamily: 'Poppins_300Light',
                                fontSize: 18,
                                textAlign: 'center',
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {
                            'You do not have any address yet. Please add a new address to continue using Mailboxx'
                          }
                        </Text>
                      </View>
                    )}
                  </>
                  <>
                    {!(fetchData?.length > 0) ? null : (
                      <SimpleStyleFlatList
                        data={fetchData}
                        horizontal={false}
                        inverted={false}
                        keyExtractor={(listData, index) =>
                          listData?.id ??
                          listData?.uuid ??
                          index?.toString() ??
                          JSON.stringify(listData)
                        }
                        keyboardShouldPersistTaps={'never'}
                        listKey={'Ggdd6IVO'}
                        nestedScrollEnabled={false}
                        numColumns={1}
                        onEndReachedThreshold={0.5}
                        renderItem={({ item, index }) => {
                          const listData = item;
                          return (
                            <>
                              {/* ListRow */}
                              <Surface
                                {...GlobalStyles.SurfaceStyles(theme)['Surface']
                                  .props}
                                elevation={2}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.SurfaceStyles(theme)['Surface']
                                      .style,
                                    {
                                      backgroundColor:
                                        theme.colors.background.brand,
                                      borderRadius: 12,
                                      margin: 2,
                                      marginBottom: 10,
                                      marginTop: 10,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {/* FirstRow */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      flex: 1,
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { flex: 1, padding: 10 },
                                      dimensions.width
                                    )}
                                  >
                                    <AddressDisplayBlock
                                      addressId={listData?.id}
                                      city={listData?.city}
                                      country={listData?.country}
                                      house_number={listData?.house_number}
                                      isPrimary={listData?.is_primary}
                                      isVerified={listData?.verified}
                                      road={listData?.road}
                                      showPrimaryInformation={true}
                                      state={listData?.state}
                                      title={listData?.title}
                                      zip={listData?.zip}
                                    />
                                  </View>
                                  {/* Actions */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { flexDirection: 'row' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* DeleteButton */}
                                    <IconButton
                                      onPress={() => {
                                        try {
                                          trackAction(
                                            Variables,
                                            segment,
                                            'Clicked Delete Button',
                                            listData?.id
                                          );
                                          setAddressIdToDelete(listData?.id);
                                          setShowDeleteModal(true);
                                        } catch (err) {
                                          Sentry.captureException(err);
                                          console.error(err);
                                        }
                                      }}
                                      size={32}
                                      icon={'Ionicons/trash-outline'}
                                      style={StyleSheet.applyWidth(
                                        { marginRight: 8 },
                                        dimensions.width
                                      )}
                                    />
                                  </View>
                                </View>
                                {/* SecondRow */}
                                <>
                                  {!!listData?.verified ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          flexDirection: 'row',
                                          justifyContent: 'space-between',
                                          marginBottom: 10,
                                          marginLeft: 10,
                                          marginRight: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* UploadButtonView */}
                                      <>
                                        {listData?.approval_image ? null : (
                                          <View
                                            style={StyleSheet.applyWidth(
                                              { flex: 1 },
                                              dimensions.width
                                            )}
                                          >
                                            {/* UploadButton */}
                                            <Button
                                              iconPosition={'left'}
                                              onPress={() => {
                                                const handler = async () => {
                                                  try {
                                                    const imageResult =
                                                      await openImagePickerUtil(
                                                        {
                                                          mediaTypes: 'Images',
                                                          allowsEditing: false,
                                                          quality: 0.2,
                                                          allowsMultipleSelection: false,
                                                          permissionErrorMessage:
                                                            'Sorry, we need media library permissions to make this work.',
                                                          showAlertOnPermissionError: true,
                                                          outputBase64: true,
                                                        }
                                                      );

                                                    if (imageResult) {
                                                      setCurrentlyUploadingId(
                                                        listData?.id
                                                      );
                                                    }
                                                    const uploadAddressApprovalResult =
                                                      await (async () => {
                                                        if (imageResult) {
                                                          return (
                                                            await xanoServiceUploadAddressApprovalPOST.mutateAsync(
                                                              {
                                                                addresses_id:
                                                                  listData?.id,
                                                                approval_image:
                                                                  imageResult,
                                                              }
                                                            )
                                                          )?.json;
                                                        }
                                                      })();
                                                    if (imageResult) {
                                                      setCurrentlyUploadingId(
                                                        0
                                                      );
                                                    }
                                                    trackAction(
                                                      Variables,
                                                      segment,
                                                      'Updated an address',
                                                      listData?.id
                                                    );
                                                  } catch (err) {
                                                    Sentry.captureException(
                                                      err
                                                    );
                                                    console.error(err);
                                                  }
                                                };
                                                handler();
                                              }}
                                              {...GlobalStyles.ButtonStyles(
                                                theme
                                              )['OutlineButton'].props}
                                              disabled={
                                                currentlyUploadingId ===
                                                listData?.id
                                              }
                                              loading={
                                                currentlyUploadingId ===
                                                listData?.id
                                              }
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.ButtonStyles(
                                                    theme
                                                  )['OutlineButton'].style,
                                                  { marginRight: 20 }
                                                ),
                                                dimensions.width
                                              )}
                                              title={
                                                'Upload Verification Document'
                                              }
                                            />
                                          </View>
                                        )}
                                      </>
                                      {/* WaitingApprovalView */}
                                      <>
                                        {!listData?.approval_image ? null : (
                                          <View
                                            style={StyleSheet.applyWidth(
                                              { flexDirection: 'row' },
                                              dimensions.width
                                            )}
                                          >
                                            <Text
                                              accessible={true}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['Text'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {'Waiting for approval...'}
                                            </Text>
                                            <Link
                                              accessible={true}
                                              onPress={() => {
                                                const handler = async () => {
                                                  try {
                                                    const imageResult =
                                                      await openImagePickerUtil(
                                                        {
                                                          mediaTypes: 'All',
                                                          allowsEditing: false,
                                                          quality: 0.2,
                                                          allowsMultipleSelection: false,
                                                          permissionErrorMessage:
                                                            'Sorry, we need media library permissions to make this work.',
                                                          showAlertOnPermissionError: true,
                                                          outputBase64: true,
                                                        }
                                                      );

                                                    setCurrentlyUploadingId(
                                                      listData?.id
                                                    );
                                                    (
                                                      await xanoServiceUploadAddressApprovalPOST.mutateAsync(
                                                        {
                                                          addresses_id:
                                                            listData?.id,
                                                          approval_image:
                                                            imageResult,
                                                        }
                                                      )
                                                    )?.json;
                                                    setCurrentlyUploadingId(0);
                                                  } catch (err) {
                                                    Sentry.captureException(
                                                      err
                                                    );
                                                    console.error(err);
                                                  }
                                                };
                                                handler();
                                              }}
                                              {...GlobalStyles.LinkStyles(
                                                theme
                                              )['Link'].props}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.LinkStyles(
                                                    theme
                                                  )['Link'].style,
                                                  {
                                                    marginLeft: 8,
                                                    textDecorationLine:
                                                      'underline',
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                              title={'Re-upload '}
                                            />
                                            <>
                                              {!(
                                                currentlyUploadingId ===
                                                listData?.id
                                              ) ? null : (
                                                <ActivityIndicator
                                                  animating={true}
                                                  hidesWhenStopped={true}
                                                  size={'small'}
                                                  {...GlobalStyles.ActivityIndicatorStyles(
                                                    theme
                                                  )['Activity Indicator'].props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.ActivityIndicatorStyles(
                                                      theme
                                                    )['Activity Indicator']
                                                      .style,
                                                    dimensions.width
                                                  )}
                                                />
                                              )}
                                            </>
                                          </View>
                                        )}
                                      </>
                                      {/* Tooltip */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'center',
                                            flex: 0,
                                            justifyContent: 'center',
                                            marginRight: 16,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Utils.CustomCodeErrorBoundary>
                                          <ScreenComponents.TooltipWrapper
                                            theme={props.theme}
                                            tooltipText="Please upload a document that confirms your current residential address. This can be a recent utility bill (like water, gas, or electricity), bank statement, or government-issued document that is dated within the last three months. Ensure that the document clearly shows your name and address. Accepted file formats are PDF, JPG, and PNG."
                                          >
                                            <Icon
                                              color={
                                                theme.colors.branding.primary
                                              }
                                              name={'AntDesign/questioncircleo'}
                                              size={20}
                                              style={StyleSheet.applyWidth(
                                                {
                                                  width: {
                                                    minWidth:
                                                      Breakpoints.Tablet,
                                                    value:
                                                      calculateParagraphHeight(
                                                        dimensions.width,
                                                        'text'
                                                      ),
                                                  },
                                                },
                                                dimensions.width
                                              )}
                                            />
                                          </ScreenComponents.TooltipWrapper>
                                        </Utils.CustomCodeErrorBoundary>
                                      </View>
                                    </View>
                                  )}
                                </>
                              </Surface>
                            </>
                          );
                        }}
                        showsHorizontalScrollIndicator={true}
                        showsVerticalScrollIndicator={true}
                      />
                    )}
                  </>
                </>
              );
            }}
          </XanoServiceApi.FetchGetAddressesGET>
        </View>
        {/* View 2 */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: theme.colors.background.brand,
              justifyContent: 'flex-end',
              padding: 20,
            },
            dimensions.width
          )}
        >
          {/* AddAddressButton */}
          <Button
            iconPosition={'left'}
            onPress={() => {
              try {
                trackAction(
                  Variables,
                  segment,
                  'Clicked Add New Address Button',
                  undefined
                );
                navigation.navigate('AddAddressScreen', {
                  isOnboarding: false,
                  isFirstAddress: !(totalAddressCount > 0),
                });
              } catch (err) {
                Sentry.captureException(err);
                console.error(err);
              }
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            icon={'Ionicons/add-sharp'}
            style={StyleSheet.applyWidth(
              GlobalStyles.ButtonStyles(theme)['Button'].style,
              dimensions.width
            )}
            title={'Add New Address'}
          />
        </View>
      </View>

      <Modal
        animationType={'none'}
        supportedOrientations={['portrait', 'landscape']}
        transparent={true}
        visible={showDeleteModal}
      >
        {/* BGView */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              backgroundColor: palettes.App.ViewBG,
              flex: 1,
              justifyContent: 'center',
              padding: 20,
            },
            dimensions.width
          )}
        >
          {/* Container */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors.background.brand,
                borderColor: palettes.App.CustomGray,
                borderRadius: 16,
                borderWidth: 1,
                maxWidth: 400,
                width: '90%',
              },
              dimensions.width
            )}
          >
            {/* Modal Header */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  borderBottomWidth: 1,
                  borderColor: palettes.App.CustomGray,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: 16,
                  paddingBottom: 8,
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { fontFamily: 'Poppins_500Medium' }
                  ),
                  dimensions.width
                )}
              >
                {'Are you sure?'}
              </Text>
              <IconButton
                onPress={() => {
                  try {
                    setShowDeleteModal(false);
                  } catch (err) {
                    Sentry.captureException(err);
                    console.error(err);
                  }
                }}
                size={32}
                icon={'Ionicons/close'}
              />
            </View>
            {/* Modal Body */}
            <View
              style={StyleSheet.applyWidth({ padding: 16 }, dimensions.width)}
            >
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  dimensions.width
                )}
              >
                {
                  'You are about to delete this address. You can not undo this action. Do you want to proceed? '
                }
              </Text>
            </View>
            {/* Modal Footer */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  padding: 16,
                },
                dimensions.width
              )}
            >
              {/* CancelView */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, marginRight: 4 },
                  dimensions.width
                )}
              >
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      setShowDeleteModal(false);
                    } catch (err) {
                      Sentry.captureException(err);
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['OutlineButton'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ButtonStyles(theme)['OutlineButton'].style,
                    dimensions.width
                  )}
                  title={'Cancel'}
                />
              </View>
              {/* ConfirmView */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, marginLeft: 4 },
                  dimensions.width
                )}
              >
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    const handler = async () => {
                      try {
                        setIsDeleting(true);
                        const deleteAddressResult = (
                          await xanoServiceDeleteAddressDELETE.mutateAsync({
                            address_id: addressIdToDelete,
                          })
                        )?.json;
                        if (deleteAddressResult?.new_primary_address) {
                          setGlobalVariableValue({
                            key: 'PRIMARY_ADDRESS',
                            value: deleteAddressResult?.new_primary_address,
                          });
                        }
                        setIsDeleting(false);
                        setShowDeleteModal(false);
                        trackAction(
                          Variables,
                          segment,
                          'Address Deleted',
                          addressIdToDelete
                        );
                      } catch (err) {
                        Sentry.captureException(err);
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  disabled={isDeleting}
                  loading={isDeleting}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      { backgroundColor: theme.colors.background.danger }
                    ),
                    dimensions.width
                  )}
                  title={'Yes, Delete!'}
                />
              </View>
            </View>
          </View>
        </View>
      </Modal>
    </ScreenContainer>
  );
};

export default withTheme(AddressesScreen);
