import React from 'react';
import {
  Divider,
  Icon,
  LinearGradient,
  Link,
  Pressable,
  ScreenContainer,
  SimpleStyleScrollView,
  Surface,
  Swiper,
  SwiperItem,
  VideoPlayer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import { BlurView } from 'expo-blur';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  Image,
  Modal,
  Platform,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as PromotionsServiceApi from '../apis/PromotionsServiceApi.js';
import WideHeaderBlock from '../components/WideHeaderBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as ScreenComponents from '../custom-files/ScreenComponents';
import amountToUserText from '../global-functions/amountToUserText';
import copyToClipboard from '../global-functions/copyToClipboard';
import isLoggedIn from '../global-functions/isLoggedIn';
import trackAction from '../global-functions/trackAction';
import trackScreen from '../global-functions/trackScreen';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import openShareUtil from '../utils/openShare';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { PromotionId: 1, hideBtn: '' };

const ViewPromotionScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const segment = useAnalytics();
  const [isSaved, setIsSaved] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const showModalIn = ms => {
    if (!ms) {
      ms = 10000;
    }

    setTimeout(() => {
      setShowModal(true);
    }, ms);
  };

  const getSaveButtonBg = () => {
    return isSaved
      ? theme.colors.branding.primary
      : theme.colors.background.brand;
  };

  const getSaveButtonTextColor = () => {
    return !isSaved
      ? theme.colors.branding.primary
      : theme.colors.background.brand;
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (!isLoggedIn(Variables) && !(Platform.OS === 'web')) {
        navigation.navigate('AuthLoginScreen');
      }
      trackScreen(segment, 'View Promotion');
      if (Platform.OS === 'web') {
        showModalIn(15000);
      }
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasSafeArea={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App.CustomGray },
        dimensions.width
      )}
    >
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <WideHeaderBlock withGoBack={true} />
        )}
      </>
      {/* Header */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            {...GlobalStyles.ViewStyles(theme)['Header'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['Header'].style,
              dimensions.width
            )}
          >
            {/* AbsoluteTitle */}
            <View
              {...GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].style,
                dimensions.width
              )}
            >
              <Image
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                resizeMode={'contain'}
                source={imageSource(Images['MailboxxGreenLogo'])}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { height: 30, width: 120 }
                  ),
                  dimensions.width
                )}
              />
            </View>
            {/* BackButton */}
            <Pressable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              {...GlobalStyles.PressableStyles(theme)['BackButton'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.PressableStyles(theme)['BackButton'].style,
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={theme.colors.branding.primary}
                  name={'Ionicons/chevron-back'}
                  size={28}
                />
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    dimensions.width
                  )}
                >
                  {'Back'}
                </Text>
              </View>
            </Pressable>
          </View>
        )}
      </>
      {/* View 2 */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignSelf: { minWidth: Breakpoints.Desktop, value: 'center' },
            backgroundColor: [
              {
                minWidth: Breakpoints.Desktop,
                value: theme.colors.background.brand,
              },
              {
                minWidth: Breakpoints.Mobile,
                value: theme.colors.background.brand,
              },
            ],
            flex: 1,
            maxWidth: { minWidth: Breakpoints.Desktop, value: 1024 },
            paddingBottom: 40,
            paddingTop: 12,
            width: { minWidth: Breakpoints.Desktop, value: '100%' },
          },
          dimensions.width
        )}
      >
        <>
          {!isLoggedIn(Variables) ? null : (
            <PromotionsServiceApi.FetchGetPromotionDetailsGET
              handlers={{
                onData: fetchData => {
                  try {
                    setIsSaved(fetchData?._saved_promotion_by_user);
                  } catch (err) {
                    Sentry.captureException(err);
                    console.error(err);
                  }
                },
              }}
              id={props.route?.params?.PromotionId ?? defaultProps.PromotionId}
            >
              {({ loading, error, data, refetchGetPromotionDetails }) => {
                const fetchData = data?.json;
                if (loading) {
                  return (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flex: 1,
                          justifyContent: 'center',
                        },
                        dimensions.width
                      )}
                    >
                      <ActivityIndicator
                        animating={true}
                        hidesWhenStopped={true}
                        {...GlobalStyles.ActivityIndicatorStyles(theme)[
                          'Activity Indicator'
                        ].props}
                        size={'large'}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.ActivityIndicatorStyles(theme)[
                            'Activity Indicator'
                          ].style,
                          dimensions.width
                        )}
                      />
                    </View>
                  );
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <>
                    {/* Header */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          paddingBottom: 4,
                          paddingLeft: 8,
                          paddingRight: 8,
                          paddingTop: 4,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Detail View */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flex: 1,
                            flexDirection: 'row',
                            paddingRight: 10,
                          },
                          dimensions.width
                        )}
                      >
                        <Pressable
                          onPress={() => {
                            try {
                              navigation.navigate('BusinessProfileScreen', {
                                business_id:
                                  fetchData?._business_profile_of_user?.id,
                              });
                            } catch (err) {
                              Sentry.captureException(err);
                              console.error(err);
                            }
                          }}
                        >
                          {/* Logo */}
                          <Image
                            {...GlobalStyles.ImageStyles(theme)['Image'].props}
                            resizeMode={'contain'}
                            source={{
                              uri: `${fetchData?.logo?.attachment?.url}`,
                            }}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ImageStyles(theme)['Image'].style,
                                {
                                  borderColor: palettes.App.ViewBG,
                                  borderRadius: 6,
                                  borderWidth: 1,
                                  height: 50,
                                  marginLeft: 10,
                                  marginRight: 10,
                                  width: 50,
                                }
                              ),
                              dimensions.width
                            )}
                          />
                        </Pressable>

                        <View
                          style={StyleSheet.applyWidth(
                            { flex: 1 },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                { fontSize: 18 }
                              ),
                              dimensions.width
                            )}
                          >
                            {fetchData?.title}
                          </Text>
                          {/* Text 2 */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                { color: theme.colors.text.light }
                              ),
                              dimensions.width
                            )}
                          >
                            {fetchData?.company_name}
                          </Text>
                        </View>
                      </View>
                      {/* PriceView */}
                      <View
                        style={StyleSheet.applyWidth(
                          { width: 70 },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              { fontSize: 19, textAlign: 'center' }
                            ),
                            dimensions.width
                          )}
                        >
                          {amountToUserText(fetchData?.amount_to_user)}
                        </Text>
                      </View>
                    </View>
                    <Divider
                      {...GlobalStyles.DividerStyles(theme)['Divider'].props}
                      color={theme.colors.border.brand}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.DividerStyles(theme)['Divider'].style,
                          { height: 1.2, marginBottom: 20, marginTop: 10 }
                        ),
                        dimensions.width
                      )}
                    />
                    <Swiper
                      data={fetchData?.details_images}
                      dotActiveColor={theme.colors.branding.primary}
                      dotColor={theme.colors.text.light}
                      dotsTouchable={true}
                      keyExtractor={(swiperData, index) =>
                        swiperData?.id ??
                        swiperData?.uuid ??
                        index?.toString() ??
                        JSON.stringify(swiperData)
                      }
                      listKey={'LMICJb9X'}
                      loop={false}
                      minDistanceForAction={0.2}
                      minDistanceToCapture={5}
                      renderItem={({ item, index }) => {
                        const swiperData = item;
                        return (
                          <SwiperItem
                            style={StyleSheet.applyWidth(
                              { flex: 1 },
                              dimensions.width
                            )}
                          >
                            <Surface
                              elevation={0}
                              {...GlobalStyles.SurfaceStyles(theme)['Surface']
                                .props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.SurfaceStyles(theme)['Surface']
                                    .style,
                                  {
                                    alignItems: 'center',
                                    backgroundColor:
                                      theme.colors.background.brand,
                                    borderRadius: 16,
                                    flex: 1,
                                    justifyContent: 'flex-start',
                                    marginLeft: 20,
                                    marginRight: 20,
                                    overflow: 'hidden',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              <>
                                {!(
                                  swiperData?.attachment?.type === 'image'
                                ) ? null : (
                                  <Image
                                    resizeMode={'cover'}
                                    {...GlobalStyles.ImageStyles(theme)['Image']
                                      .props}
                                    source={{
                                      uri: `${swiperData?.attachment?.url}`,
                                    }}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.ImageStyles(theme)['Image']
                                          .style,
                                        { height: '100%', width: '100%' }
                                      ),
                                      dimensions.width
                                    )}
                                  />
                                )}
                              </>
                              <>
                                {!(
                                  swiperData?.attachment?.type === 'video'
                                ) ? null : (
                                  <VideoPlayer
                                    isMuted={false}
                                    posterResizeMode={'cover'}
                                    rate={1}
                                    resizeMode={'cover'}
                                    usePoster={false}
                                    volume={0.5}
                                    {...GlobalStyles.VideoPlayerStyles(theme)[
                                      'Video'
                                    ].props}
                                    isLooping={true}
                                    shouldPlay={true}
                                    source={{
                                      uri: `${swiperData?.attachment?.url}`,
                                    }}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.VideoPlayerStyles(theme)[
                                          'Video'
                                        ].style,
                                        { height: '100%', width: '100%' }
                                      ),
                                      dimensions.width
                                    )}
                                    useNativeControls={false}
                                  />
                                )}
                              </>
                              <LinearGradient
                                startY={0}
                                {...GlobalStyles.LinearGradientStyles(theme)[
                                  'Linear Gradient'
                                ].props}
                                color1={'rgba(0, 0, 0, 0)'}
                                color2={palettes.App.Black}
                                endX={50}
                                endY={80}
                                startX={50}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.LinearGradientStyles(theme)[
                                      'Linear Gradient'
                                    ].style,
                                    {
                                      bottom: 0,
                                      height: '40%',
                                      opacity: 1,
                                      paddingTop: 65,
                                      position: 'absolute',
                                      width: '100%',
                                      zIndex: 0,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                <SimpleStyleScrollView
                                  bounces={true}
                                  horizontal={false}
                                  keyboardShouldPersistTaps={'never'}
                                  nestedScrollEnabled={false}
                                  showsHorizontalScrollIndicator={true}
                                  showsVerticalScrollIndicator={true}
                                  style={StyleSheet.applyWidth(
                                    {
                                      bottom: 0,
                                      justifyContent: 'flex-end',
                                      padding: 16,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    accessible={true}
                                    {...GlobalStyles.TextStyles(theme)['Text']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        {
                                          color: theme.colors.background.brand,
                                          fontSize: 18,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {fetchData?.content}
                                  </Text>
                                </SimpleStyleScrollView>
                              </LinearGradient>
                            </Surface>
                          </SwiperItem>
                        );
                      }}
                      timeout={0}
                      vertical={false}
                      {...GlobalStyles.SwiperStyles(theme)['Swiper'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.SwiperStyles(theme)['Swiper'].style,
                          { flex: 1, height: null, minHeight: 300 }
                        ),
                        dimensions.width
                      )}
                    />
                    {/* ActionsMenu */}
                    <>
                      {(props.route?.params?.hideBtn ??
                        defaultProps.hideBtn) === true ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: theme.colors.background.brand,
                              flexDirection: 'row',
                              gap: 8,
                              justifyContent: 'space-between',
                              marginLeft: 20,
                              marginRight: 20,
                              marginTop: 20,
                              minHeight: 45,
                            },
                            dimensions.width
                          )}
                        >
                          {/* ViewMorePressable */}
                          <Pressable
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  trackAction(
                                    Variables,
                                    segment,
                                    'Clicked View More Button',
                                    fetchData?.redirect_link
                                  );
                                  await WebBrowser.openBrowserAsync(
                                    `${fetchData?.redirect_link}`
                                  );
                                } catch (err) {
                                  Sentry.captureException(err);
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  backgroundColor:
                                    theme.colors.branding.primary,
                                  borderRadius: 6,
                                  flex: 1,
                                  flexDirection: 'row',
                                  height: 45,
                                  justifyContent: 'space-between',
                                  paddingBottom: 6,
                                  paddingLeft: 12,
                                  paddingRight: 12,
                                  paddingTop: 6,
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    {
                                      color: palettes.App.White,
                                      fontFamily: 'System',
                                      fontSize: 15,
                                      fontWeight: '600',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'View More'}
                              </Text>
                              <Icon
                                color={palettes.App.White}
                                name={'Ionicons/add-sharp'}
                                size={28}
                              />
                            </View>
                          </Pressable>
                          {/* SharePressable */}
                          <Pressable
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  trackAction(
                                    Variables,
                                    segment,
                                    'Clicked Share Button',
                                    props.route?.params?.PromotionId ??
                                      defaultProps.PromotionId
                                  );
                                  await openShareUtil(`https://mailboxx.
app/promo/${props.route?.params?.PromotionId ?? defaultProps.PromotionId}`);
                                  await copyToClipboard(
                                    'https://mailboxx.app/promo/' +
                                      (props.route?.params?.PromotionId ??
                                        defaultProps.PromotionId)
                                  );
                                } catch (err) {
                                  Sentry.captureException(err);
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  backgroundColor:
                                    theme.colors.branding.primary,
                                  borderRadius: 6,
                                  flex: 1,
                                  flexDirection: 'row',
                                  height: 45,
                                  justifyContent: 'space-between',
                                  paddingBottom: 6,
                                  paddingLeft: 12,
                                  paddingRight: 12,
                                  paddingTop: 6,
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    {
                                      color: palettes.App.White,
                                      fontFamily: 'System',
                                      fontSize: 15,
                                      fontWeight: '600',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Share'}
                              </Text>
                              <Icon
                                color={palettes.App.White}
                                name={'Entypo/share'}
                                size={28}
                                style={StyleSheet.applyWidth(
                                  { marginLeft: 15, marginRight: -5 },
                                  dimensions.width
                                )}
                              />
                            </View>
                          </Pressable>
                          {/* SavePressable */}
                          <Pressable
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  trackAction(
                                    Variables,
                                    segment,
                                    'Clicked Save Button',
                                    props.route?.params?.PromotionId ??
                                      defaultProps.PromotionId
                                  );
                                  setIsSaving(true);
                                  const saveResult = (
                                    await PromotionsServiceApi.savePromotionPOST(
                                      Constants,
                                      {
                                        promotion_id:
                                          props.route?.params?.PromotionId ??
                                          defaultProps.PromotionId,
                                      }
                                    )
                                  )?.json;
                                  setIsSaved(saveResult);
                                  setIsSaving(false);
                                } catch (err) {
                                  Sentry.captureException(err);
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor: '"rgba(0, 0, 0, 0)"',
                                  borderColor: theme.colors.branding.primary,
                                  borderRadius: 6,
                                  borderWidth: 1,
                                  flex: 1,
                                  height: 45,
                                  justifyContent: 'center',
                                },
                                dimensions.width
                              )}
                            >
                              {/* Filled */}
                              <>
                                {!isSaved ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        backgroundColor:
                                          theme.colors.branding.primary,
                                        bottom: 0,
                                        height: '100%',
                                        left: 0,
                                        position: 'absolute',
                                        right: 0,
                                        top: 0,
                                        width: '100%',
                                        zIndex: 0,
                                      },
                                      dimensions.width
                                    )}
                                  />
                                )}
                              </>
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    justifyContent: 'space-around',
                                    paddingBottom: 6,
                                    paddingLeft: 12,
                                    paddingRight: 12,
                                    paddingTop: 6,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* GreenText */}
                                <>
                                  {isSaved ? null : (
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          {
                                            fontFamily: 'System',
                                            fontSize: 15,
                                            fontWeight: '600',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'Save'}
                                    </Text>
                                  )}
                                </>
                                {/* WhiteText */}
                                <>
                                  {!isSaved ? null : (
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          {
                                            color: palettes.App.White,
                                            fontFamily: 'System',
                                            fontSize: 15,
                                            fontWeight: '600',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'Save'}
                                    </Text>
                                  )}
                                </>
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      height: 40,
                                      justifyContent: 'center',
                                      width: 40,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <>
                                    {!isSaving ? null : (
                                      <ActivityIndicator
                                        animating={true}
                                        hidesWhenStopped={true}
                                        size={'small'}
                                        {...GlobalStyles.ActivityIndicatorStyles(
                                          theme
                                        )['Activity Indicator'].props}
                                        color={palettes.App['Custom #dbdbdb']}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.ActivityIndicatorStyles(
                                            theme
                                          )['Activity Indicator'].style,
                                          dimensions.width
                                        )}
                                      />
                                    )}
                                  </>
                                  {/* icons */}
                                  <>
                                    {isSaving ? null : (
                                      <View>
                                        {/* icon-filled */}
                                        <>
                                          {!isSaved ? null : (
                                            <Icon
                                              color={palettes.App.White}
                                              name={'FontAwesome/bookmark'}
                                              size={26}
                                            />
                                          )}
                                        </>
                                        {/* icon-empty */}
                                        <>
                                          {isSaved ? null : (
                                            <Icon
                                              color={
                                                theme.colors.branding.primary
                                              }
                                              name={'FontAwesome/bookmark-o'}
                                              size={26}
                                            />
                                          )}
                                        </>
                                      </View>
                                    )}
                                  </>
                                </View>
                              </View>
                            </View>
                          </Pressable>
                        </View>
                      )}
                    </>
                    <>
                      {!fetchData?.can_consume ? null : (
                        <Utils.CustomCodeErrorBoundary>
                          <ScreenComponents.Confetti />
                        </Utils.CustomCodeErrorBoundary>
                      )}
                    </>
                  </>
                );
              }}
            </PromotionsServiceApi.FetchGetPromotionDetailsGET>
          )}
        </>
        {/* NoAuthFetch */}
        <>
          {isLoggedIn(Variables) ? null : (
            <PromotionsServiceApi.FetchGetPromotionDetailsNoAuthGET
              handlers={{
                onData: noAuthFetchData => {
                  try {
                    setIsSaved(noAuthFetchData?._saved_promotion_by_user);
                  } catch (err) {
                    Sentry.captureException(err);
                    console.error(err);
                  }
                },
              }}
              id={props.route?.params?.PromotionId ?? defaultProps.PromotionId}
            >
              {({ loading, error, data, refetchGetPromotionDetailsNoAuth }) => {
                const noAuthFetchData = data?.json;
                if (loading) {
                  return (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flex: 1,
                          justifyContent: 'center',
                        },
                        dimensions.width
                      )}
                    >
                      <ActivityIndicator
                        animating={true}
                        hidesWhenStopped={true}
                        {...GlobalStyles.ActivityIndicatorStyles(theme)[
                          'Activity Indicator'
                        ].props}
                        size={'large'}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.ActivityIndicatorStyles(theme)[
                            'Activity Indicator'
                          ].style,
                          dimensions.width
                        )}
                      />
                    </View>
                  );
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <>
                    {/* Header */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          paddingBottom: 4,
                          paddingLeft: 8,
                          paddingRight: 8,
                          paddingTop: 4,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Detail View */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flex: 1,
                            flexDirection: 'row',
                            paddingRight: 10,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Logo */}
                        <Image
                          {...GlobalStyles.ImageStyles(theme)['Image'].props}
                          resizeMode={'contain'}
                          source={{
                            uri: `${noAuthFetchData?.logo?.attachment?.url}`,
                          }}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ImageStyles(theme)['Image'].style,
                              {
                                borderColor: palettes.App.ViewBG,
                                borderRadius: 6,
                                borderWidth: 1,
                                height: 50,
                                marginLeft: 10,
                                marginRight: 10,
                                width: 50,
                              }
                            ),
                            dimensions.width
                          )}
                        />
                        <View
                          style={StyleSheet.applyWidth(
                            { flex: 1 },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                { fontSize: 18 }
                              ),
                              dimensions.width
                            )}
                          >
                            {noAuthFetchData?.title}
                          </Text>
                          {/* Text 2 */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                { color: theme.colors.text.light }
                              ),
                              dimensions.width
                            )}
                          >
                            {noAuthFetchData?.company_name}
                          </Text>
                        </View>
                      </View>
                      {/* PriceView */}
                      <View
                        style={StyleSheet.applyWidth(
                          { width: 70 },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              { fontSize: 19, textAlign: 'center' }
                            ),
                            dimensions.width
                          )}
                        >
                          {amountToUserText(noAuthFetchData?.amount_to_user)}
                        </Text>
                      </View>
                    </View>
                    <Divider
                      {...GlobalStyles.DividerStyles(theme)['Divider'].props}
                      color={theme.colors.border.brand}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.DividerStyles(theme)['Divider'].style,
                          { height: 1.2, marginBottom: 20, marginTop: 10 }
                        ),
                        dimensions.width
                      )}
                    />
                    <Swiper
                      data={noAuthFetchData?.details_images}
                      dotActiveColor={theme.colors.branding.primary}
                      dotColor={theme.colors.text.light}
                      dotsTouchable={true}
                      keyExtractor={(swiperData, index) =>
                        swiperData?.id ??
                        swiperData?.uuid ??
                        index?.toString() ??
                        JSON.stringify(swiperData)
                      }
                      listKey={'qLNCzOQy'}
                      loop={false}
                      minDistanceForAction={0.2}
                      minDistanceToCapture={5}
                      renderItem={({ item, index }) => {
                        const swiperData = item;
                        return (
                          <SwiperItem
                            style={StyleSheet.applyWidth(
                              { flex: 1 },
                              dimensions.width
                            )}
                          >
                            <Surface
                              elevation={0}
                              {...GlobalStyles.SurfaceStyles(theme)['Surface']
                                .props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.SurfaceStyles(theme)['Surface']
                                    .style,
                                  {
                                    alignItems: 'center',
                                    backgroundColor:
                                      theme.colors.background.brand,
                                    borderRadius: 16,
                                    flex: 1,
                                    justifyContent: 'flex-start',
                                    marginLeft: 20,
                                    marginRight: 20,
                                    overflow: 'hidden',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              <>
                                {!(
                                  swiperData?.attachment?.type === 'image'
                                ) ? null : (
                                  <Image
                                    resizeMode={'cover'}
                                    {...GlobalStyles.ImageStyles(theme)['Image']
                                      .props}
                                    source={{
                                      uri: `${swiperData?.attachment?.url}`,
                                    }}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.ImageStyles(theme)['Image']
                                          .style,
                                        { height: '100%', width: '100%' }
                                      ),
                                      dimensions.width
                                    )}
                                  />
                                )}
                              </>
                              <>
                                {!(
                                  swiperData?.attachment?.type === 'video'
                                ) ? null : (
                                  <VideoPlayer
                                    isMuted={false}
                                    posterResizeMode={'cover'}
                                    rate={1}
                                    resizeMode={'cover'}
                                    usePoster={false}
                                    volume={0.5}
                                    {...GlobalStyles.VideoPlayerStyles(theme)[
                                      'Video'
                                    ].props}
                                    isLooping={true}
                                    shouldPlay={true}
                                    source={{
                                      uri: `${swiperData?.attachment?.url}`,
                                    }}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.VideoPlayerStyles(theme)[
                                          'Video'
                                        ].style,
                                        { height: '100%', width: '100%' }
                                      ),
                                      dimensions.width
                                    )}
                                    useNativeControls={false}
                                  />
                                )}
                              </>
                              <LinearGradient
                                startY={0}
                                {...GlobalStyles.LinearGradientStyles(theme)[
                                  'Linear Gradient'
                                ].props}
                                color1={'rgba(0, 0, 0, 0)'}
                                color2={palettes.App.Black}
                                endX={50}
                                endY={80}
                                startX={50}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.LinearGradientStyles(theme)[
                                      'Linear Gradient'
                                    ].style,
                                    {
                                      bottom: 0,
                                      height: '40%',
                                      opacity: 1,
                                      paddingTop: 65,
                                      position: 'absolute',
                                      width: '100%',
                                      zIndex: 0,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                <SimpleStyleScrollView
                                  bounces={true}
                                  horizontal={false}
                                  keyboardShouldPersistTaps={'never'}
                                  nestedScrollEnabled={false}
                                  showsHorizontalScrollIndicator={true}
                                  showsVerticalScrollIndicator={true}
                                  style={StyleSheet.applyWidth(
                                    {
                                      bottom: 0,
                                      justifyContent: 'flex-end',
                                      padding: 16,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    accessible={true}
                                    {...GlobalStyles.TextStyles(theme)['Text']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        {
                                          color: theme.colors.background.brand,
                                          fontSize: 18,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {noAuthFetchData?.content}
                                  </Text>
                                </SimpleStyleScrollView>
                              </LinearGradient>
                            </Surface>
                          </SwiperItem>
                        );
                      }}
                      timeout={0}
                      vertical={false}
                      {...GlobalStyles.SwiperStyles(theme)['Swiper'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.SwiperStyles(theme)['Swiper'].style,
                          { flex: 1, height: null, minHeight: 300 }
                        ),
                        dimensions.width
                      )}
                    />
                    {/* ActionsMenu */}
                    <>
                      {(props.route?.params?.hideBtn ??
                        defaultProps.hideBtn) === true ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: theme.colors.background.brand,
                              flexDirection: 'row',
                              gap: 8,
                              justifyContent: 'space-between',
                              marginLeft: 20,
                              marginRight: 20,
                              marginTop: 20,
                              minHeight: 45,
                            },
                            dimensions.width
                          )}
                        >
                          {/* ViewMorePressable */}
                          <Pressable
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  trackAction(
                                    Variables,
                                    segment,
                                    'Clicked View More Button',
                                    noAuthFetchData?.redirect_link
                                  );
                                  await WebBrowser.openBrowserAsync(
                                    `${noAuthFetchData?.redirect_link}`
                                  );
                                } catch (err) {
                                  Sentry.captureException(err);
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  backgroundColor:
                                    theme.colors.branding.primary,
                                  borderRadius: 6,
                                  flex: 1,
                                  flexDirection: 'row',
                                  height: 45,
                                  justifyContent: 'space-between',
                                  paddingBottom: 6,
                                  paddingLeft: 12,
                                  paddingRight: 12,
                                  paddingTop: 6,
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    {
                                      color: palettes.App.White,
                                      fontFamily: 'System',
                                      fontSize: 15,
                                      fontWeight: '600',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'View More'}
                              </Text>
                              <Icon
                                color={palettes.App.White}
                                name={'Ionicons/add-sharp'}
                                size={28}
                              />
                            </View>
                          </Pressable>
                          {/* SharePressable */}
                          <Pressable
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  trackAction(
                                    Variables,
                                    segment,
                                    'Clicked Share Button',
                                    props.route?.params?.PromotionId ??
                                      defaultProps.PromotionId
                                  );
                                  await openShareUtil(`https://mailboxx.
app/promo/${props.route?.params?.PromotionId ?? defaultProps.PromotionId}`);
                                  await copyToClipboard(
                                    'https://mailboxx.app/promo/' +
                                      (props.route?.params?.PromotionId ??
                                        defaultProps.PromotionId)
                                  );
                                } catch (err) {
                                  Sentry.captureException(err);
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  backgroundColor:
                                    theme.colors.branding.primary,
                                  borderRadius: 6,
                                  flex: 1,
                                  flexDirection: 'row',
                                  height: 45,
                                  justifyContent: 'space-between',
                                  paddingBottom: 6,
                                  paddingLeft: 12,
                                  paddingRight: 12,
                                  paddingTop: 6,
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    {
                                      color: palettes.App.White,
                                      fontFamily: 'System',
                                      fontSize: 15,
                                      fontWeight: '600',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Share'}
                              </Text>
                              <Icon
                                color={palettes.App.White}
                                name={'Entypo/share'}
                                size={28}
                                style={StyleSheet.applyWidth(
                                  { marginLeft: 15, marginRight: -5 },
                                  dimensions.width
                                )}
                              />
                            </View>
                          </Pressable>
                          {/* SavePressable */}
                          <Pressable
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  trackAction(
                                    Variables,
                                    segment,
                                    'Clicked Save Button',
                                    props.route?.params?.PromotionId ??
                                      defaultProps.PromotionId
                                  );
                                  setIsSaving(true);
                                  const saveResult = (
                                    await PromotionsServiceApi.savePromotionPOST(
                                      Constants,
                                      {
                                        promotion_id:
                                          props.route?.params?.PromotionId ??
                                          defaultProps.PromotionId,
                                      }
                                    )
                                  )?.json;
                                  setIsSaved(saveResult);
                                  setIsSaving(false);
                                } catch (err) {
                                  Sentry.captureException(err);
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor: '"rgba(0, 0, 0, 0)"',
                                  borderColor: theme.colors.branding.primary,
                                  borderRadius: 6,
                                  borderWidth: 1,
                                  flex: 1,
                                  height: 45,
                                  justifyContent: 'center',
                                },
                                dimensions.width
                              )}
                            >
                              {/* Filled */}
                              <>
                                {!isSaved ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        backgroundColor:
                                          theme.colors.branding.primary,
                                        bottom: 0,
                                        height: '100%',
                                        left: 0,
                                        position: 'absolute',
                                        right: 0,
                                        top: 0,
                                        width: '100%',
                                        zIndex: 0,
                                      },
                                      dimensions.width
                                    )}
                                  />
                                )}
                              </>
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    justifyContent: 'space-around',
                                    paddingBottom: 6,
                                    paddingLeft: 12,
                                    paddingRight: 12,
                                    paddingTop: 6,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* GreenText */}
                                <>
                                  {isSaved ? null : (
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          {
                                            fontFamily: 'System',
                                            fontSize: 15,
                                            fontWeight: '600',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'Save'}
                                    </Text>
                                  )}
                                </>
                                {/* WhiteText */}
                                <>
                                  {!isSaved ? null : (
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          {
                                            color: palettes.App.White,
                                            fontFamily: 'System',
                                            fontSize: 15,
                                            fontWeight: '600',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'Save'}
                                    </Text>
                                  )}
                                </>
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      height: 40,
                                      justifyContent: 'center',
                                      width: 40,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <>
                                    {!isSaving ? null : (
                                      <ActivityIndicator
                                        animating={true}
                                        hidesWhenStopped={true}
                                        size={'small'}
                                        {...GlobalStyles.ActivityIndicatorStyles(
                                          theme
                                        )['Activity Indicator'].props}
                                        color={palettes.App['Custom #dbdbdb']}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.ActivityIndicatorStyles(
                                            theme
                                          )['Activity Indicator'].style,
                                          dimensions.width
                                        )}
                                      />
                                    )}
                                  </>
                                  {/* icons */}
                                  <>
                                    {isSaving ? null : (
                                      <View>
                                        {/* icon-filled */}
                                        <>
                                          {!isSaved ? null : (
                                            <Icon
                                              color={palettes.App.White}
                                              name={'FontAwesome/bookmark'}
                                              size={26}
                                            />
                                          )}
                                        </>
                                        {/* icon-empty */}
                                        <>
                                          {isSaved ? null : (
                                            <Icon
                                              color={
                                                theme.colors.branding.primary
                                              }
                                              name={'FontAwesome/bookmark-o'}
                                              size={26}
                                            />
                                          )}
                                        </>
                                      </View>
                                    )}
                                  </>
                                </View>
                              </View>
                            </View>
                          </Pressable>
                        </View>
                      )}
                    </>
                    <>
                      {!noAuthFetchData?.can_consume ? null : (
                        <Utils.CustomCodeErrorBoundary>
                          <ScreenComponents.Confetti />
                        </Utils.CustomCodeErrorBoundary>
                      )}
                    </>
                  </>
                );
              }}
            </PromotionsServiceApi.FetchGetPromotionDetailsNoAuthGET>
          )}
        </>
      </View>
      {/* DownloadModal */}
      <Modal
        supportedOrientations={['portrait', 'landscape']}
        animationType={'fade'}
        presentationStyle={'fullScreen'}
        transparent={true}
        visible={showModal}
      >
        <BlurView
          intensity={50}
          tint={'default'}
          {...GlobalStyles.BlurViewStyles(theme)['Blur View'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.BlurViewStyles(theme)['Blur View'].style,
              { alignItems: 'center', justifyContent: 'center' }
            ),
            dimensions.width
          )}
        >
          {/* ModalContainer */}
          <Surface
            {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
            elevation={1}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.SurfaceStyles(theme)['Surface'].style,
                {
                  alignItems: 'center',
                  backgroundColor: [
                    { minWidth: Breakpoints.Mobile, value: palettes.App.White },
                    {
                      minWidth: Breakpoints.Desktop,
                      value: palettes.App.White,
                    },
                  ],
                  borderColor: palettes.App.PrimarySelected,
                  borderRadius: 16,
                  borderWidth: 1,
                  justifyContent: 'flex-start',
                  marginLeft: 16,
                  marginRight: 16,
                  minHeight: 400,
                  padding: 16,
                  width: [
                    { minWidth: Breakpoints.Mobile, value: '90%' },
                    { minWidth: Breakpoints.Laptop, value: 400 },
                  ],
                }
              ),
              dimensions.width
            )}
          >
            <Image
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              resizeMode={'contain'}
              source={imageSource(Images['PngColorLogoNoBackground1'])}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  { width: 165 }
                ),
                dimensions.width
              )}
            />
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    alignSelf: {
                      minWidth: Breakpoints.Desktop,
                      value: 'center',
                    },
                    marginTop: 16,
                    textAlign: [
                      { minWidth: Breakpoints.Mobile, value: 'center' },
                      { minWidth: Breakpoints.Desktop, value: 'center' },
                    ],
                  }
                ),
                dimensions.width
              )}
            >
              {
                "Making money just by reading your mail sounds amazing, doesn't it?\n\nFind out more by downloading the Mailboxx app."
              }
            </Text>
            {/* Buttons */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flexDirection: [
                    { minWidth: Breakpoints.Mobile, value: 'row' },
                    { minWidth: Breakpoints.Desktop, value: 'row' },
                  ],
                  marginBottom: 20,
                  marginTop: [
                    { minWidth: Breakpoints.Desktop, value: 20 },
                    { minWidth: Breakpoints.Mobile, value: 20 },
                  ],
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderRadius: 6,
                    marginRight: [
                      { minWidth: Breakpoints.Mobile, value: 4 },
                      { minWidth: Breakpoints.Desktop, value: 4 },
                    ],
                    overflow: 'hidden',
                  },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'cover'}
                  {...GlobalStyles.ImageStyles(theme)['Image'].props}
                  source={imageSource(Images['ApplestoreButton'])}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'].style,
                      {
                        height: [
                          { minWidth: Breakpoints.Mobile, value: 45 },
                          { minWidth: Breakpoints.Desktop, value: 45 },
                        ],
                        width: [
                          { minWidth: Breakpoints.Desktop, value: 150 },
                          { minWidth: Breakpoints.Mobile, value: 150 },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                />
              </View>
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderRadius: 6,
                    marginLeft: [
                      { minWidth: Breakpoints.Desktop, value: 4 },
                      { minWidth: Breakpoints.Mobile, value: 4 },
                    ],
                    overflow: 'hidden',
                  },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'cover'}
                  {...GlobalStyles.ImageStyles(theme)['Image'].props}
                  source={imageSource(Images['PlaystoreButton'])}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'].style,
                      {
                        height: [
                          { minWidth: Breakpoints.Desktop, value: 45 },
                          { minWidth: Breakpoints.Mobile, value: 45 },
                        ],
                        width: [
                          { minWidth: Breakpoints.Desktop, value: 150 },
                          { minWidth: Breakpoints.Mobile, value: 150 },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                />
              </View>
            </View>
            {/* Skip */}
            <Link
              accessible={true}
              onPress={() => {
                try {
                  trackAction(
                    Variables,
                    segment,
                    'Clicked Skip Button',
                    'Download Modal'
                  );
                  setShowModal(false);
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              {...GlobalStyles.LinkStyles(theme)['Link'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.LinkStyles(theme)['Link'].style,
                  {
                    color: {
                      minWidth: Breakpoints.Desktop,
                      value: theme.colors.text.medium,
                    },
                    fontFamily: {
                      minWidth: Breakpoints.Desktop,
                      value: 'Poppins_300Light',
                    },
                    marginTop: { minWidth: Breakpoints.Desktop, value: 30 },
                    textDecorationLine: {
                      minWidth: Breakpoints.Desktop,
                      value: 'none',
                    },
                  }
                ),
                dimensions.width
              )}
              title={'Skip'}
            />
          </Surface>
        </BlurView>
      </Modal>
    </ScreenContainer>
  );
};

export default withTheme(ViewPromotionScreen);
