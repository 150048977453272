import React from 'react';
import {
  Button,
  Checkbox,
  IconButton,
  Pressable,
  SimpleStyleFlatList,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoServiceApi from '../apis/XanoServiceApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import trackAction from '../global-functions/trackAction';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { onAddressSelect: () => {} };

const AddressPickerBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const navigation = useNavigation();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const segment = useAnalytics();
  const [selectedAddress, setSelectedAddress] = React.useState({});
  const arrayOfCities = () => {
    if (selectedAddress?.city) return [selectedAddress.city];

    return [];
  };

  const toggleItem = (arr, item) => {
    if (arr.includes(item)) return arr.filter(i => i !== item);
    else return [...arr, item];
  };

  return (
    <View
      style={StyleSheet.applyWidth(
        { justifyContent: 'center', padding: 12 },
        dimensions.width
      )}
    >
      <Text
        accessible={true}
        {...GlobalStyles.TextStyles(theme)['Text'].props}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
            fontFamily: 'Poppins_500Medium',
            marginBottom: 30,
            marginLeft: 15,
            marginRight: 15,
            marginTop: 40,
            textAlign: 'center',
          }),
          dimensions.width
        )}
      >
        {
          'Please select the location for which you wish to create your promotion.'
        }
      </Text>

      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
          },
          dimensions.width
        )}
      >
        {/* Text 2 */}
        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              marginBottom: 12,
            }),
            dimensions.width
          )}
        >
          {'My Addresses:'}
        </Text>
        <IconButton
          onPress={() => {
            try {
              navigation.navigate('AddAddressScreen', { isOnboarding: false });
            } catch (err) {
              Sentry.captureException(err);
              console.error(err);
            }
          }}
          icon={'AntDesign/pluscircleo'}
          size={26}
        />
      </View>

      <XanoServiceApi.FetchGetAddressesGET>
        {({ loading, error, data, refetchGetAddresses }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              {!(fetchData?.length > 0) ? null : (
                <SimpleStyleFlatList
                  data={fetchData}
                  horizontal={false}
                  inverted={false}
                  keyExtractor={(listData, index) =>
                    listData?.id ??
                    listData?.uuid ??
                    index?.toString() ??
                    JSON.stringify(listData)
                  }
                  keyboardShouldPersistTaps={'never'}
                  listKey={'xN1ryaia'}
                  nestedScrollEnabled={false}
                  numColumns={1}
                  onEndReachedThreshold={0.5}
                  renderItem={({ item, index }) => {
                    const listData = item;
                    return (
                      <Pressable
                        onPress={() => {
                          try {
                            setSelectedAddress(listData);
                          } catch (err) {
                            Sentry.captureException(err);
                            console.error(err);
                          }
                        }}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: 'row',
                              justifyContent: 'flex-start',
                              marginBottom: 15,
                            },
                            dimensions.width
                          )}
                        >
                          <Checkbox
                            color={palettes.App.customGreen}
                            defaultValue={selectedAddress?.id === listData?.id}
                            disabled={true}
                            uncheckedColor={palettes.App.customGreen}
                          />
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                { marginLeft: 16 }
                              ),
                              dimensions.width
                            )}
                          >
                            {listData?.title}
                            {' ('}
                            {listData?.zip}
                            {', '}
                            {listData?.city}
                            {')'}
                          </Text>
                        </View>
                      </Pressable>
                    );
                  }}
                  showsHorizontalScrollIndicator={true}
                  showsVerticalScrollIndicator={true}
                />
              )}
            </>
          );
        }}
      </XanoServiceApi.FetchGetAddressesGET>
      {/* ProceedButton */}
      <Button
        iconPosition={'left'}
        onPress={() => {
          try {
            trackAction(
              Variables,
              segment,
              'Clicked Proceed Button',
              undefined
            );
            props.onAddressSelect?.(selectedAddress);
          } catch (err) {
            Sentry.captureException(err);
            console.error(err);
          }
        }}
        {...GlobalStyles.ButtonStyles(theme)['Button'].props}
        disabled={!selectedAddress?.id}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'].style, {
            backgroundColor: theme.colors.branding.primary,
            marginBottom: 10,
            marginTop: 10,
          }),
          dimensions.width
        )}
        title={'Proceed'}
      />
    </View>
  );
};

export default withTheme(AddressPickerBlock);
