import React from 'react';
import { Icon, IconButton, Pressable, withTheme } from '@draftbit/ui';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import { BlurView } from 'expo-blur';
import * as WebBrowser from 'expo-web-browser';
import { ActivityIndicator, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as PromotionsServiceApi from '../apis/PromotionsServiceApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import copyToClipboard from '../global-functions/copyToClipboard';
import trackAction from '../global-functions/trackAction';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openShareUtil from '../utils/openShare';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  promotion: {
    id: 411,
    logo: {
      id: 45,
      user_id: 147,
      attachment: {
        url: 'https://xhrm-5alx-lhb1.n7.xano.io/vault/cH9onT_o/hkTkq3T5rJqVoXWJzNHykz86PEg/SosT5g../a1a898d233cdf6e4fc687e3b004a278f6ca16a14-359x360.png',
        meta: { width: 1080, height: 1083 },
        mime: 'image/png',
        name: 'a1a898d233cdf6e4fc687e3b004a278f6ca16a14-359x360.png',
        path: '/vault/cH9onT_o/hkTkq3T5rJqVoXWJzNHykz86PEg/SosT5g../a1a898d233cdf6e4fc687e3b004a278f6ca16a14-359x360.png',
        size: 296814,
        type: 'image',
        access: 'public',
      },
      created_at: 1705870767662,
    },
    title: '20% Off Today Only!',
    budget: 38325,
    gender: ['male', 'female', 'other', 'prefer-not-to-say'],
    radius: 50000,
    content:
      'This is a special promotion for 20% Off Today Only!. Limited stock available. Hurry up!',
    max_age: 99,
    min_age: 1,
    is_active: true,
    asset_type: 'image',
    created_at: 1692200685629,
    creator_id: 147,
    expires_at: 1714253984066,
    hero_image: {
      id: 47,
      user_id: 147,
      attachment: {
        url: 'https://xhrm-5alx-lhb1.n7.xano.io/vault/cH9onT_o/iOAc_s-1qaufiSes_Vcqr250jco/e5VWRA../Tide.jpeg',
        meta: { width: 1734, height: 742 },
        mime: 'image/jpeg',
        name: 'Tide.jpeg',
        path: '/vault/cH9onT_o/iOAc_s-1qaufiSes_Vcqr250jco/e5VWRA../Tide.jpeg',
        size: 341821,
        type: 'image',
        access: 'public',
      },
      created_at: 1705871201060,
    },
    for_explore: true,
    addresses_id: 148,
    for_category: false,
    coverage_type: 'gps',
    redirect_link: 'https://promo.example.com/promo219',
    amount_to_user: 100,
    details_images: [
      {
        id: 78,
        user_id: 147,
        attachment: {
          url: 'https://xhrm-5alx-lhb1.n7.xano.io/vault/cH9onT_o/PXa3AfGT0vFXqpTti13epRasTKQ/MS1p-g../services_1.png',
          meta: { width: 1080, height: 1920 },
          mime: 'image/png',
          name: 'services_1.png',
          path: '/vault/cH9onT_o/PXa3AfGT0vFXqpTti13epRasTKQ/MS1p-g../services_1.png',
          size: 732747,
          type: 'image',
          access: 'public',
        },
        created_at: 1709198248491,
      },
    ],
    remaining_budget: 38105,
    amount_to_mailboxx: 10,
    _is_consumed_by_user: 199,
  },
};

const ReelsPlayerActionButtonsOverlayBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const segment = useAnalytics();
  const [isSaved, setIsSaved] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  React.useEffect(() => {
    try {
      setIsSaved(
        (props.promotion ?? defaultProps.promotion)?._saved_promotion_by_user
      );
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
    }
  }, []);

  return (
    <View
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App['Transparent Overlay'],
          borderBottomLeftRadius: 12,
          borderTopLeftRadius: 12,
          bottom: '30%',
          overflow: 'hidden',
          position: 'absolute',
          right: 0,
          zIndex: 2,
        },
        dimensions.width
      )}
    >
      <BlurView
        {...GlobalStyles.BlurViewStyles(theme)['Blur View'].props}
        intensity={10}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.BlurViewStyles(theme)['Blur View'].style,
            {
              alignItems: 'center',
              borderBottomLeftRadius: 12,
              borderTopLeftRadius: 12,
              flex: 1,
              paddingBottom: 8,
              paddingTop: 8,
            }
          ),
          dimensions.width
        )}
        tint={'dark'}
      >
        {/* SavePressable */}
        <Pressable
          onPress={() => {
            const handler = async () => {
              try {
                trackAction(
                  Variables,
                  segment,
                  'Clicked Save Button',
                  (props.promotion ?? defaultProps.promotion)?.id
                );
                setIsSaving(true);
                const saveResult = (
                  await PromotionsServiceApi.savePromotionPOST(Constants, {
                    promotion_id: (props.promotion ?? defaultProps.promotion)
                      ?.id,
                  })
                )?.json;
                setIsSaved(saveResult);
                setIsSaving(false);
              } catch (err) {
                Sentry.captureException(err);
                console.error(err);
              }
            };
            handler();
          }}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                height: 40,
                justifyContent: 'center',
                width: 40,
              },
              dimensions.width
            )}
          >
            <>
              {!isSaving ? null : (
                <ActivityIndicator
                  animating={true}
                  hidesWhenStopped={true}
                  size={'small'}
                  {...GlobalStyles.ActivityIndicatorStyles(theme)[
                    'Activity Indicator'
                  ].props}
                  color={palettes.App['Custom #dbdbdb']}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ActivityIndicatorStyles(theme)[
                      'Activity Indicator'
                    ].style,
                    dimensions.width
                  )}
                />
              )}
            </>
            {/* icons */}
            <>
              {isSaving ? null : (
                <View>
                  {/* icon-filled */}
                  <>
                    {!isSaved ? null : (
                      <Icon
                        color={palettes.App.White}
                        name={'AntDesign/heart'}
                        size={28}
                      />
                    )}
                  </>
                  {/* icon-empty */}
                  <>
                    {isSaved ? null : (
                      <Icon
                        color={palettes.App.White}
                        name={'AntDesign/hearto'}
                        size={28}
                      />
                    )}
                  </>
                </View>
              )}
            </>
          </View>
        </Pressable>
        {/* ShareButton */}
        <IconButton
          onPress={() => {
            const handler = async () => {
              try {
                trackAction(
                  Variables,
                  segment,
                  'Clicked Share Button',
                  (props.promotion ?? defaultProps.promotion)?.id
                );
                await openShareUtil(
                  `${
                    'https://www.mailboxx.app/promo/' +
                    (props.promotion ?? defaultProps.promotion)?.id
                  }`
                );
                await copyToClipboard(
                  'https://www.mailboxx.app/promo/' +
                    (props.promotion ?? defaultProps.promotion)?.id
                );
              } catch (err) {
                Sentry.captureException(err);
                console.error(err);
              }
            };
            handler();
          }}
          color={palettes.App.White}
          icon={'MaterialCommunityIcons/share'}
          size={28}
          style={StyleSheet.applyWidth({ marginTop: 12 }, dimensions.width)}
        />
        {/* LinkButton */}
        <IconButton
          onPress={() => {
            const handler = async () => {
              try {
                trackAction(
                  Variables,
                  segment,
                  'Clicked Link Button',
                  undefined
                );
                await WebBrowser.openBrowserAsync(
                  `${
                    (props.promotion ?? defaultProps.promotion)?.redirect_link
                  }`
                );
              } catch (err) {
                Sentry.captureException(err);
                console.error(err);
              }
            };
            handler();
          }}
          color={palettes.App.White}
          icon={'Feather/globe'}
          size={28}
          style={StyleSheet.applyWidth({ marginTop: 12 }, dimensions.width)}
        />
      </BlurView>
    </View>
  );
};

export default withTheme(ReelsPlayerActionButtonsOverlayBlock);
