import React from 'react';
import {
  Button,
  Icon,
  NumberInput,
  Pressable,
  ScreenContainer,
  SimpleStyleKeyboardAwareScrollView,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BalanceServiceApi from '../apis/BalanceServiceApi.js';
import WideHeaderBlock from '../components/WideHeaderBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as Stripe from '../custom-files/Stripe';
import centToDollar from '../global-functions/centToDollar';
import isLoggedIn from '../global-functions/isLoggedIn';
import trackAction from '../global-functions/trackAction';
import trackScreen from '../global-functions/trackScreen';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { mode: 'credit' };

const BalanceUpdateScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const segment = useAnalytics();
  const [centValue, setCentValue] = React.useState('00');
  const [dollarValue, setDollarValue] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isUpdating, setIsUpdating] = React.useState(false);
  const [maxBalance, setMaxBalance] = React.useState(0);
  const [noteValue, setNoteValue] = React.useState('');
  const [numberInputValue, setNumberInputValue] = React.useState('');
  const [textAreaValue, setTextAreaValue] = React.useState('');
  const verifyForm = () => {
    const dollar = parseInt(dollarValue);

    if (isNaN(dollar)) {
      setErrorMessage('Please enter a valid number');
      return false;
    }

    if (dollar === 0) {
      setErrorMessage('Please enter a number higher than 0');
      return false;
    }

    if (props.route?.params?.mode === 'debit') {
      const tot = totalAmount();

      if (tot > maxBalance) {
        setErrorMessage(
          `The maximum number you can withrow is ${centToDollar(maxBalance)}`
        );
        return false;
      }
    }

    setErrorMessage('');
    return true;
  };

  const totalAmount = comission => {
    const totalFee = dollarValue * 100 + (parseInt(centValue) | 0);

    if (!comission) return totalFee;

    return totalFee - (totalFee * comission) / 100;
  };
  const balanceServiceUpdateBalancePOST =
    BalanceServiceApi.useUpdateBalancePOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      console.log(Constants['USER_DATA']);
      if (!isLoggedIn(Variables)) {
        navigation.navigate('AuthLoginScreen');
      }
      trackScreen(segment, 'Balance Update');
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={true}
      scrollable={true}
      style={StyleSheet.applyWidth(
        {
          alignItems: 'stretch',
          backgroundColor: {
            minWidth: Breakpoints.Desktop,
            value: palettes.App.CustomGray,
          },
        },
        dimensions.width
      )}
    >
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <WideHeaderBlock currentScreen={'balance'} withGoBack={true} />
        )}
      </>
      {/* Header */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            {...GlobalStyles.ViewStyles(theme)['Header'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['Header'].style,
              dimensions.width
            )}
          >
            {/* AbsoluteTitle */}
            <View
              {...GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].style,
                dimensions.width
              )}
            >
              <Image
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                resizeMode={'contain'}
                source={imageSource(Images['MailboxxGreenLogo'])}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { height: 30, width: 120 }
                  ),
                  dimensions.width
                )}
              />
            </View>
            {/* BackButton */}
            <Pressable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              {...GlobalStyles.PressableStyles(theme)['BackButton'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.PressableStyles(theme)['BackButton'].style,
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={palettes.App['Custom Color_9']}
                  name={'Ionicons/chevron-back'}
                  size={28}
                />
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['HeaderTitleText'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['HeaderTitleText'].style,
                      { color: palettes.App.customGreen }
                    ),
                    dimensions.width
                  )}
                >
                  {'Back'}
                </Text>
              </View>
            </Pressable>
          </View>
        )}
      </>
      {/* Body */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            alignSelf: { minWidth: Breakpoints.Desktop, value: 'center' },
            backgroundColor: {
              minWidth: Breakpoints.Desktop,
              value: theme.colors.background.brand,
            },
            flex: 1,
            maxWidth: { minWidth: Breakpoints.Desktop, value: 1024 },
            width: { minWidth: Breakpoints.Desktop, value: '100%' },
          },
          dimensions.width
        )}
      >
        <SimpleStyleKeyboardAwareScrollView
          enableOnAndroid={false}
          enableResetScrollToCoords={false}
          showsVerticalScrollIndicator={true}
          viewIsInsideTabBar={false}
          enableAutomaticScroll={true}
          keyboardShouldPersistTaps={'always'}
          style={StyleSheet.applyWidth({ maxWidth: 1024 }, dimensions.width)}
        >
          <View>
            {/* CreditHeader */}
            <>
              {!(
                (props.route?.params?.mode ?? defaultProps.mode) === 'credit'
              ) ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      paddingBottom: 24,
                      paddingLeft: 32,
                      paddingRight: 32,
                      paddingTop: 34,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: theme.colors.text.strong,
                          fontSize: 32,
                          marginBottom: 6,
                          textAlign: 'center',
                          typography: theme.typography.headline4,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Add Credit'}
                  </Text>
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: theme.colors.text.strong,
                          marginBottom: 6,
                          textAlign: 'center',
                          typography: theme.typography.headline4,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Please use this form to add credits to your wallet'}
                  </Text>
                </View>
              )}
            </>
          </View>
          {/* WithdrawHeader */}
          <>
            {!(
              (props.route?.params?.mode ?? defaultProps.mode) === 'debit'
            ) ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    paddingBottom: 24,
                    paddingLeft: 32,
                    paddingRight: 32,
                    paddingTop: 34,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: theme.colors.text.strong,
                        fontSize: 32,
                        marginBottom: 6,
                        textAlign: 'center',
                        typography: theme.typography.headline4,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Withdraw Credit'}
                </Text>
                {/* Text 2 */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: theme.colors.text.strong,
                        marginBottom: 6,
                        textAlign: 'center',
                        typography: theme.typography.headline4,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Please use this form to withdraw credits from your wallet'}
                </Text>
              </View>
            )}
          </>
          {/* View 3 */}
          <View style={StyleSheet.applyWidth({ margin: 20 }, dimensions.width)}>
            <>
              {!(
                (props.route?.params?.mode ?? defaultProps.mode) === 'debit'
              ) ? null : (
                <BalanceServiceApi.FetchGetBalanceGET
                  handlers={{
                    onData: fetchData => {
                      try {
                        setMaxBalance(fetchData?.balance);
                      } catch (err) {
                        Sentry.captureException(err);
                        console.error(err);
                      }
                    },
                  }}
                >
                  {({ loading, error, data, refetchGetBalance }) => {
                    const fetchData = data?.json;
                    if (loading) {
                      return <ActivityIndicator />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          dimensions.width
                        )}
                      >
                        {'Your available balance is $'}
                        {centToDollar(fetchData?.balance)}
                      </Text>
                    );
                  }}
                </BalanceServiceApi.FetchGetBalanceGET>
              )}
            </>
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'flex-end', flexDirection: 'row' },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth({ flex: 0.85 }, dimensions.width)}
              >
                {/* DollarInput */}
                <NumberInput
                  changeTextDelay={500}
                  onChangeText={newDollarInputValue => {
                    try {
                      setDollarValue(newDollarInputValue);
                    } catch (err) {
                      Sentry.captureException(err);
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.NumberInputStyles(theme)['Number Input']
                    .props}
                  autoFocus={true}
                  enablesReturnKeyAutomatically={true}
                  keyboardType={'numeric'}
                  placeholder={'Enter an amount'}
                  placeholderTextColor={theme.colors.text.light}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.NumberInputStyles(theme)['Number Input'].style,
                    dimensions.width
                  )}
                  value={dollarValue}
                />
              </View>

              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { marginLeft: 6, marginRight: 6 }
                  ),
                  dimensions.width
                )}
              >
                {'.'}
              </Text>
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth({ flex: 0.15 }, dimensions.width)}
              >
                {/* CentInput */}
                <NumberInput
                  changeTextDelay={500}
                  onChangeText={newCentInputValue => {
                    try {
                      setCentValue(newCentInputValue);
                    } catch (err) {
                      Sentry.captureException(err);
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.NumberInputStyles(theme)['Number Input']
                    .props}
                  maxLength={2}
                  placeholder={'00'}
                  placeholderTextColor={theme.colors.text.light}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.NumberInputStyles(theme)['Number Input'].style,
                    dimensions.width
                  )}
                  value={centValue}
                />
              </View>
            </View>
            {/* ComissionsView */}
            <>
              {!(
                totalAmount(undefined) > 0 &&
                Constants['USER_DATA']?.role === 'business' &&
                (props.route?.params?.mode ?? defaultProps.mode) === 'debit'
              ) ? null : (
                <View>
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      dimensions.width
                    )}
                  >
                    {'Transaction Fee (3.99%) : $'}
                    {centToDollar(totalAmount(3.99))}
                  </Text>
                </View>
              )}
            </>
            <TextInput
              autoCorrect={true}
              changeTextDelay={500}
              multiline={true}
              numberOfLines={4}
              onChangeText={newTextAreaValue => {
                try {
                  setNoteValue(newTextAreaValue);
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              textAlignVertical={'top'}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['Text Area'].props}
              placeholder={'Note'}
              placeholderTextColor={theme.colors.text.light}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextInputStyles(theme)['Text Area'].style,
                  { marginTop: 20, minHeight: 100 }
                ),
                dimensions.width
              )}
              value={noteValue}
            />
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}
            >
              {/* ErrorMessage */}
              <>
                {!errorMessage ? null : (
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: theme.colors.background.danger,
                          marginBottom: 8,
                          marginLeft: 8,
                          marginRight: 8,
                          marginTop: 8,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {errorMessage}
                  </Text>
                )}
              </>
              {/* AddBalanceButton */}
              <>
                {!(
                  (props.route?.params?.mode ?? defaultProps.mode) === 'credit'
                ) ? null : (
                  <Utils.CustomCodeErrorBoundary>
                    <Stripe.PaymentButton
                      theme={props.theme}
                      navigation={props.navigation}
                      amount={totalAmount()}
                      description={noteValue}
                      setErrorMessage={setErrorMessage}
                      verify={verifyForm}
                    />
                  </Utils.CustomCodeErrorBoundary>
                )}
              </>
              {/* WithdrawButton */}
              <>
                {!(
                  (props.route?.params?.mode ?? defaultProps.mode) === 'debit'
                ) ? null : (
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          if (!verifyForm()) {
                            return;
                          }
                          setIsUpdating(true);
                          const updateBalanceResult = (
                            await balanceServiceUpdateBalancePOST.mutateAsync({
                              amount: totalAmount(undefined),
                              note: noteValue,
                              type: 'debit',
                            })
                          )?.json;
                          trackAction(
                            Variables,
                            segment,
                            'Clicked on Withdraw Credit',
                            undefined
                          );
                          setIsUpdating(false);
                          if (updateBalanceResult?.message) {
                            setErrorMessage(updateBalanceResult?.message);
                          }
                          if (!updateBalanceResult?.message) {
                            navigation.navigate('BalanceScreen');
                          }
                        } catch (err) {
                          Sentry.captureException(err);
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    disabled={isUpdating}
                    loading={isUpdating}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      dimensions.width
                    )}
                    title={'Withdraw Credit'}
                  />
                )}
              </>
            </View>
          </View>
        </SimpleStyleKeyboardAwareScrollView>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(BalanceUpdateScreen);
