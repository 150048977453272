import React from 'react';
import { Button, Icon, ScreenContainer, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoServiceApi from '../apis/XanoServiceApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import trackScreen from '../global-functions/trackScreen';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import getPushTokenUtil from '../utils/getPushToken';
import useWindowDimensions from '../utils/useWindowDimensions';

const AuthNotificationPermissionsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const [isLoading, setIsLoading] = React.useState(false);
  const xanoServiceTogglePushTokenPOST =
    XanoServiceApi.useTogglePushTokenPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      trackScreen(segment, 'Notification Permissions');
      if (!Constants['AuthToken']) {
        navigation.navigate('AuthLoginScreen');
      }
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasSafeArea={true}
      style={StyleSheet.applyWidth(
        {
          alignItems: 'center',
          backgroundColor: {
            minWidth: Breakpoints.Desktop,
            value: palettes.App.CustomGray,
          },
          justifyContent: 'space-around',
          padding: 16,
        },
        dimensions.width
      )}
    >
      <View
        style={StyleSheet.applyWidth(
          {
            alignSelf: { minWidth: Breakpoints.Desktop, value: 'center' },
            backgroundColor: {
              minWidth: Breakpoints.Desktop,
              value: theme.colors.background.brand,
            },
            flex: 1,
            maxWidth: { minWidth: Breakpoints.Desktop, value: 1024 },
            width: '80%',
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center', flex: 1, justifyContent: 'center' },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: palettes.App.CustomGray,
                borderRadius: 100,
                height: 128,
                justifyContent: 'center',
                marginBottom: 30,
                width: 128,
              },
              dimensions.width
            )}
          >
            <Icon
              color={palettes.App.customGreen}
              name={'AntDesign/setting'}
              size={90}
            />
          </View>

          <Text
            accessible={true}
            style={StyleSheet.applyWidth(
              {
                fontFamily: 'Poppins_500Medium',
                fontSize: 24,
                lineHeight: 32,
                textAlign: 'center',
              },
              dimensions.width
            )}
          >
            {'Enable Notifications'}
          </Text>

          <Text
            accessible={true}
            style={StyleSheet.applyWidth(
              {
                color: theme.colors.text.medium,
                fontFamily: 'Poppins_200ExtraLight',
                fontSize: 16,
                lineHeight: 24,
                textAlign: 'center',
              },
              dimensions.width
            )}
          >
            {'Stay up to date with the latest updates and alerts. '}
          </Text>
        </View>

        <View
          style={StyleSheet.applyWidth(
            {
              justifyContent: 'space-evenly',
              marginBottom: { minWidth: Breakpoints.Desktop, value: 40 },
              paddingLeft: { minWidth: Breakpoints.Desktop, value: 20 },
              paddingRight: { minWidth: Breakpoints.Desktop, value: 20 },
            },
            dimensions.width
          )}
        >
          {/* SkipButton */}
          <Button
            iconPosition={'left'}
            onPress={() => {
              try {
                navigation.navigate('AddAddressScreen', { isOnboarding: true });
              } catch (err) {
                Sentry.captureException(err);
                console.error(err);
              }
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Button'].style,
                {
                  alignItems: 'center',
                  backgroundColor: theme.colors.background.brand,
                  color: theme.colors.text.light,
                  flexDirection: 'row',
                  justifyContent: 'center',
                }
              ),
              dimensions.width
            )}
            title={'SKIP'}
          >
            {'Sign Up'}
          </Button>
          {/* EnableNotificationsButton */}
          <Button
            iconPosition={'left'}
            onPress={() => {
              const handler = async () => {
                try {
                  const Token = await getPushTokenUtil({
                    permissionErrorMessage:
                      'Failed to get push token for push notification!',
                    deviceErrorMessage:
                      'Must use physical device for Push Notifications',
                    showAlertOnPermissionError: true,
                    showAlertOnDeviceError: true,
                  });

                  setIsLoading(true);
                  const togglePushTokenResult = (
                    await xanoServiceTogglePushTokenPOST.mutateAsync({
                      push_token: Token,
                    })
                  )?.json;
                  setGlobalVariableValue({
                    key: 'USER_DATA',
                    value: togglePushTokenResult,
                  });
                  setIsLoading(false);
                  if (!togglePushTokenResult?.message) {
                    navigation.navigate('AddAddressScreen', {
                      isOnboarding: true,
                    });
                  }
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              };
              handler();
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            disabled={isLoading}
            loading={isLoading}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Button'].style,
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  marginTop: 16,
                }
              ),
              dimensions.width
            )}
            title={'ENABLE NOTIFICATIONS'}
          >
            {'Sign Up'}
          </Button>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(AuthNotificationPermissionsScreen);
