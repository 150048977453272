import palettes from '../themes/palettes';
// This import is required if you are defining react components in this module.
import React, { useState, useRef, useEffect } from 'react';

import { View } from 'react-native';
import PhoneInput from 'react-native-phone-number-input';
import { Icon } from '@draftbit/ui';

// Define and export your components as named exports here.

// You can use components exported from this file within a Custom Code component as
// <CustomCode.MyExampleComponent />
export const CountryPickerComponent = ({
  setNumberValid,
  setNumber,
  theme,
}) => {
  const phoneInput = useRef(null);
  const [value, setValue] = useState('');
  const [formattedValue, setFormattedValue] = useState('');
  useEffect(() => {
    setNumberValid(phoneInput.current?.isValidNumber(value));
    setNumber(
      formattedValue
        .replaceAll('(', '')
        .replaceAll(')', '')
        .replaceAll('-', '')
        .replaceAll(' ', '')
    );
  }, [value]);
  return (
    <View style={{ marginTop: 20 }}>
      <PhoneInput
        ref={phoneInput}
        defaultValue={value}
        defaultCode="US"
        layout="first"
        onChangeText={text => {
          setValue(text);
        }}
        onChangeFormattedText={text => {
          setFormattedValue(text);
        }}
        // withShadow
        containerStyle={{
          height: 40,
          borderRadius: 30,
          backgroundColor: 'rgb(244, 244, 244)',
          width: '100%',
        }}
        textContainerStyle={{
          borderTopRightRadius: 8,
          height: 40,
          borderBottomRightRadius: 8,
          backgroundColor: 'rgb(244, 244, 244)',
        }}
        flagButtonStyle={{
          borderTopLeftRadius: 8,
          borderBottomLeftRadius: 8,
          backgroundColor: 'rgb(244, 244, 244)',
        }}
        codeTextStyle={{
          fontSize: 12,
          height: 40,
          marginTop: 25,
        }}
        textInputStyle={{
          fontSize: 12,
          height: 40,
        }}
        textInputProps={{
          placeholderTextColor: theme.colors.text.light,
        }}
        autoFocus={false}
        countryPickerProps={{
          withCloseButton: true,
          closeButtonImageStyle: {
            // backgroundColor:'red'
          },
          // closeButtonImage:   <Icon
          //     color={palettes.App['Custom Color_9']}
          //     name={'Ionicons/chevron-back'}
          //     size={28}
          //   />
        }}
      />
    </View>
  );
};
