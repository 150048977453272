import React from 'react';
import { Icon, Pressable, withTheme } from '@draftbit/ui';
import { useNavigation } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import centToDollar from '../global-functions/centToDollar';
import trackAction from '../global-functions/trackAction';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { availableBalance: 40000 };

const BalanceButtonBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const navigation = useNavigation();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const segment = useAnalytics();

  return (
    <Pressable
      onPress={() => {
        try {
          trackAction(Variables, segment, 'Clicked Balance Block', undefined);
          navigation.navigate('BalanceScreen');
        } catch (err) {
          Sentry.captureException(err);
          console.error(err);
        }
      }}
      style={StyleSheet.applyWidth({ marginRight: 10 }, dimensions.width)}
    >
      <View
        style={StyleSheet.applyWidth(
          { alignItems: 'center', flexDirection: 'row' },
          dimensions.width
        )}
      >
        <Icon
          color={theme.colors.text.strong}
          name={'Entypo/wallet'}
          size={16}
          style={StyleSheet.applyWidth({ marginRight: 4 }, dimensions.width)}
        />
        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              color: theme.colors.text.strong,
            }),
            dimensions.width
          )}
        >
          {'$'}
          {centToDollar(
            props.availableBalance ?? defaultProps.availableBalance
          )}
        </Text>
      </View>
    </Pressable>
  );
};

export default withTheme(BalanceButtonBlock);
