import React, { useEffect, useRef, useState } from 'react';
import {
  View,
  StyleSheet,
  Dimensions,
  FlatList,
  // Image
} from 'react-native';
import { Video } from 'expo-av';
import { Image } from './Lib';

// Screen Dimensions
const ScreenWidth = Dimensions.get('window').width;
const ScreenHeight = Dimensions.get('window').height;
const blurhash =
  '|rF?hV%2WCj[ayj[a|j[az_NaeWBj@ayfRayfQfQM{M|azj[azf6fQfQfQIpWXofj[ayj[j[fQayWCoeoeaya}j[ayfQa{oLj?j[WVj[ayayj[fQoff7azayj[ayj[j[ayofayayayj[fQj[ayayj[ayfjj[j[ayjuayj[';

function ReelCard({
  currentPromotion,
  viewableItem,
  index,

  backgroundColor = 'black',
  pauseOnOptionsShow = true,
  onFinishPlaying = () => {},
}) {
  // ref for Video Player
  const VideoPlayer = useRef(null);

  // States
  const [VideoDimensions, SetVideoDimensions] = useState({
    width: ScreenWidth,
    height: ScreenWidth,
  });

  // Play/Pause video according to visibility
  useEffect(() => {
    if (viewableItem.id === currentPromotion.id) PlayVideo();
    else PauseVideo();
  }, [viewableItem.id]);

  // Pause when use toggle options to True
  useEffect(() => {
    if (pauseOnOptionsShow) {
      PlayVideo();
    }
  }, [pauseOnOptionsShow]);

  // Callback for PlayBackStatusUpdate
  const PlayBackStatusUpdate = playbackStatus => {
    if (!playbackStatus.isLoaded) {
      if (playbackStatus?.error) {
      }
    } else {
      if (playbackStatus.positionMillis)
        if (playbackStatus.durationMillis)
          if (playbackStatus.didJustFinish && !playbackStatus.isLooping) {
            onFinishPlaying(index);
          }
    }
  };

  // function for playing video
  const PlayVideo = async () => {
    try {
      if (VideoPlayer.current !== null) {
        VideoPlayer?.current.playAsync();
      }
    } catch (error) {}
  };

  // function for pausing video
  const PauseVideo = async () => {
    try {
      if (VideoPlayer.current !== null) {
        VideoPlayer?.current.pauseAsync();
      }
    } catch (error) {}
  };

  // function for getting video dimensions on load complete
  const onLoadComplete = async event => {
    let status = event.status;
    const { naturalSize } = event;

    try {
      const naturalWidth = naturalSize.width;
      const naturalHeight = naturalSize.height;
      if (naturalWidth > naturalHeight) {
        SetVideoDimensions({
          width: ScreenWidth,
          height: ScreenWidth * (naturalHeight / naturalWidth),
        });
      } else {
        SetVideoDimensions({
          width: ScreenHeight * (naturalWidth / naturalHeight),
          height: ScreenHeight,
        });
      }
    } catch (error) {}
  };
  console.log('currentPromotion', currentPromotion?.details_images);
  return (
    <View style={[styles.container, { backgroundColor: backgroundColor }]}>
      {!(currentPromotion?.asset_type === 'image') ? null : (
        <AutoScrollFlatList
          data={currentPromotion?.details_images || []}
          keyExtractor={(swiperData, index) => swiperData?.id}
          renderItem={({ item, index }) => {
            const swiperData = item;
            return (
              <View style={{ width: ScreenWidth, minHeight: ScreenHeight }}>
                <Image
                  resizeMode={'cover'}
                  source={{ uri: `${swiperData?.attachment?.url}` }}
                  style={{
                    height: '100%',
                    width: '100%',
                    backgroundColor: '#0553',
                  }}
                  // source={swiperData?.attachment?.url}
                  // style={ {
                  //   flex: 1,
                  //   width: '100%',
                  //   backgroundColor: '#0553',
                  // }}
                  placeholder={blurhash}
                  // contentFit="cover"
                  transition={1000}
                />
              </View>
            );
          }}
        />
      )}

      {!(currentPromotion?.asset_type === 'video') ? null : (
        <Video
          ref={VideoPlayer}
          source={{
            uri: `${
              (
                currentPromotion?.details_images &&
                (currentPromotion?.details_images)[0]
              )?.attachment?.url
            }`,
          }}
          resizeMode="contain"
          shouldPlay
          onPlaybackStatusUpdate={PlayBackStatusUpdate}
          onReadyForDisplay={onLoadComplete}
          style={{ height: ScreenHeight, width: ScreenWidth }}
          videoStyle={{ height: '100%', width: '100%' }}
          isMuted
          isLooping
        />
      )}
    </View>
  );
}

// Exports
export default ReelCard;

const AutoScrollFlatList = ({ data, ...props }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const flatListRef = useRef();
  const viewabilityConfig = useRef({
    itemVisiblePercentThreshold: 50, // Consider an item focused when 50% of it is visible
  }).current;

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentIndex(prevIndex => {
  //       const nextIndex = prevIndex + 1 === data.length ? 0 : prevIndex + 1;
  //       return nextIndex;
  //     });
  //   }, 5000);

  //   return () => clearInterval(interval);
  // }, [data.length]);

  useEffect(() => {
    if (flatListRef.current && data.length > 0) {
      flatListRef.current.scrollToIndex({
        animated: true,
        index: currentIndex,
        viewPosition: 0.5,
      });
    }
  }, [currentIndex, data.length]);

  const onViewableItemsChanged = useRef(({ viewableItems }) => {
    if (viewableItems.length > 0) {
      setCurrentIndex(viewableItems[0].index);
    }
  });

  const renderDots = () => (
    <View style={styles.dotsContainer}>
      {data.map((_, index) => (
        <View
          key={index}
          style={[
            styles.dot,
            currentIndex === index ? styles.activeDot : styles.inactiveDot,
          ]}
        />
      ))}
    </View>
  );

  return (
    <View style={{ flex: 1 }}>
      <FlatList
        ref={flatListRef}
        data={data}
        horizontal={true}
        pagingEnabled={true}
        showsHorizontalScrollIndicator={false}
        onViewableItemsChanged={onViewableItemsChanged.current}
        viewabilityConfig={viewabilityConfig}
        {...props}
      />
      {renderDots()}
    </View>
  );
};

// Stylesheet
const styles = StyleSheet.create({
  container: {
    width: ScreenWidth,
    height: ScreenHeight,
    justifyContent: 'center',
    zIndex: 0,
  },

  dotsContainer: {
    position: 'absolute',
    bottom: 50,
    width: ScreenWidth,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dot: {
    width: 8,
    height: 8,
    borderRadius: 4,
    marginHorizontal: 4,
  },
  activeDot: {
    backgroundColor: 'white',
  },
  inactiveDot: {
    backgroundColor: 'white',
    opacity: 0.5,
  },
});
