import { Platform } from 'react-native';

const trackAction = (Variables, segment, action, properties) => {
  try {
    // console.log('segment track action', action, properties, Variables)
    segment.track(action, {
      ...properties,
      platform: Platform.OS,
      user: {
        email: Variables?.USER_DATA?.email || undefined,
        role: Variables?.USER_DATA?.role?.role || undefined,
        gender: Variables?.USER_DATA?.gender?.gender || undefined,
        business_categories_id:
          Variables?.USER_DATA?.business_categories_id
            ?.business_categories_id || undefined,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export default trackAction;
