import React from 'react';
import { IconButton, withTheme } from '@draftbit/ui';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import { Image, Keyboard, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { title: 'Settings' };

const NarrowHeaderWithDrawerBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();

  return (
    <View
      {...GlobalStyles.ViewStyles(theme)['Header'].props}
      style={StyleSheet.applyWidth(
        GlobalStyles.ViewStyles(theme)['Header'].style,
        dimensions.width
      )}
    >
      {/* MenuButton */}
      <IconButton
        onPress={() => {
          try {
            Keyboard.dismiss();
            setGlobalVariableValue({
              key: 'SHOW_DRAWER',
              value: true,
            });
          } catch (err) {
            Sentry.captureException(err);
            console.error(err);
          }
        }}
        size={32}
        {...GlobalStyles.IconButtonStyles(theme)['MenuButton'].props}
        color={theme.colors.branding.primary}
        icon={'Entypo/menu'}
        style={StyleSheet.applyWidth(
          GlobalStyles.IconButtonStyles(theme)['MenuButton'].style,
          dimensions.width
        )}
      />
      {/* AbsoluteTitle */}
      <View
        {...GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].style,
          dimensions.width
        )}
      >
        <Image
          {...GlobalStyles.ImageStyles(theme)['Image'].props}
          resizeMode={'contain'}
          source={imageSource(Images['MailboxxGreenLogo'])}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'].style, {
              height: 30,
              width: 120,
            }),
            dimensions.width
          )}
        />
      </View>
    </View>
  );
};

export default withTheme(NarrowHeaderWithDrawerBlock);
