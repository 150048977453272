import React from 'react';
import {
  Button,
  Divider,
  Icon,
  IconButton,
  LinearGradient,
  Link,
  Pressable,
  Slider,
  Surface,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  Modal,
  Platform,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BalanceServiceApi from '../apis/BalanceServiceApi.js';
import * as PromotionsServiceApi from '../apis/PromotionsServiceApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import amountToUserText from '../global-functions/amountToUserText';
import centToDollar from '../global-functions/centToDollar';
import formatDate from '../global-functions/formatDate';
import toRelativeDateString from '../global-functions/toRelativeDateString';
import trackAction from '../global-functions/trackAction';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  item: {
    id: 10,
    logo: {
      id: 100,
      user_id: 79,
      adobe_urn: '',
      attachment: {
        url: 'https://xhrm-5alx-lhb1.n7.xano.io/vault/cH9onT_o/MrU-P_1Z0OCIrzxPX5YgiheVG1w/HEqE8g../file-cfa3ee.jpeg',
        meta: { width: 1024, height: 1024 },
        mime: 'image/jpeg',
        name: 'file-cfa3ee.jpeg',
        path: '/vault/cH9onT_o/MrU-P_1Z0OCIrzxPX5YgiheVG1w/HEqE8g../file-cfa3ee.jpeg',
        size: 146238,
        type: 'image',
        access: 'public',
      },
      created_at: 1725363314382,
    },
    title: 'Gourmet Brunch Special',
    budget: 5000,
    gender: ['female', 'prefer-not-to-say', 'male', 'other'],
    radius: 0,
    content:
      'Join us every Sunday for our gourmet brunch special, featuring freshly baked pastries and artisan coffee.',
    max_age: 29,
    min_age: 19,
    is_active: true,
    asset_type: 'image',
    created_at: 1725375579000,
    creator_id: 79,
    expires_at: 1759503567000,
    hero_image: {
      id: 144,
      user_id: 79,
      adobe_urn: '',
      attachment: {
        url: 'https://xhrm-5alx-lhb1.n7.xano.io/vault/cH9onT_o/Ji5_DqOHApnGbIqMRWeXxeDNqrs/FIPYTg../file-f31ffa.jpeg',
        meta: { width: 1024, height: 512 },
        mime: 'image/jpeg',
        name: 'file-f31ffa.jpeg',
        path: '/vault/cH9onT_o/Ji5_DqOHApnGbIqMRWeXxeDNqrs/FIPYTg../file-f31ffa.jpeg',
        size: 153281,
        type: 'image',
        access: 'public',
      },
      created_at: 1725375285443,
    },
    can_consume: true,
    for_explore: false,
    addresses_id: null,
    company_name: 'Gourmet Bistro',
    for_category: true,
    coverage_type: 'global',
    redirect_link: 'https://gourmetbistro.com/brunch-special',
    amount_to_user: 0,
    details_images: [
      {
        id: 145,
        user_id: 79,
        adobe_urn: 'urn:aaid:sc:AP:a7ed01a0-5c95-4302-b1be-4ad8402d8141',
        attachment: {
          url: 'https://xhrm-5alx-lhb1.n7.xano.io/vault/cH9onT_o/LGV9_Ngnrqb4A4qULk9-va7y8DA/_Me-MQ../file-25b5e8.png',
          meta: { width: 1080, height: 1920 },
          mime: 'image/png',
          name: 'file-25b5e8.png',
          path: '/vault/cH9onT_o/LGV9_Ngnrqb4A4qULk9-va7y8DA/_Me-MQ../file-25b5e8.png',
          size: 966273,
          type: 'image',
          access: 'public',
        },
        created_at: 1725375462393,
      },
    ],
    remaining_budget: 5000,
    amount_to_mailboxx: 0,
    business_categories_id: 2,
  },
  mode: 'card',
  onCardSelect: () => {},
  onChange: '',
  useNavigate: true,
};

const CardBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const navigation = useNavigation();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const [cardItem, setCardItem] = React.useState(
    props.item ?? defaultProps.item
  );
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [isUpdating, setIsUpdating] = React.useState(false);
  const [newBudgetValue, setNewBudgetValue] = React.useState(0);
  const [showBudgetModal, setShowBudgetModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  const isExpired = () => {
    return cardItem?.expires_at <= Date.now();
  };

  const now = () => {
    return Date.now();
  };

  const onChangeFunction = () => {
    props?.onChange && props?.onChange();
  };

  const hideReelsPlayer = () => {
    setShowReelsPlayer(false);
  };
  const promotionsServiceReCreatePromotionPOST =
    PromotionsServiceApi.useReCreatePromotionPOST();
  const promotionsServiceTogglePromotionPATCH =
    PromotionsServiceApi.useTogglePromotionPATCH();
  const promotionsServiceStopPromotionPATCH =
    PromotionsServiceApi.useStopPromotionPATCH();
  const promotionsServiceDeletePromotionDELETE =
    PromotionsServiceApi.useDeletePromotionDELETE();
  const promotionsServiceUpdatePromotionBudgetPATCH =
    PromotionsServiceApi.useUpdatePromotionBudgetPATCH();
  React.useEffect(() => {
    try {
      /* hidden 'Log to Console' action */
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
    }
  }, []);

  return (
    <View>
      {/* CARD */}
      <Surface
        {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
        elevation={2}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.SurfaceStyles(theme)['Surface'].style,
            {
              borderColor: palettes.App['Custom #dbdbdb'],
              borderRadius: 10,
              borderWidth: 1,
              minHeight: 140,
              overflow: 'hidden',
              zIndex: 1,
            }
          ),
          dimensions.width
        )}
      >
        {/* MenuButton */}
        <>
          {!(Constants['USER_DATA']?.id === cardItem?.creator_id) ? null : (
            <IconButton
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'CARD_TO_SHOW_MENU',
                    value: cardItem?.id,
                  });
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              color={theme.colors.text.medium}
              icon={'Entypo/dots-three-horizontal'}
              size={28}
              style={StyleSheet.applyWidth(
                { position: 'absolute', right: 5, top: 5, zIndex: 100 },
                dimensions.width
              )}
            />
          )}
        </>
        <Pressable
          onPress={() => {
            try {
              trackAction(
                Variables,
                segment,
                'Clicked Promotion Card',
                cardItem?.id
              );
              if ((props.mode ?? defaultProps.mode) === 'card') {
                if (cardItem?.for_category) {
                  if (!(props.useNavigate ?? defaultProps.useNavigate)) {
                    props.onCardSelect?.(undefined);
                  }
                  if (props.useNavigate ?? defaultProps.useNavigate) {
                    navigation.navigate('ViewPromotionScreen', {
                      PromotionId: cardItem?.id,
                    });
                  }
                } else {
                  navigation.navigate('ViewPromotionScreen', {
                    PromotionId: cardItem?.id,
                  });
                }
              } else {
                navigation.navigate('PromotionStatsScreen', {
                  promotion_id: cardItem?.id,
                });
                /* hidden 'If/Else' action */
              }
            } catch (err) {
              Sentry.captureException(err);
              console.error(err);
            }
          }}
        >
          {/* CardMode */}
          <>
            {!((props.mode ?? defaultProps.mode) === 'card') ? null : (
              <View
                style={StyleSheet.applyWidth(
                  { borderRadius: 10, overflow: 'hidden' },
                  dimensions.width
                )}
              >
                <ImageBackground
                  resizeMode={'cover'}
                  backfaceVisibility={'hidden'}
                  source={{ uri: `${cardItem?.hero_image?.attachment?.url}` }}
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'stretch',
                      alignSelf: 'auto',
                      height: 140,
                      justifyContent: 'space-between',
                      margin: 0,
                      padding: 0,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* NoPriceTagView */}
                  <>
                    {cardItem?.amount_to_user &&
                    cardItem?.can_consume ? null : (
                      <View />
                    )}
                  </>
                  {/* PriceTag */}
                  <>
                    {!(
                      cardItem?.amount_to_user && cardItem?.can_consume
                    ) ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginLeft: 5,
                            marginRight: 5,
                            marginTop: 5,
                            position: 'relative',
                          },
                          dimensions.width
                        )}
                      >
                        {/* UnUsedTag */}
                        <>
                          {cardItem?._is_consumed_by_user ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  alignSelf: 'auto',
                                  backgroundColor: palettes.App.customGreen,
                                  borderRadius: 10,
                                  flexDirection: 'column',
                                  height: 20,
                                  justifyContent: 'center',
                                  width: 40,
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    { color: palettes.App.White }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {amountToUserText(cardItem?.amount_to_user)}
                              </Text>
                            </View>
                          )}
                        </>
                        {/* UsedTag */}
                        <>
                          {!cardItem?._is_consumed_by_user ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  alignSelf: 'auto',
                                  backgroundColor: theme.colors.text.light,
                                  borderRadius: 10,
                                  flexDirection: 'column',
                                  height: 20,
                                  justifyContent: 'center',
                                  width: 40,
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    { color: palettes.App.White }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {amountToUserText(cardItem?.amount_to_user)}
                              </Text>
                            </View>
                          )}
                        </>
                      </View>
                    )}
                  </>
                  {/* Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: '"rgb(255, 255, 255)"',
                        borderColor: palettes.App.ViewBG,
                        borderRadius: 12,
                        borderWidth: 1,
                        bottom: 10,
                        flexDirection: 'row',
                        height: 55,
                        justifyContent: 'center',
                        left: 5,
                        padding: 10,
                        position: 'relative',
                        width: 55,
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      {...GlobalStyles.ImageStyles(theme)['Image'].props}
                      resizeMode={'contain'}
                      source={{ uri: `${cardItem?.logo?.attachment?.url}` }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'].style,
                          {
                            borderColor: '"rgb(244, 244, 244)"',
                            borderRadius: 10,
                            height: 45,
                            width: 45,
                          }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                </ImageBackground>
              </View>
            )}
          </>
          {/* DetailsMode */}
          <>
            {!((props.mode ?? defaultProps.mode) === 'details') ? null : (
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, justifyContent: 'center', padding: 8 },
                  dimensions.width
                )}
              >
                {/* Row */}
                <View
                  style={StyleSheet.applyWidth(
                    { flexDirection: 'row' },
                    dimensions.width
                  )}
                >
                  {/* Title */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['CardDetailsLabel']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['CardDetailsLabel'].style,
                      dimensions.width
                    )}
                  >
                    {'Title:'}
                  </Text>

                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { flex: 0.75, fontSize: 12 }
                      ),
                      dimensions.width
                    )}
                  >
                    {cardItem?.title}
                    {' ('}
                    {cardItem?.id}
                    {')'}
                  </Text>
                </View>
                {/* Row 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    { flexDirection: 'row', marginTop: 6 },
                    dimensions.width
                  )}
                >
                  {/* Title */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['CardDetailsLabel']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['CardDetailsLabel'].style,
                      dimensions.width
                    )}
                  >
                    {'Description:'}
                  </Text>

                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    ellipsizeMode={'tail'}
                    numberOfLines={1}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { flex: 0.75, fontSize: 12 }
                      ),
                      dimensions.width
                    )}
                  >
                    {cardItem?.content}
                  </Text>
                </View>
                {/* Row 3 */}
                <View
                  style={StyleSheet.applyWidth(
                    { flexDirection: 'row', marginTop: 6 },
                    dimensions.width
                  )}
                >
                  {/* Title */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['CardDetailsLabel']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['CardDetailsLabel'].style,
                      dimensions.width
                    )}
                  >
                    {'Created At:'}
                  </Text>

                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    ellipsizeMode={'clip'}
                    numberOfLines={1}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { flex: 0.75, fontSize: 12 }
                      ),
                      dimensions.width
                    )}
                  >
                    {formatDate(cardItem?.created_at)}
                    {' ('}
                    {toRelativeDateString(cardItem?.created_at)}
                    {')'}
                  </Text>
                </View>
                {/* Row 4 */}
                <View
                  style={StyleSheet.applyWidth(
                    { flexDirection: 'row', marginTop: 6 },
                    dimensions.width
                  )}
                >
                  {/* Title */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['CardDetailsLabel']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['CardDetailsLabel'].style,
                      dimensions.width
                    )}
                  >
                    {'Business Cat.:'}
                  </Text>

                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    ellipsizeMode={'clip'}
                    numberOfLines={1}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { flex: 0.75, fontSize: 12 }
                      ),
                      dimensions.width
                    )}
                  >
                    {cardItem?._business_categories?.name}
                    {' ('}
                    {cardItem?._business_categories?.id}
                    {')'}
                  </Text>
                </View>
                {/* Row 5 */}
                <View
                  style={StyleSheet.applyWidth(
                    { flexDirection: 'row', marginTop: 6 },
                    dimensions.width
                  )}
                >
                  {/* Title */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['CardDetailsLabel']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['CardDetailsLabel'].style,
                      dimensions.width
                    )}
                  >
                    {'Type:'}
                  </Text>

                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    ellipsizeMode={'clip'}
                    numberOfLines={1}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { flex: 0.75, fontSize: 12 }
                      ),
                      dimensions.width
                    )}
                  >
                    {cardItem?.for_category
                      ? 'Category'
                      : cardItem?.for_explore
                      ? 'Explore'
                      : 'Inbox'}
                    {' Promotion'}
                  </Text>
                </View>
                {/* Row 9 */}
                <View
                  style={StyleSheet.applyWidth(
                    { flexDirection: 'row', marginTop: 6 },
                    dimensions.width
                  )}
                >
                  {/* Title */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['CardDetailsLabel']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['CardDetailsLabel'].style,
                      dimensions.width
                    )}
                  >
                    {'Budget:'}
                  </Text>

                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    ellipsizeMode={'clip'}
                    numberOfLines={1}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { flex: 0.25, fontSize: 12, textAlign: 'left' }
                      ),
                      dimensions.width
                    )}
                  >
                    {'$'}
                    {centToDollar(cardItem?.budget)}{' '}
                  </Text>
                  {/* Title */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['CardDetailsLabel']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['CardDetailsLabel'].style,
                      dimensions.width
                    )}
                  >
                    {'Remaining:'}
                  </Text>

                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    ellipsizeMode={'clip'}
                    numberOfLines={1}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { flex: 0.25, fontSize: 12, textAlign: 'left' }
                      ),
                      dimensions.width
                    )}
                  >
                    {'$'}
                    {centToDollar(cardItem?.remaining_budget)}
                  </Text>
                </View>
                {/* Row 10 */}
                <View
                  style={StyleSheet.applyWidth(
                    { flexDirection: 'row', marginTop: 6 },
                    dimensions.width
                  )}
                >
                  {/* Col */}
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', flex: 1, flexDirection: 'row' },
                      dimensions.width
                    )}
                  >
                    {/* ExpiredIcon */}
                    <>
                      {!isExpired() ? null : (
                        <Icon
                          size={24}
                          color={theme.colors.background.danger}
                          name={'Ionicons/stop'}
                        />
                      )}
                    </>
                    {/* ExpiredText */}
                    <>
                      {!isExpired() ? null : (
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              { fontSize: 12 }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Expired'}
                        </Text>
                      )}
                    </>
                    <>
                      {isExpired() ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', flexDirection: 'row' },
                            dimensions.width
                          )}
                        >
                          {/* PlayIcon */}
                          <>
                            {!cardItem?.is_active ? null : (
                              <Icon
                                size={24}
                                color={theme.colors.branding.primary}
                                name={'Ionicons/play'}
                              />
                            )}
                          </>
                          {/* ActiveText */}
                          <>
                            {!cardItem?.is_active ? null : (
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    { fontSize: 12 }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Active'}
                              </Text>
                            )}
                          </>
                          {/* PauseIcon */}
                          <>
                            {cardItem?.is_active ? null : (
                              <Icon
                                size={24}
                                color={palettes.App['Custom Color_13']}
                                name={'Ionicons/pause'}
                              />
                            )}
                          </>
                          {/* PausedText */}
                          <>
                            {cardItem?.is_active ? null : (
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    { fontSize: 12 }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Paused'}
                              </Text>
                            )}
                          </>
                        </View>
                      )}
                    </>
                  </View>
                  {/* Col */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flex: 1,
                        flexDirection: 'row',
                        justifyContent: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {/* WillExpire */}
                    <>
                      {isExpired() ? null : (
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              { fontSize: 12 }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Expires '}
                          {toRelativeDateString(cardItem?.expires_at)}
                        </Text>
                      )}
                    </>
                  </View>
                  {/* Col 3 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flex: 1,
                        flexDirection: 'row',
                        justifyContent: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {/* VideoIcon */}
                    <>
                      {!(cardItem?.asset_type === 'video') ? null : (
                        <Icon
                          size={24}
                          color={theme.colors.background.danger}
                          name={'Entypo/video'}
                          style={StyleSheet.applyWidth(
                            { marginRight: 8 },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    {/* ImageIcon */}
                    <>
                      {!(cardItem?.asset_type === 'image') ? null : (
                        <Icon
                          size={24}
                          color={palettes.App['Custom #f06454']}
                          name={'Entypo/image'}
                          style={StyleSheet.applyWidth(
                            { marginRight: 8 },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    {/* GlobalText */}
                    <>
                      {!(cardItem?.coverage_type === 'global') ? null : (
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              { fontSize: 12 }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Global'}
                        </Text>
                      )}
                    </>
                    {/* ZipText */}
                    <>
                      {!(cardItem?.coverage_type === 'zip') ? null : (
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              { fontSize: 12 }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Zip'}
                        </Text>
                      )}
                    </>
                    {/* GpsText */}
                    <>
                      {!(cardItem?.coverage_type === 'gps') ? null : (
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              { fontSize: 12 }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Gps'}
                        </Text>
                      )}
                    </>
                  </View>
                </View>
              </View>
            )}
          </>
        </Pressable>
        {/* FadeOutView */}
        <>
          {!(Constants['CARD_TO_SHOW_MENU'] === cardItem?.id) ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: '"rgba(0, 0, 0, 0.2)"',
                  height: '100%',
                  position: 'absolute',
                  width: '100%',
                },
                dimensions.width
              )}
            />
          )}
        </>
      </Surface>
      <>
        {!(Constants['CARD_TO_SHOW_MENU'] === cardItem?.id) ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                bottom: 0,
                height: '100%',
                left: 0,
                position: 'absolute',
                right: 0,
                top: 0,
                width: '100%',
                zIndex: 999,
              },
              dimensions.width
            )}
          >
            {/* DismissPressable */}
            <Pressable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'CARD_TO_SHOW_MENU',
                    value: 0,
                  });
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                { height: '100%', width: '100%' },
                dimensions.width
              )}
            />
            {/* Dropdown */}
            <Surface
              elevation={0}
              {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.SurfaceStyles(theme)['Surface'].style,
                  {
                    borderRadius: 4,
                    padding: 8,
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    width: 150,
                  }
                ),
                dimensions.width
              )}
            >
              {/* CloseButton */}
              <IconButton
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'CARD_TO_SHOW_MENU',
                      value: 0,
                    });
                  } catch (err) {
                    Sentry.captureException(err);
                    console.error(err);
                  }
                }}
                color={theme.colors.text.strong}
                icon={'AntDesign/close'}
                size={20}
                style={StyleSheet.applyWidth(
                  { position: 'absolute', right: 5, top: 5, zIndex: 555 },
                  dimensions.width
                )}
              />
              {/* EditButton */}
              <>
                {!(!isExpired() && !cardItem?.for_category) ? null : (
                  <Touchable
                    onPress={() => {
                      try {
                        trackAction(
                          Variables,
                          segment,
                          'Clicked Edit Button',
                          undefined
                        );
                        setGlobalVariableValue({
                          key: 'CARD_TO_SHOW_MENU',
                          value: 0,
                        });
                        setShowBudgetModal(true);
                      } catch (err) {
                        Sentry.captureException(err);
                        console.error(err);
                      }
                    }}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      <Icon name={'MaterialIcons/edit'} size={20} />
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { marginLeft: 8 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Edit Budget'}
                      </Text>
                    </View>
                  </Touchable>
                )}
              </>
              <>
                {!(!isExpired() && !cardItem?.for_category) ? null : (
                  <Divider
                    color={theme.colors.border.brand}
                    {...GlobalStyles.DividerStyles(theme)['Divider'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.DividerStyles(theme)['Divider'].style,
                        { marginBottom: 10, marginTop: 10 }
                      ),
                      dimensions.width
                    )}
                  />
                )}
              </>
              {/* ViewPromotion */}
              <>
                {!isExpired() ? null : (
                  <Touchable
                    onPress={() => {
                      const handler = async () => {
                        try {
                          trackAction(
                            Variables,
                            segment,
                            'Clicked View Button',
                            undefined
                          );
                          setGlobalVariableValue({
                            key: 'CARD_TO_SHOW_MENU',
                            value: 0,
                          });
                          setIsUpdating(true);
                          const recreateResult = (
                            await promotionsServiceReCreatePromotionPOST.mutateAsync(
                              { promotion_id: cardItem?.id }
                            )
                          )?.json;
                          setIsUpdating(false);
                          if (recreateResult?.message) {
                            setErrorMessage(recreateResult?.message);
                          }
                          if (recreateResult?.message) {
                            setShowErrorModal(true);
                          }
                        } catch (err) {
                          Sentry.captureException(err);
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      <Icon
                        name={'MaterialCommunityIcons/presentation'}
                        size={20}
                      />
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { marginLeft: 8 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'View'}
                      </Text>
                    </View>
                  </Touchable>
                )}
              </>
              {/* Re-Create */}
              <>
                {!isExpired() ? null : (
                  <Touchable
                    onPress={() => {
                      const handler = async () => {
                        try {
                          trackAction(
                            Variables,
                            segment,
                            'Clicked Re-Create Button',
                            undefined
                          );
                          setGlobalVariableValue({
                            key: 'CARD_TO_SHOW_MENU',
                            value: 0,
                          });
                          setIsUpdating(true);
                          const recreateResult = (
                            await promotionsServiceReCreatePromotionPOST.mutateAsync(
                              { promotion_id: cardItem?.id }
                            )
                          )?.json;
                          setIsUpdating(false);
                          if (recreateResult?.message) {
                            setErrorMessage(recreateResult?.message);
                          }
                          if (recreateResult?.message) {
                            setShowErrorModal(true);
                          }
                        } catch (err) {
                          Sentry.captureException(err);
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      <Icon name={'Ionicons/sync'} size={20} />
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { marginLeft: 8 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Re-Create'}
                      </Text>
                    </View>
                  </Touchable>
                )}
              </>
              {/* ToggleButton */}
              <>
                {isExpired() ? null : (
                  <Touchable
                    onPress={() => {
                      const handler = async () => {
                        try {
                          trackAction(
                            Variables,
                            segment,
                            'Clicked Pause Button',
                            undefined
                          );
                          setGlobalVariableValue({
                            key: 'CARD_TO_SHOW_MENU',
                            value: 0,
                          });
                          (
                            await promotionsServiceTogglePromotionPATCH.mutateAsync(
                              { promotion_id: cardItem?.id }
                            )
                          )?.json;
                          onChangeFunction();
                        } catch (err) {
                          Sentry.captureException(err);
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                  >
                    <>
                      {!cardItem?.is_active ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { flexDirection: 'row' },
                            dimensions.width
                          )}
                        >
                          <Icon name={'AntDesign/pausecircleo'} size={20} />
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                { marginLeft: 8 }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Pause'}
                          </Text>
                        </View>
                      )}
                    </>
                    {/* View 2 */}
                    <>
                      {cardItem?.is_active ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { flexDirection: 'row' },
                            dimensions.width
                          )}
                        >
                          <Icon name={'AntDesign/playcircleo'} size={20} />
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                { marginLeft: 8 }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Resume'}
                          </Text>
                        </View>
                      )}
                    </>
                  </Touchable>
                )}
              </>
              {/* DeactiveButton */}
              <>
                {isExpired() ? null : (
                  <Touchable
                    onPress={() => {
                      const handler = async () => {
                        try {
                          trackAction(
                            Variables,
                            segment,
                            'Clicked Stop Button',
                            undefined
                          );
                          setGlobalVariableValue({
                            key: 'CARD_TO_SHOW_MENU',
                            value: 0,
                          });
                          (
                            await promotionsServiceStopPromotionPATCH.mutateAsync(
                              { promotion_id: cardItem?.id }
                            )
                          )?.json;
                          onChangeFunction();
                        } catch (err) {
                          Sentry.captureException(err);
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      { marginTop: 10 },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      <Icon name={'AntDesign/closecircleo'} size={20} />
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { marginLeft: 8 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Stop'}
                      </Text>
                    </View>
                  </Touchable>
                )}
              </>
              {/* DeleteButton */}
              <>
                {isExpired() ? null : (
                  <Touchable
                    onPress={() => {
                      try {
                        trackAction(
                          Variables,
                          segment,
                          'Clicked Delete Button',
                          undefined
                        );
                        setGlobalVariableValue({
                          key: 'CARD_TO_SHOW_MENU',
                          value: 0,
                        });
                        setShowDeleteModal(true);
                      } catch (err) {
                        Sentry.captureException(err);
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      { marginTop: 10 },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      <Icon name={'AntDesign/delete'} size={20} />
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { marginLeft: 8 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Delete'}
                      </Text>
                    </View>
                  </Touchable>
                )}
              </>
            </Surface>
          </View>
        )}
      </>
      {/* DeleteModal */}
      <>
        {!(Constants['USER_DATA']?.id === cardItem?.creator_id) ? null : (
          <Modal
            animationType={'none'}
            supportedOrientations={['portrait', 'landscape']}
            transparent={true}
            visible={showDeleteModal}
          >
            {/* BGView */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: palettes.App.ViewBG,
                  flex: 1,
                  justifyContent: 'center',
                  padding: 20,
                },
                dimensions.width
              )}
            >
              {/* Container */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors.background.brand,
                    borderColor: palettes.App.CustomGray,
                    borderRadius: 16,
                    borderWidth: 1,
                    maxWidth: 400,
                    width: '90%',
                  },
                  dimensions.width
                )}
              >
                {/* Modal Header */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      borderBottomWidth: 1,
                      borderColor: palettes.App.CustomGray,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      padding: 16,
                      paddingBottom: 8,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { fontFamily: 'Poppins_500Medium' }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Are you sure?'}
                  </Text>
                  <IconButton
                    onPress={() => {
                      try {
                        setShowDeleteModal(false);
                      } catch (err) {
                        Sentry.captureException(err);
                        console.error(err);
                      }
                    }}
                    size={32}
                    color={theme.colors.text.strong}
                    icon={'Ionicons/close'}
                  />
                </View>
                {/* Modal Body */}
                <View
                  style={StyleSheet.applyWidth(
                    { padding: 16 },
                    dimensions.width
                  )}
                >
                  <>
                    {errorMessage ? null : (
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          dimensions.width
                        )}
                      >
                        {
                          'You are about to delete this promotion. You can not undo this action. Do you want to proceed? '
                        }
                      </Text>
                    )}
                  </>
                  {/* errorText */}
                  <>
                    {!errorMessage ? null : (
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { color: theme.colors.background.danger }
                          ),
                          dimensions.width
                        )}
                      >
                        {errorMessage}
                      </Text>
                    )}
                  </>
                </View>
                {/* Modal Footer */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'space-around',
                      padding: 16,
                    },
                    dimensions.width
                  )}
                >
                  {/* CancelView */}
                  <View
                    style={StyleSheet.applyWidth(
                      { flex: 1, marginRight: 4 },
                      dimensions.width
                    )}
                  >
                    <Button
                      iconPosition={'left'}
                      onPress={() => {
                        try {
                          setShowDeleteModal(false);
                        } catch (err) {
                          Sentry.captureException(err);
                          console.error(err);
                        }
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['OutlineButton']
                        .props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ButtonStyles(theme)['OutlineButton'].style,
                        dimensions.width
                      )}
                      title={'Cancel'}
                    />
                  </View>
                  {/* ConfirmView */}
                  <View
                    style={StyleSheet.applyWidth(
                      { flex: 1, marginLeft: 4 },
                      dimensions.width
                    )}
                  >
                    <Button
                      iconPosition={'left'}
                      onPress={() => {
                        const handler = async () => {
                          try {
                            setIsDeleting(true);
                            const deleteResult = (
                              await promotionsServiceDeletePromotionDELETE.mutateAsync(
                                { promotion_id: cardItem?.id }
                              )
                            )?.json;
                            setIsDeleting(false);
                            if (!deleteResult?.message) {
                              trackAction(
                                Variables,
                                segment,
                                'Promotion Deleted',
                                cardItem?.id
                              );
                            }
                            if (deleteResult?.message) {
                              setErrorMessage(deleteResult?.message);
                            }
                            if (!deleteResult?.message) {
                              setShowDeleteModal(false);
                            }
                            if (!deleteResult?.message) {
                              onChangeFunction();
                            }
                          } catch (err) {
                            Sentry.captureException(err);
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                      disabled={isDeleting}
                      loading={isDeleting}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'].style,
                          { backgroundColor: theme.colors.background.danger }
                        ),
                        dimensions.width
                      )}
                      title={'Yes, Delete!'}
                    />
                  </View>
                </View>
              </View>
            </View>
          </Modal>
        )}
      </>
      {/* ErrorMessageModal */}
      <>
        {!errorMessage ? null : (
          <Modal
            animationType={'none'}
            supportedOrientations={['portrait', 'landscape']}
            transparent={true}
            visible={showErrorModal}
          >
            {/* BGView */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: palettes.App.ViewBG,
                  flex: 1,
                  justifyContent: 'center',
                  padding: 20,
                },
                dimensions.width
              )}
            >
              {/* Container */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors.background.brand,
                    borderColor: palettes.App.CustomGray,
                    borderRadius: 16,
                    borderWidth: 1,
                    maxWidth: 400,
                    width: '90%',
                  },
                  dimensions.width
                )}
              >
                {/* Modal Header */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      borderBottomWidth: 1,
                      borderColor: palettes.App.CustomGray,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      padding: 16,
                      paddingBottom: 8,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { fontFamily: 'Poppins_500Medium' }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Error'}
                  </Text>
                  <IconButton
                    onPress={() => {
                      try {
                        setShowErrorModal(false);
                      } catch (err) {
                        Sentry.captureException(err);
                        console.error(err);
                      }
                    }}
                    size={32}
                    color={theme.colors.text.strong}
                    icon={'Ionicons/close'}
                  />
                </View>
                {/* Modal Body */}
                <View
                  style={StyleSheet.applyWidth(
                    { padding: 16 },
                    dimensions.width
                  )}
                >
                  {/* errorText */}
                  <>
                    {!errorMessage ? null : (
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { color: theme.colors.background.danger }
                          ),
                          dimensions.width
                        )}
                      >
                        {errorMessage}
                      </Text>
                    )}
                  </>
                </View>
                {/* Modal Footer */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'space-around',
                      padding: 16,
                    },
                    dimensions.width
                  )}
                >
                  {/* ConfirmView */}
                  <View
                    style={StyleSheet.applyWidth(
                      { flex: 1, marginLeft: 4 },
                      dimensions.width
                    )}
                  >
                    <Button
                      iconPosition={'left'}
                      onPress={() => {
                        try {
                          setShowErrorModal(false);
                        } catch (err) {
                          Sentry.captureException(err);
                          console.error(err);
                        }
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'].style,
                          { backgroundColor: theme.colors.background.danger }
                        ),
                        dimensions.width
                      )}
                      title={'Close'}
                    />
                  </View>
                </View>
              </View>
            </View>
          </Modal>
        )}
      </>
      {/* BudgetModal */}
      <>
        {!(Constants['USER_DATA']?.id === cardItem?.creator_id) ? null : (
          <Modal
            animationType={'none'}
            supportedOrientations={['portrait', 'landscape']}
            presentationStyle={'overFullScreen'}
            transparent={true}
            visible={showBudgetModal}
          >
            {/* BGView */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: palettes.App.ViewBG,
                  flex: 1,
                  justifyContent: 'center',
                  padding: 20,
                },
                dimensions.width
              )}
            >
              {/* Container */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors.background.brand,
                    borderColor: palettes.App.CustomGray,
                    borderRadius: 16,
                    borderWidth: 1,
                    maxWidth: 400,
                    width: '90%',
                  },
                  dimensions.width
                )}
              >
                {/* Modal Header */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      borderBottomWidth: 1,
                      borderColor: palettes.App.CustomGray,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      padding: 16,
                      paddingBottom: 8,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { fontFamily: 'Poppins_500Medium' }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Update Promotion Budget?'}
                  </Text>
                  <IconButton
                    onPress={() => {
                      try {
                        setShowBudgetModal(false);
                      } catch (err) {
                        Sentry.captureException(err);
                        console.error(err);
                      }
                    }}
                    size={32}
                    color={theme.colors.text.strong}
                    icon={'Ionicons/close'}
                  />
                </View>
                {/* Modal Body */}
                <View
                  style={StyleSheet.applyWidth(
                    { padding: 16 },
                    dimensions.width
                  )}
                >
                  <BalanceServiceApi.FetchGetBalanceGET>
                    {({ loading, error, data, refetchGetBalance }) => {
                      const fetchData = data?.json;
                      if (loading) {
                        return <ActivityIndicator />;
                      }

                      if (error || data?.status < 200 || data?.status >= 300) {
                        return <ActivityIndicator />;
                      }

                      return (
                        <>
                          <View
                            style={StyleSheet.applyWidth(
                              { flexDirection: 'row' },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                dimensions.width
                              )}
                            >
                              {'Balance              : $'}
                              {centToDollar(fetchData?.balance)}
                              {'\nCurrent Budget : $'}
                              {centToDollar(cardItem?.budget)}
                            </Text>
                            <Link
                              accessible={true}
                              onPress={() => {
                                try {
                                  trackAction(
                                    Variables,
                                    segment,
                                    'Clicked Add Balance Button',
                                    undefined
                                  );
                                  navigation.navigate('BalanceScreen');
                                  setShowBudgetModal(false);
                                } catch (err) {
                                  Sentry.captureException(err);
                                  console.error(err);
                                }
                              }}
                              {...GlobalStyles.LinkStyles(theme)['Link'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.LinkStyles(theme)['Link'].style,
                                  {
                                    fontFamily: 'Poppins_400Regular',
                                    marginLeft: 6,
                                  }
                                ),
                                dimensions.width
                              )}
                              title={'Add Balance'}
                            />
                          </View>
                          {/* BudgetView */}
                          <View
                            style={StyleSheet.applyWidth(
                              { flexDirection: 'column', width: '100%' },
                              dimensions.width
                            )}
                          >
                            {/* SliderView */}
                            <View
                              style={StyleSheet.applyWidth(
                                { flex: 1 },
                                dimensions.width
                              )}
                            >
                              {/* BudgetSlider */}
                              <Slider
                                onValueChange={newBudgetSliderValue => {
                                  try {
                                    setNewBudgetValue(newBudgetSliderValue);
                                  } catch (err) {
                                    Sentry.captureException(err);
                                    console.error(err);
                                  }
                                }}
                                {...GlobalStyles.SliderStyles(theme)['Slider']
                                  .props}
                                maximumValue={
                                  parseInt(fetchData?.balance, 10) ?? 5000
                                }
                                step={100}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.SliderStyles(theme)['Slider']
                                      .style,
                                    { marginLeft: 0, width: '100%' }
                                  ),
                                  dimensions.width
                                )}
                                value={newBudgetValue}
                              />
                            </View>
                            {/* InputView */}
                            <View
                              style={StyleSheet.applyWidth(
                                { flex: 0.2 },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  dimensions.width
                                )}
                              >
                                {'$'}
                                {centToDollar(newBudgetValue)}
                              </Text>
                            </View>
                          </View>
                        </>
                      );
                    }}
                  </BalanceServiceApi.FetchGetBalanceGET>
                </View>
                {/* errorText */}
                <>
                  {!errorMessage ? null : (
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: theme.colors.background.danger,
                            fontFamily: 'Poppins_400Regular',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {errorMessage}
                    </Text>
                  )}
                </>
                {/* Modal Footer */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'space-around',
                      padding: 16,
                    },
                    dimensions.width
                  )}
                >
                  {/* CancelView */}
                  <View
                    style={StyleSheet.applyWidth(
                      { flex: 1, marginRight: 4 },
                      dimensions.width
                    )}
                  >
                    <Button
                      iconPosition={'left'}
                      onPress={() => {
                        try {
                          setShowBudgetModal(false);
                        } catch (err) {
                          Sentry.captureException(err);
                          console.error(err);
                        }
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['OutlineButton']
                        .props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ButtonStyles(theme)['OutlineButton'].style,
                        dimensions.width
                      )}
                      title={'Cancel'}
                    />
                  </View>
                  {/* ConfirmView */}
                  <View
                    style={StyleSheet.applyWidth(
                      { flex: 1, marginLeft: 4 },
                      dimensions.width
                    )}
                  >
                    <Button
                      iconPosition={'left'}
                      onPress={() => {
                        const handler = async () => {
                          try {
                            setIsUpdating(true);
                            (
                              await promotionsServiceUpdatePromotionBudgetPATCH.mutateAsync(
                                {
                                  balance: parseInt(newBudgetValue, 10),
                                  promotion_id: cardItem?.id,
                                }
                              )
                            )?.json;
                            setIsUpdating(false);
                            trackAction(
                              Variables,
                              segment,
                              'Promotion Updated',
                              cardItem?.id
                            );
                            setShowBudgetModal(false);
                            onChangeFunction();
                          } catch (err) {
                            Sentry.captureException(err);
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                      disabled={newBudgetValue === 0}
                      loading={isUpdating}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'].style,
                          { backgroundColor: theme.colors.branding.primary }
                        ),
                        dimensions.width
                      )}
                      title={'Yes, Update!'}
                    />
                  </View>
                </View>
              </View>
            </View>
          </Modal>
        )}
      </>
    </View>
  );
};

export default withTheme(CardBlock);
