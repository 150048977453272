import React from 'react';
import { Button, Icon, Link, ScreenContainer, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import * as WebBrowser from 'expo-web-browser';
import { Platform, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import trackAction from '../global-functions/trackAction';
import trackScreen from '../global-functions/trackScreen';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const AuthDoNotCallScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const segment = useAnalytics();
  const [isLoading, setIsLoading] = React.useState(false);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (!Constants['AuthToken']) {
        navigation.navigate('AuthLoginScreen');
      }
      trackScreen(segment, 'Do Not Call');
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasSafeArea={true}
      style={StyleSheet.applyWidth(
        {
          alignItems: 'center',
          backgroundColor: {
            minWidth: Breakpoints.Desktop,
            value: palettes.App.CustomGray,
          },
          justifyContent: 'space-around',
          padding: 16,
        },
        dimensions.width
      )}
    >
      <View
        style={StyleSheet.applyWidth(
          {
            alignSelf: { minWidth: Breakpoints.Desktop, value: 'center' },
            backgroundColor: {
              minWidth: Breakpoints.Desktop,
              value: theme.colors.background.brand,
            },
            flex: 1,
            maxWidth: { minWidth: Breakpoints.Desktop, value: 1024 },
            width: [
              { minWidth: Breakpoints.Mobile, value: '80%' },
              { minWidth: Breakpoints.Desktop, value: '100%' },
            ],
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center', flex: 1, justifyContent: 'center' },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: palettes.App.CustomGray,
                borderRadius: 100,
                height: 128,
                justifyContent: 'center',
                marginBottom: 30,
                width: 128,
              },
              dimensions.width
            )}
          >
            <Icon
              color={palettes.App.customGreen}
              name={'MaterialCommunityIcons/satellite-uplink'}
              size={90}
            />
          </View>

          <Text
            accessible={true}
            style={StyleSheet.applyWidth(
              {
                fontFamily: 'Poppins_500Medium',
                fontSize: 24,
                lineHeight: 32,
                textAlign: 'center',
              },
              dimensions.width
            )}
          >
            {'Block Spam'}
          </Text>

          <Text
            accessible={true}
            style={StyleSheet.applyWidth(
              {
                color: theme.colors.text.medium,
                fontFamily: 'Poppins_200ExtraLight',
                fontSize: 16,
                lineHeight: 24,
                textAlign: 'center',
              },
              dimensions.width
            )}
          >
            {
              'You can register your phone number, email address, and mailing address on these opt-out lists using the links below'
            }
          </Text>
          {/* View 2 */}
          <View>
            {/* DoNotCall */}
            <Link
              accessible={true}
              onPress={() => {
                const handler = async () => {
                  try {
                    await WebBrowser.openBrowserAsync(
                      'https://www.donotcall.gov/register.html#step1'
                    );
                  } catch (err) {
                    Sentry.captureException(err);
                    console.error(err);
                  }
                };
                handler();
              }}
              {...GlobalStyles.LinkStyles(theme)['Link'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.LinkStyles(theme)['Link'].style,
                  {
                    fontFamily: 'Poppins_400Regular',
                    marginTop: 16,
                    textAlign: 'center',
                  }
                ),
                dimensions.width
              )}
              title={'Do Not Call Registry (for phone calls)'}
            />
            {/* DoNotCall 2 */}
            <Link
              accessible={true}
              onPress={() => {
                const handler = async () => {
                  try {
                    await WebBrowser.openBrowserAsync(
                      'https://dmachoice.org/register.php'
                    );
                  } catch (err) {
                    Sentry.captureException(err);
                    console.error(err);
                  }
                };
                handler();
              }}
              {...GlobalStyles.LinkStyles(theme)['Link'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.LinkStyles(theme)['Link'].style,
                  {
                    fontFamily: 'Poppins_400Regular',
                    marginTop: 16,
                    textAlign: 'center',
                  }
                ),
                dimensions.width
              )}
              title={'DMAchoice (for emails)'}
            />
            {/* DoNotCall 3 */}
            <Link
              accessible={true}
              onPress={() => {
                const handler = async () => {
                  try {
                    await WebBrowser.openBrowserAsync(
                      'https://www.directmail.com/mail_preference/'
                    );
                  } catch (err) {
                    Sentry.captureException(err);
                    console.error(err);
                  }
                };
                handler();
              }}
              {...GlobalStyles.LinkStyles(theme)['Link'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.LinkStyles(theme)['Link'].style,
                  {
                    fontFamily: 'Poppins_400Regular',
                    marginTop: 16,
                    textAlign: 'center',
                  }
                ),
                dimensions.width
              )}
              title={'DirectMail.com (for physical junk mail)'}
            />
          </View>
        </View>

        <View
          style={StyleSheet.applyWidth(
            {
              justifyContent: 'space-evenly',
              paddingBottom: { minWidth: Breakpoints.Desktop, value: 40 },
              paddingLeft: { minWidth: Breakpoints.Desktop, value: 20 },
              paddingRight: { minWidth: Breakpoints.Desktop, value: 20 },
            },
            dimensions.width
          )}
        >
          {/* Continue */}
          <Button
            iconPosition={'left'}
            onPress={() => {
              try {
                /* hidden 'If/Else' action */
                trackAction(
                  Variables,
                  segment,
                  'Clicked Continue Button',
                  undefined
                );
                if (Platform.OS === 'web') {
                  navigation.navigate('AddAddressScreen', {
                    isOnboarding: true,
                    isFirstAddress: true,
                  });
                } else {
                  navigation.navigate('AuthNotificationPermissionsScreen');
                }
              } catch (err) {
                Sentry.captureException(err);
                console.error(err);
              }
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            disabled={isLoading}
            loading={isLoading}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Button'].style,
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  marginTop: 16,
                }
              ),
              dimensions.width
            )}
            title={'Continue'}
          >
            {'Sign Up'}
          </Button>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(AuthDoNotCallScreen);
