import React from 'react';
import {
  Icon,
  Link,
  ScreenContainer,
  Surface,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import { Image, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import isLoggedIn from '../global-functions/isLoggedIn';
import trackAction from '../global-functions/trackAction';
import trackScreen from '../global-functions/trackScreen';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const AuthSelectSignupTypeScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const segment = useAnalytics();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      trackScreen(segment, 'Select Signup Type');
      if (!isLoggedIn(Variables)) {
        return;
      }
      if (Constants['USER_DATA']?.role === 'admin') {
        navigation.navigate('AdminDashboardScreen');
      } else {
        if (Constants['USER_DATA']?.role === 'business') {
          navigation.navigate('MyPromotionsScreen');
        } else {
          navigation.navigate('InboxScreen');
        }
      }
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer scrollable={false} hasSafeArea={true}>
      {/* Header */}
      <View
        {...GlobalStyles.ViewStyles(theme)['Header'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['Header'].style,
          dimensions.width
        )}
      >
        {/* AbsoluteTitle */}
        <View
          {...GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].style,
            dimensions.width
          )}
        >
          <Image
            {...GlobalStyles.ImageStyles(theme)['Image'].props}
            resizeMode={'contain'}
            source={imageSource(Images['MailboxxGreenLogo'])}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ImageStyles(theme)['Image'].style,
                { height: 30, width: 120 }
              ),
              dimensions.width
            )}
          />
        </View>
      </View>
      {/* Body */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignSelf: { minWidth: Breakpoints.Desktop, value: 'center' },
            flex: 1,
            justifyContent: [
              { minWidth: Breakpoints.Mobile, value: 'center' },
              { minWidth: Breakpoints.Desktop, value: 'center' },
            ],
            marginLeft: 20,
            marginRight: 20,
            maxWidth: 1024,
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center', justifyContent: 'center', padding: 16 },
            dimensions.width
          )}
        >
          {/* Title */}
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                fontSize: 18,
                textAlign: 'center',
              }),
              dimensions.width
            )}
          >
            {'Please select your profile type to continue '}
          </Text>
        </View>
        {/* PropmotionTypePickerView */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: { minWidth: Breakpoints.Tablet, value: 'center' },
              justifyContent: 'center',
              marginTop: 20,
            },
            dimensions.width
          )}
        >
          {/* Row */}
          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'row',
                justifyContent: 'center',
                maxHeight: 300,
                maxWidth: 500,
              },
              dimensions.width
            )}
          >
            {/* NormalUser */}
            <Surface
              {...GlobalStyles.SurfaceStyles(theme)['PromotionCard'].props}
              elevation={1}
              style={StyleSheet.applyWidth(
                GlobalStyles.SurfaceStyles(theme)['PromotionCard'].style,
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    trackAction(
                      Variables,
                      segment,
                      'Clicked User Profile Button',
                      undefined
                    );
                    navigation.navigate('AuthSignUpUserScreen');
                  } catch (err) {
                    Sentry.captureException(err);
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  { height: '100%', width: '100%' },
                  dimensions.width
                )}
              >
                {/* Container */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flex: 1,
                      justifyContent: 'center',
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: '"rgba(69, 77, 70, 0.66)"',
                        borderRadius: 10,
                        padding: 20,
                      },
                      dimensions.width
                    )}
                  >
                    <Icon name={'FontAwesome/user-circle-o'} size={60} />
                  </View>

                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: palettes.App.PrimaryDark,
                          fontFamily: 'Poppins_500Medium',
                          fontSize: 18,
                          marginBottom: 20,
                          marginTop: 20,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'User Profile'}
                  </Text>
                </View>
              </Touchable>
            </Surface>
            {/* Business */}
            <Surface
              {...GlobalStyles.SurfaceStyles(theme)['PromotionCard'].props}
              elevation={1}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.SurfaceStyles(theme)['PromotionCard'].style,
                  { backgroundColor: theme.colors.branding.secondary }
                ),
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    trackAction(
                      Variables,
                      segment,
                      'Clicked Business Profile Button',
                      undefined
                    );
                    navigation.navigate('AuthSignUpBusinessScreen');
                  } catch (err) {
                    Sentry.captureException(err);
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  { height: '100%', width: '100%' },
                  dimensions.width
                )}
              >
                {/* Container */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flex: 1,
                      justifyContent: 'center',
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App.SecondarySelected,
                        borderRadius: 10,
                        padding: 20,
                      },
                      dimensions.width
                    )}
                  >
                    <Icon
                      color={theme.colors.text.strong}
                      name={'Ionicons/business'}
                      size={60}
                    />
                  </View>

                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: palettes.App['Custom #002b4b'],
                          fontFamily: 'Poppins_500Medium',
                          fontSize: 18,
                          marginBottom: 20,
                          marginTop: 20,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Business Profile'}
                  </Text>
                </View>
              </Touchable>
            </Surface>
          </View>
        </View>
        {/* LogInButton */}
        <Link
          accessible={true}
          onPress={() => {
            try {
              navigation.navigate('AuthLoginScreen');
            } catch (err) {
              Sentry.captureException(err);
              console.error(err);
            }
          }}
          {...GlobalStyles.LinkStyles(theme)['Link'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'].style, {
              alignSelf: 'center',
              fontSize: 18,
              marginTop: 120,
              textAlign: 'center',
            }),
            dimensions.width
          )}
          title={'Log In'}
        />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(AuthSelectSignupTypeScreen);
