const trackScreen = (segment, screenName) => {
  try {
    segment.screen(screenName);
  } catch (err) {
    console.error(err);
  }
  try {
    segment.screen(screenName);
  } catch (err) {
    console.error(err);
  }
};

export default trackScreen;
