const toRelativeDateString = date => {
  const now = new Date();
  const diff = new Date(date).getTime() - now.getTime();
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 1) {
    return 'in ' + days + ' days';
  } else if (days === 1) {
    return 'tomorrow';
  } else if (hours > 1) {
    return 'in ' + hours + ' hours';
  } else if (hours === 1) {
    return 'in an hour';
  } else if (minutes > 1) {
    return 'in ' + minutes + ' minutes';
  } else if (minutes === 1) {
    return 'in a minute';
  } else if (seconds > 1) {
    return 'in ' + seconds + ' seconds';
  } else if (seconds === 1) {
    return 'in a second';
  } else if (seconds === 0) {
    return 'just now';
  } else if (seconds === -1) {
    return 'a second ago';
  } else if (seconds > -60) {
    return Math.abs(seconds) + ' seconds ago';
  } else if (minutes === -1) {
    return 'a minute ago';
  } else if (minutes > -60) {
    return Math.abs(minutes) + ' minutes ago';
  } else if (hours === -1) {
    return 'an hour ago';
  } else if (hours > -24) {
    return Math.abs(hours) + ' hours ago';
  } else if (days === -1) {
    return 'yesterday';
  } else {
    return Math.abs(days) + ' days ago';
  }
};

export default toRelativeDateString;
