import React from 'react';
import { Surface, Touchable, withTheme } from '@draftbit/ui';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import { Image, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import trackAction from '../global-functions/trackAction';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  imagePerClickPrice: 1.1,
  onSelect: () => {},
  title: null,
  videoPerClickPrice: 2.2,
};

const PromotionAssetTypePickerBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const segment = useAnalytics();
  const [selectedPromotionAssetType, setSelectedPromotionAssetType] =
    React.useState('');

  return (
    <View
      style={StyleSheet.applyWidth(
        { flex: 1, justifyContent: 'center' },
        dimensions.width
      )}
    >
      <>
        {!(props.title ?? defaultProps.title) ? null : (
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                marginBottom: 16,
                marginLeft: 20,
                marginRight: 20,
                textAlign: 'center',
              }),
              dimensions.width
            )}
          >
            {props.title ?? defaultProps.title}
          </Text>
        )}
      </>
      {/* Row */}
      <View
        style={StyleSheet.applyWidth(
          {
            flexDirection: 'row',
            height: 250,
            justifyContent: 'center',
            marginLeft: 20,
            marginRight: 20,
          },
          dimensions.width
        )}
      >
        {/* StaticImage */}
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['PromotionCard'].props}
          elevation={3}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.SurfaceStyles(theme)['PromotionCard'].style,
              {
                backgroundColor: palettes.App.White,
                borderColor: theme.colors.border.brand,
                borderWidth: 1,
              }
            ),
            dimensions.width
          )}
        >
          <Touchable
            onPress={() => {
              try {
                trackAction(
                  Variables,
                  segment,
                  'Clicked Image Promotion Button',
                  undefined
                );
                setSelectedPromotionAssetType('image');
                props.onSelect?.('image');
              } catch (err) {
                Sentry.captureException(err);
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              { height: '100%', width: '100%' },
              dimensions.width
            )}
          >
            {/* Container */}
            <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
              {/* ImageView */}
              <View
                style={StyleSheet.applyWidth({ flex: 3 }, dimensions.width)}
              >
                <Image
                  resizeMode={'cover'}
                  {...GlobalStyles.ImageStyles(theme)['Image'].props}
                  source={imageSource(Images['ImageAsset'])}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'].style,
                      { height: '100%', width: '100%' }
                    ),
                    dimensions.width
                  )}
                />
              </View>
              {/* TextViews */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, justifyContent: 'center', padding: 10 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        fontFamily: 'Poppins_500Medium',
                        fontSize: 18,
                        marginBottom: 20,
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Image'}
                </Text>
                {/* Description */}
                <>
                  {!(
                    props.imagePerClickPrice ?? defaultProps.imagePerClickPrice
                  ) ? null : (
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          { textAlign: 'center' }
                        ),
                        dimensions.width
                      )}
                    >
                      {'$'}
                      {props.imagePerClickPrice ??
                        defaultProps.imagePerClickPrice}
                      {'  per click'}
                    </Text>
                  )}
                </>
              </View>
            </View>
          </Touchable>
        </Surface>
        {/* VideoOrGIF */}
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['PromotionCard'].props}
          elevation={3}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.SurfaceStyles(theme)['PromotionCard'].style,
              {
                backgroundColor: palettes.App.White,
                borderColor: theme.colors.border.brand,
                borderWidth: 1,
              }
            ),
            dimensions.width
          )}
        >
          <Touchable
            onPress={() => {
              try {
                trackAction(
                  Variables,
                  segment,
                  'Clicked Video Promotion Button',
                  undefined
                );
                setSelectedPromotionAssetType('video');
                props.onSelect?.('video');
              } catch (err) {
                Sentry.captureException(err);
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              { height: '100%', width: '100%' },
              dimensions.width
            )}
          >
            {/* Container */}
            <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
              {/* ImageView */}
              <View
                style={StyleSheet.applyWidth({ flex: 3 }, dimensions.width)}
              >
                <Image
                  resizeMode={'cover'}
                  {...GlobalStyles.ImageStyles(theme)['Image'].props}
                  source={imageSource(Images['VideoAsset'])}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'].style,
                      { height: '100%', width: '100%' }
                    ),
                    dimensions.width
                  )}
                />
              </View>
              {/* TextViews */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, justifyContent: 'center', padding: 10 },
                  dimensions.width
                )}
              >
                {/* Title */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        fontFamily: 'Poppins_500Medium',
                        fontSize: 18,
                        marginBottom: 20,
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Video'}
                </Text>
                {/* Description */}
                <>
                  {!(
                    props.videoPerClickPrice ?? defaultProps.videoPerClickPrice
                  ) ? null : (
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          { textAlign: 'center' }
                        ),
                        dimensions.width
                      )}
                    >
                      {'$'}
                      {props.videoPerClickPrice ??
                        defaultProps.videoPerClickPrice}
                      {'  per click'}
                    </Text>
                  )}
                </>
              </View>
            </View>
          </Touchable>
        </Surface>
      </View>
    </View>
  );
};

export default withTheme(PromotionAssetTypePickerBlock);
