import palettes from '../themes/palettes';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Button } from '@draftbit/ui';
import { loadStripe } from '@stripe/stripe-js';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariableContext from '../config/GlobalVariableContext';
import * as XanoServiceApi from '../apis/XanoServiceApi.js';

export const ConnectBtn = ({
  navigation,
  theme,
  clientSecret,
  isOnboarding = false,
  setErrorMessage,
  onCancel,
}) => {
  const [stripe, setStripe] = React.useState(null);
  const xanoServiceAddBankAccountsPOST =
    XanoServiceApi.useAddBankAccountsPOST();

  const variables = GlobalVariableContext.useValues();
  const setGlobalVariable = GlobalVariableContext.useSetValue();

  React.useEffect(() => {
    const initializeStripe = async () => {
      const stripeInstance = await loadStripe(variables.STRIPE_PUBLISHABLE_KEY);
      setStripe(stripeInstance);
    };

    if (variables.STRIPE_PUBLISHABLE_KEY) initializeStripe();
  }, [variables.STRIPE_PUBLISHABLE_KEY]);

  const handleCollectPress = async () => {
    // console.log('clientSecret', clientSecret, variables.STRIPE_PUBLISHABLE_KEY)
    const { financialConnectionsSession, error } =
      await stripe.collectFinancialConnectionsAccounts({ clientSecret });

    // console.log(financialConnectionsSession, error)

    if (error) onCancel?.();

    if ((financialConnectionsSession.accounts || []).length === 0) {
      setErrorMessage('No accounts found');
      return;
    }

    const addBankAccountResults =
      await xanoServiceAddBankAccountsPOST.mutateAsync({
        accounts: financialConnectionsSession.accounts,
      });

    if (addBankAccountResults.message) {
      setErrorMessage(addBankAccountResults.message);
      return;
    }

    if (isOnboarding) navigation.navigate('AuthBankAccountsScreen');
  };

  return (
    <View>
      <Button
        onPress={handleCollectPress}
        style={StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
          alignItems: 'center',
          backgroundColor: theme.colors.branding.primary,
          flexDirection: 'row',
          fontFamily: 'System',
          fontWeight: '700',
          justifyContent: 'center',
          marginTop: 16,
        })}
        title={'CONNECT ACCOUNTS'}
      />
    </View>
  );
};
