import React, { useState, useEffect } from 'react';
import { View, TouchableWithoutFeedback } from 'react-native';
import * as Animatable from 'react-native-animatable';
import DrawerBlock from '../components/DrawerBlock';
import * as GlobalVariableContext from '../config/GlobalVariableContext';

export function DrawerView(props) {
  const variables = GlobalVariableContext.useValues();
  const setGlobalVariable = GlobalVariableContext.useSetValue();

  const hide = () => {
    setGlobalVariable({
      key: 'SHOW_DRAWER',
      value: false,
    });
  };

  const [shouldRender, setShouldRender] = useState(variables.SHOW_DRAWER);

  useEffect(() => {
    if (variables.SHOW_DRAWER) {
      setShouldRender(true);
    } else {
      setTimeout(() => {
        setShouldRender(false);
      }, 500); // set this to the duration of your animation
    }
  }, [variables.SHOW_DRAWER]);

  if (!shouldRender) return null;

  return (
    <>
      <View
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 998,
        }}
      >
        <TouchableWithoutFeedback onPress={hide}>
          <View style={{ flex: 1 }} />
        </TouchableWithoutFeedback>
      </View>

      <Animatable.View
        animation={variables.SHOW_DRAWER ? 'fadeInLeft' : 'fadeOutLeft'}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          zIndex: 999,
          backgroundColor: 'transparent',
        }}
      >
        <View style={{ flex: 1 }}>
          <DrawerBlock {...props} />
        </View>
      </Animatable.View>
    </>
  );
}
