import palettes from '../themes/palettes';
import React from 'react';
import { Text } from 'react-native';
import ComponentTransactionRowScreen from '../screens/ComponentTransactionRowScreen';
import ConfettiCannon from 'react-native-confetti-cannon';
import Tooltip from 'react-native-walkthrough-tooltip';
import calculateParagraphHeight from '../global-functions/calculateParagraphHeight';
import useWindowDimensions from '../utils/useWindowDimensions';

export function TransactionRow(props) {
  const route = {
    params: props,
  };
  return <ComponentTransactionRowScreen route={route} {...props} />;
}

export function Confetti() {
  return <ConfettiCannon fadeOut count={200} origin={{ x: -10, y: 0 }} />;
}

export function TooltipWrapper({ theme, children, tooltipText }) {
  const dimensions = useWindowDimensions();
  if (!tooltipText) return children;
  return (
    <Tooltip
      height={calculateParagraphHeight(dimensions.width * 1.2, tooltipText)}
      width={'80%'}
      placement="bottom"
      // backgroundColor={theme.colors.branding.primary}
      content={<Text>{`${tooltipText}`}</Text>}
    >
      {children}
    </Tooltip>
  );
}
