import React, { useRef, useState } from 'react';
import { Dimensions, FlatList, View } from 'react-native';
import ReelCard from './ReelCard';
import ReelsPlayerBottomOverlayBlock from '../components/ReelsPlayerBottomOverlayBlock';
import ReelsPlayerActionButtonsOverlayBlock from '../components/ReelsPlayerActionButtonsOverlayBlock';

const screenHeight = Dimensions.get('window').height;
const screenWidth = Dimensions.get('window').width;

function ReelsPlayer(props) {
  const { items, onPageChange, onEndReached, ...otherProps } = props;
  const FlatlistRef = useRef(null);
  const [viewableItem, setViewableItem] = useState('');
  const [toggleContent, setToggleContent] = useState(false);
  const viewConfigRef = useRef({ viewAreaCoveragePercentThreshold: 70 });

  // Viewable configuration
  const onViewRef = useRef(viewableItems => {
    if (viewableItems?.viewableItems?.length > 0) {
      setViewableItem(
        {
          id: viewableItems.viewableItems[0].item.id,
          _is_consumed_by_user:
            viewableItems.viewableItems[0].item?._is_consumed_by_user,
        } || 0
      );
    }
  });

  React.useEffect(() => {
    if (onPageChange) {
      onPageChange(viewableItem);
      setToggleContent(false);
    }
  }, [viewableItem]);

  return (
    <FlatList
      ref={FlatlistRef}
      data={items}
      keyExtractor={item => item.id.toString()}
      renderItem={({ item, index }) => {
        return (
          <View style={{ flex: 1, height: screenHeight, width: screenWidth }}>
            {!toggleContent ? null : (
              <View
                style={{
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  height: '100%',
                  left: 0,
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  width: '100%',
                  zIndex: 1,
                }}
              />
            )}

            <ReelCard
              currentPromotion={item}
              index={index}
              viewableItem={viewableItem}
              onFinishPlaying={index => {
                console.log('video finished playing');
              }}
              {...otherProps}
            />

            <ReelsPlayerBottomOverlayBlock
              promotion={item}
              onToggle={() => setToggleContent(prev => !prev)}
            />

            <ReelsPlayerActionButtonsOverlayBlock promotion={item} />
          </View>
        );
      }}
      getItemLayout={(_data, index) => {
        return {
          length: screenHeight,
          offset: screenHeight * index,
          index,
        };
      }}
      pagingEnabled
      decelerationRate={0.9}
      onViewableItemsChanged={onViewRef.current}
      onEndReached={onEndReached}
      onEndReachedThreshold={2}
      viewabilityConfig={viewConfigRef.current}
      showsVerticalScrollIndicator={false}
    />
  );
}

export default ReelsPlayer;

// <View
//       style={{
//         position: 'absolute',
//         bottom: 0,
//         width: '100%',
//         zIndex: 999,
//       }}
//     >

//     </View>
