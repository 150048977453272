import { LineChart } from 'react-native-chart-kit';
import { Dimensions } from 'react-native';

export const Line = ({ theme, labels, datasets }) => {
  if (datasets.length === 0) return;

  return (
    <LineChart
      data={{
        labels,
        datasets,
      }}
      width={Dimensions.get('window').width}
      height={320}
      yAxisInterval={1} // optional, defaults to 1
      chartConfig={{
        backgroundColor: '#fff',
        backgroundGradientFrom: '#fff',
        backgroundGradientTo: '#fff',
        decimalPlaces: 2, // optional, defaults to 2dp
        color: (opacity = 1) => `rgba(76, 217, 100, ${opacity})`,
        labelColor: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
        propsForLabels: {
          fontFamily: 'Poppins_400Regular',
        },
        propsForVerticalLabels: {
          rotation: 300,
        },
      }}
      bezier
    />
  );
};
