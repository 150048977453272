import { showMessage } from 'react-native-flash-message';

export const myMessage = ({ message }) => {
  showMessage({
    message: message,
    type: 'success',
    duration: 3000,
    color: '#434343',
    backgroundColor: '#C4FDDB',
    floating: true,
  });
};
