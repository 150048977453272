import React from 'react';
import {
  Divider,
  Icon,
  LinearGradient,
  Pressable,
  ScreenContainer,
  SimpleStyleScrollView,
  Surface,
  Swiper,
  SwiperItem,
  VideoPlayer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import { Image, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import WideHeaderBlock from '../components/WideHeaderBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as ScreenComponents from '../custom-files/ScreenComponents';
import amountToUserText from '../global-functions/amountToUserText';
import isLoggedIn from '../global-functions/isLoggedIn';
import trackScreen from '../global-functions/trackScreen';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  content:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ullamcorper nisi at orci rutrum, vulputate viverra purus ullamcorper. Vivamus sed fermentum ex, non varius diam. Phasellus vel auctor ex. Quisque aliquet euismod velit, vel placerat nisl ultrices posuere. Phasellus porta non purus id auctor. Praesent nec dolor varius, blandit ipsum a, suscipit sem. Duis vehicula nisl id lorem luctus, non sollicitudin sapien vulputate. Aliquam interdum euismod odio id mollis. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Aliquam ornare diam at quam pulvinar placerat. Curabitur tempor in nulla in bibendum. Aliquam porta hendrerit nibh, et pulvinar erat ullamcorper sed.  Vivamus ornare at orci vitae mattis. Vivamus varius lectus id maximus iaculis. Cras fringilla augue leo, a gravida massa vehicula eu. Suspendisse finibus, dui sit amet commodo gravida, sem justo sollicitudin nisi, at rutrum velit sem id magna. Nullam a bibendum purus. Proin efficitur est vel libero gravida gravida euismod quis dolor. Aenean magna nunc, placerat non arcu vitae, accumsan convallis ligula.  Etiam pellentesque ipsum ligula, at pellentesque tortor pulvinar a. Donec sagittis ipsum felis, quis vehicula neque sollicitudin et. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Phasellus non ullamcorper metus. Nulla tincidunt leo non efficitur venenatis. Nunc luctus et nunc quis rhoncus. Nunc dictum sem a neque aliquam pellentesque. In laoreet blandit risus, et condimentum nisl pulvinar at. Suspendisse sit amet elementum nunc, nec dignissim neque. Curabitur eget mi sed orci tincidunt dictum. Quisque posuere nisi velit, pulvinar auctor nisl iaculis vel. Aliquam consequat neque et velit eleifend imperdiet. Quisque blandit elit metus, at feugiat turpis scelerisque efficitur. Quisque iaculis eu tellus a vulputate.',
  details_images: [
    {
      id: 1,
      user_id: 4,
      adobe_urn: '',
      attachment: {
        url: 'https://xhrm-5alx-lhb1.n7.xano.io/vault/cH9onT_o/K7SLwPbWCHrWhG6C4m52tq2MObk/Qec51w../file-6debb5.mp4',
        meta: {
          video: {
            dar: false,
            fps: 30,
            par: false,
            res: '1080x1920',
            codec: 'h264 (High) (avc1 / 0x31637661)',
            bitrate: 406000,
            profile: 'yuv420p(tv',
            postWidth: 1080,
            postHeight: 1920,
          },
          duration: 3.87,
        },
        mime: 'video/mp4',
        name: 'file-6debb5.mp4',
        path: '/vault/cH9onT_o/K7SLwPbWCHrWhG6C4m52tq2MObk/Qec51w../file-6debb5.mp4',
        size: 201827,
        type: 'video',
        access: 'public',
      },
      created_at: 1715871025251,
    },
  ],
  logo: 'https://xhrm-5alx-lhb1.n7.xano.io/vault/cH9onT_o/KlpEMurwK6mv1ckNgEwr-ukZuc4/Zp1s7A../file-f44781.jpeg',
  promoDiscount: 50,
  subTitle: 'New Business',
  title: 'Title of Promo',
};

const PreviewPromotionScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const segment = useAnalytics();
  const [isSaving, setIsSaving] = React.useState(false);
  const [promotionId, setPromotionId] = React.useState(0);
  const [refresh, setRefresh] = React.useState(false);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (!isLoggedIn(Variables)) {
        navigation.navigate('AuthLoginScreen');
      }
      if (Constants['USER_DATA']?.role === 'user') {
        navigation.navigate('AuthLoginScreen');
      }
      trackScreen(segment, 'Preview Promotion');
      setPromotionId(null);
      console.log(
        props.route?.params?.details_images ?? defaultProps.details_images
      );
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasSafeArea={true}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: {
            minWidth: Breakpoints.Desktop,
            value: palettes.App.CustomGray,
          },
        },
        dimensions.width
      )}
    >
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <WideHeaderBlock withGoBack={true} />
        )}
      </>
      {/* Header */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            {...GlobalStyles.ViewStyles(theme)['Header'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['Header'].style,
              dimensions.width
            )}
          >
            {/* AbsoluteTitle 2 */}
            <View
              {...GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].style,
                dimensions.width
              )}
            >
              <Image
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                resizeMode={'contain'}
                source={imageSource(Images['MailboxxGreenLogo'])}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { height: 30, width: 120 }
                  ),
                  dimensions.width
                )}
              />
            </View>
            {/* BackButton */}
            <Pressable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              {...GlobalStyles.PressableStyles(theme)['BackButton'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.PressableStyles(theme)['BackButton'].style,
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={theme.colors.branding.primary}
                  name={'Ionicons/chevron-back'}
                  size={28}
                />
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    dimensions.width
                  )}
                >
                  {'Back'}
                </Text>
              </View>
            </Pressable>
          </View>
        )}
      </>
      {/* View 2 */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignSelf: { minWidth: Breakpoints.Desktop, value: 'center' },
            backgroundColor: {
              minWidth: Breakpoints.Desktop,
              value: theme.colors.background.brand,
            },
            flex: 1,
            maxWidth: { minWidth: Breakpoints.Desktop, value: 1024 },
            paddingBottom: 40,
            paddingTop: { minWidth: Breakpoints.Desktop, value: 12 },
            width: { minWidth: Breakpoints.Desktop, value: '100%' },
          },
          dimensions.width
        )}
      >
        {/* Header */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingBottom: 4,
              paddingLeft: 8,
              paddingRight: 8,
              paddingTop: 4,
            },
            dimensions.width
          )}
        >
          {/* Detail View */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flex: 1,
                flexDirection: 'row',
                paddingRight: 10,
              },
              dimensions.width
            )}
          >
            {/* Logo */}
            <Image
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              resizeMode={'contain'}
              source={{
                uri: `${props.route?.params?.logo ?? defaultProps.logo}`,
              }}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  { height: 50, marginLeft: 10, marginRight: 10, width: 50 }
                ),
                dimensions.width
              )}
            />
            <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { fontSize: 18 }
                  ),
                  dimensions.width
                )}
              >
                {props.route?.params?.title ?? defaultProps.title}
              </Text>
              {/* Text 2 */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { color: theme.colors.text.light }
                  ),
                  dimensions.width
                )}
              >
                {props.route?.params?.subTitle ?? defaultProps.subTitle}
              </Text>
            </View>
          </View>
          {/* PriceView */}
          <View style={StyleSheet.applyWidth({ width: 70 }, dimensions.width)}>
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  { fontSize: 19, textAlign: 'center' }
                ),
                dimensions.width
              )}
            >
              {amountToUserText(
                props.route?.params?.promoDiscount ?? defaultProps.promoDiscount
              )}
            </Text>
          </View>
        </View>
        <Divider
          {...GlobalStyles.DividerStyles(theme)['Divider'].props}
          color={theme.colors.border.brand}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.DividerStyles(theme)['Divider'].style,
              { height: 1.2, marginBottom: 20, marginTop: 10 }
            ),
            dimensions.width
          )}
        />
        <Swiper
          data={
            props.route?.params?.details_images ?? defaultProps.details_images
          }
          dotActiveColor={theme.colors.branding.primary}
          dotColor={theme.colors.text.light}
          dotsTouchable={true}
          keyExtractor={(swiperData, index) =>
            swiperData?.id ??
            swiperData?.uuid ??
            index?.toString() ??
            JSON.stringify(swiperData)
          }
          listKey={'On7kQZ0D'}
          loop={false}
          minDistanceForAction={0.2}
          minDistanceToCapture={5}
          renderItem={({ item, index }) => {
            const swiperData = item;
            return (
              <SwiperItem>
                <Surface
                  elevation={0}
                  {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.SurfaceStyles(theme)['Surface'].style,
                      {
                        alignItems: 'center',
                        backgroundColor: theme.colors.background.brand,
                        borderRadius: 16,
                        flex: 1,
                        justifyContent: 'center',
                        marginLeft: 20,
                        marginRight: 20,
                        overflow: 'hidden',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  <>
                    {!(swiperData?.attachment?.type === 'image') ? null : (
                      <Image
                        {...GlobalStyles.ImageStyles(theme)['Image'].props}
                        resizeMode={'contain'}
                        source={{ uri: `${swiperData?.attachment?.url}` }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ImageStyles(theme)['Image'].style,
                            { height: '100%', width: '100%' }
                          ),
                          dimensions.width
                        )}
                      />
                    )}
                  </>
                  <>
                    {!(swiperData?.attachment?.type === 'video') ? null : (
                      <VideoPlayer
                        isMuted={false}
                        posterResizeMode={'cover'}
                        rate={1}
                        resizeMode={'cover'}
                        usePoster={false}
                        volume={0.5}
                        {...GlobalStyles.VideoPlayerStyles(theme)['Video']
                          .props}
                        isLooping={true}
                        shouldPlay={true}
                        source={{ uri: `${swiperData?.attachment?.url}` }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.VideoPlayerStyles(theme)['Video']
                              .style,
                            { height: '100%', width: '100%' }
                          ),
                          dimensions.width
                        )}
                        useNativeControls={false}
                      />
                    )}
                  </>
                  <LinearGradient
                    startY={0}
                    {...GlobalStyles.LinearGradientStyles(theme)[
                      'Linear Gradient'
                    ].props}
                    color1={'rgba(0, 0, 0, 0)'}
                    color2={palettes.App.Black}
                    endX={50}
                    endY={50}
                    startX={50}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.LinearGradientStyles(theme)[
                          'Linear Gradient'
                        ].style,
                        {
                          bottom: 0,
                          height: '40%',
                          opacity: 1,
                          paddingTop: 65,
                          position: 'absolute',
                          width: '100%',
                          zIndex: 0,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    <SimpleStyleScrollView
                      bounces={true}
                      horizontal={false}
                      keyboardShouldPersistTaps={'never'}
                      nestedScrollEnabled={false}
                      showsHorizontalScrollIndicator={true}
                      showsVerticalScrollIndicator={true}
                      style={StyleSheet.applyWidth(
                        { bottom: 0, justifyContent: 'flex-end', padding: 16 },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              color: theme.colors.background.brand,
                              fontSize: 18,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {props.route?.params?.content ?? defaultProps.content}
                      </Text>
                    </SimpleStyleScrollView>
                  </LinearGradient>
                </Surface>
              </SwiperItem>
            );
          }}
          timeout={0}
          vertical={false}
          {...GlobalStyles.SwiperStyles(theme)['Swiper'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.SwiperStyles(theme)['Swiper'].style,
              { flex: 1, height: null, minHeight: 300 }
            ),
            dimensions.width
          )}
        />
        {/* ActionsMenu */}
        <>
          {null === true ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors.background.brand,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginLeft: 20,
                  marginRight: 20,
                  marginTop: 20,
                  minHeight: 45,
                },
                dimensions.width
              )}
            >
              {/* ViewMorePressable */}
              <Pressable>
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: theme.colors.branding.primary,
                      borderRadius: 6,
                      flex: 1,
                      flexDirection: 'row',
                      height: 45,
                      justifyContent: 'space-between',
                      paddingBottom: 6,
                      paddingLeft: 12,
                      paddingRight: 12,
                      paddingTop: 6,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: palettes.App.White,
                          fontFamily: 'System',
                          fontSize: 15,
                          fontWeight: '600',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'View More'}
                  </Text>
                  <Icon
                    color={palettes.App.White}
                    name={'Ionicons/add-sharp'}
                    size={28}
                  />
                </View>
              </Pressable>
              {/* SharePressable */}
              <Pressable>
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: theme.colors.branding.primary,
                      borderRadius: 6,
                      flex: 1,
                      flexDirection: 'row',
                      height: 45,
                      justifyContent: 'space-between',
                      paddingBottom: 6,
                      paddingLeft: 12,
                      paddingRight: 12,
                      paddingTop: 6,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: palettes.App.White,
                          fontFamily: 'System',
                          fontSize: 15,
                          fontWeight: '600',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Share'}
                  </Text>
                  <Icon
                    color={palettes.App.White}
                    name={'Entypo/share'}
                    size={28}
                    style={StyleSheet.applyWidth(
                      { marginLeft: 15, marginRight: -5 },
                      dimensions.width
                    )}
                  />
                </View>
              </Pressable>
              {/* SavePressable */}
              <Pressable>
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: '"rgba(0, 0, 0, 0)"',
                      borderColor: theme.colors.branding.primary,
                      borderRadius: 6,
                      borderWidth: 1,
                      flex: 1,
                      height: 45,
                      justifyContent: 'center',
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        paddingBottom: 6,
                        paddingLeft: 12,
                        paddingRight: 12,
                        paddingTop: 6,
                      },
                      dimensions.width
                    )}
                  >
                    {/* GreenText */}
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            fontFamily: 'System',
                            fontSize: 15,
                            fontWeight: '600',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Save'}
                    </Text>

                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          height: 40,
                          justifyContent: 'center',
                          width: 40,
                        },
                        dimensions.width
                      )}
                    >
                      {/* icon-empty */}
                      <Icon
                        color={theme.colors.branding.primary}
                        name={'FontAwesome/bookmark-o'}
                        size={26}
                      />
                    </View>
                  </View>
                </View>
              </Pressable>
            </View>
          )}
        </>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(PreviewPromotionScreen);
