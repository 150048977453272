import React from 'react';
import {
  Button,
  Icon,
  Link,
  Pressable,
  ScreenContainer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import { Image, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoServiceApi from '../apis/XanoServiceApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import trackAction from '../global-functions/trackAction';
import trackScreen from '../global-functions/trackScreen';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const AuthForgetPasswordUpdateScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [password1, setPassword1] = React.useState('');
  const [password2, setPassword2] = React.useState('');
  const validForm = () => {
    if (password1 === '') {
      setErrorMessage('Please enter your new password');
      return false;
    }

    if (password2 === '') {
      setErrorMessage('Please confirm your new password');
      return false;
    }

    if (password1 !== password2) {
      setErrorMessage('Passwords do not match');
      return false;
    }

    if (!password1.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d\W_]{8,}$/)) {
      setErrorMessage(
        'Password must be at least 8 characters long containing at least 1 number, 1 lowercase letter and 1 uppercase letter'
      );
      return false;
    }

    setErrorMessage('');
    return true;
  };
  const xanoServiceForgotPasswordUpdatePOST =
    XanoServiceApi.useForgotPasswordUpdatePOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (!Constants['AuthToken']) {
        navigation.navigate('AuthForgetPasswordScreen');
      }
      trackScreen(segment, 'Forgot Password Update');
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasSafeArea={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App.White },
        dimensions.width
      )}
    >
      {/* Header */}
      <View
        {...GlobalStyles.ViewStyles(theme)['Header'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['Header'].style,
          dimensions.width
        )}
      >
        {/* AbsoluteTitle */}
        <View
          {...GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].style,
            dimensions.width
          )}
        >
          <Image
            {...GlobalStyles.ImageStyles(theme)['Image'].props}
            resizeMode={'contain'}
            source={imageSource(Images['MailboxxGreenLogo'])}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ImageStyles(theme)['Image'].style,
                { height: 30, width: 120 }
              ),
              dimensions.width
            )}
          />
        </View>
        {/* BackButton */}
        <Pressable
          onPress={() => {
            try {
              navigation.goBack();
            } catch (err) {
              Sentry.captureException(err);
              console.error(err);
            }
          }}
          {...GlobalStyles.PressableStyles(theme)['BackButton'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.PressableStyles(theme)['BackButton'].style,
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
              },
              dimensions.width
            )}
          >
            <Icon
              color={palettes.App['Custom Color_9']}
              name={'Ionicons/chevron-back'}
              size={28}
            />
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['HeaderTitleText'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['HeaderTitleText'].style,
                  { color: palettes.App.customGreen }
                ),
                dimensions.width
              )}
            >
              {'Back'}
            </Text>
          </View>
        </Pressable>
      </View>
      {/* Main */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignSelf: { minWidth: Breakpoints.Desktop, value: 'center' },
            flex: { minWidth: Breakpoints.Desktop, value: 1 },
            justifyContent: { minWidth: Breakpoints.Desktop, value: 'center' },
            marginLeft: 20,
            marginRight: 20,
            marginTop: 30,
            maxWidth: 1024,
          },
          dimensions.width
        )}
      >
        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              fontSize: 32,
              textAlign: 'center',
            }),
            dimensions.width
          )}
        >
          {'Forgot Password'}
        </Text>

        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              fontSize: 18,
              marginTop: 10,
              textAlign: 'center',
            }),
            dimensions.width
          )}
        >
          {'Please enter your new password'}
        </Text>
        {/* NewPassword */}
        <TextInput
          autoCapitalize={'none'}
          autoCorrect={true}
          changeTextDelay={500}
          onChangeText={newNewPasswordValue => {
            try {
              setPassword1(newNewPasswordValue);
            } catch (err) {
              Sentry.captureException(err);
              console.error(err);
            }
          }}
          webShowOutline={true}
          {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
          placeholder={'Password'}
          secureTextEntry={true}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.TextInputStyles(theme)['Text Input'].style,
              { marginTop: 40 }
            ),
            dimensions.width
          )}
          value={password1}
        />
        {/* ConfirmPassword */}
        <TextInput
          autoCapitalize={'none'}
          autoCorrect={true}
          changeTextDelay={500}
          onChangeText={newConfirmPasswordValue => {
            try {
              setPassword2(newConfirmPasswordValue);
            } catch (err) {
              Sentry.captureException(err);
              console.error(err);
            }
          }}
          webShowOutline={true}
          {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
          placeholder={'Confirm Password'}
          secureTextEntry={true}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.TextInputStyles(theme)['Text Input'].style,
              { marginTop: 20 }
            ),
            dimensions.width
          )}
          value={password2}
        />
        {/* ChangePassword */}
        <Button
          iconPosition={'left'}
          onPress={() => {
            const handler = async () => {
              try {
                if (!validForm()) {
                  return;
                }
                setIsLoading(true);
                const result = (
                  await xanoServiceForgotPasswordUpdatePOST.mutateAsync({
                    password: password1,
                  })
                )?.json;
                setIsLoading(false);
                if (result?.message) {
                  setErrorMessage(result?.message);
                } else {
                  setGlobalVariableValue({
                    key: 'AuthToken',
                    value: '',
                  });
                  trackAction(
                    Variables,
                    segment,
                    'Changed password',
                    undefined
                  );
                  navigation.navigate('AuthLoginScreen');
                }
              } catch (err) {
                Sentry.captureException(err);
                console.error(err);
              }
            };
            handler();
          }}
          {...GlobalStyles.ButtonStyles(theme)['Button'].props}
          disabled={isLoading}
          loading={isLoading}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.ButtonStyles(theme)['Button'].style,
              { marginTop: 20 }
            ),
            dimensions.width
          )}
          title={'Change Password'}
        />
        {/* errorView */}
        <>
          {!errorMessage ? null : (
            <View
              {...GlobalStyles.ViewStyles(theme)['errorView 2'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.ViewStyles(theme)['errorView 2'].style,
                dimensions.width
              )}
            >
              {/* errorText */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: theme.colors.background.danger,
                      textAlign: 'center',
                    }
                  ),
                  dimensions.width
                )}
              >
                {errorMessage}
              </Text>
            </View>
          )}
        </>
        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center', marginBottom: 30, marginTop: 20 },
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                color: theme.colors.text.strong,
                textAlign: 'center',
              }),
              dimensions.width
            )}
          >
            {'By signing up, you agree to our '}
            <Link
              accessible={true}
              onPress={() => {
                try {
                  navigation.navigate('TermsandConditionsScreen');
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              {...GlobalStyles.LinkStyles(theme)['Link'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.LinkStyles(theme)['Link'].style,
                dimensions.width
              )}
              title={'Terms & Conditions'}
            />
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  { color: theme.colors.text.strong }
                ),
                dimensions.width
              )}
            >
              {' and '}
              {/* Link 2 */}
              <Link
                accessible={true}
                onPress={() => {
                  try {
                    navigation.navigate('PrivacyPolicyScreen');
                  } catch (err) {
                    Sentry.captureException(err);
                    console.error(err);
                  }
                }}
                {...GlobalStyles.LinkStyles(theme)['Link'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.LinkStyles(theme)['Link'].style,
                  dimensions.width
                )}
                title={'Privacy Policy'}
              />
            </Text>
          </Text>
        </View>
        {/* GoToSignInButton */}
        <Link
          accessible={true}
          onPress={() => {
            try {
              navigation.navigate('AuthLoginScreen');
            } catch (err) {
              Sentry.captureException(err);
              console.error(err);
            }
          }}
          {...GlobalStyles.LinkStyles(theme)['Link'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'].style, {
              alignSelf: 'center',
              fontSize: 16,
              marginTop: '70%',
            }),
            dimensions.width
          )}
          title={'Sign In'}
        />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(AuthForgetPasswordUpdateScreen);
