import React from 'react';
import {
  Circle,
  Divider,
  Icon,
  Link,
  Pressable,
  ScreenContainer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import { FlatList, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import trackScreen from '../global-functions/trackScreen';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { item: '' };

const ComponentTransactionRowScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const segment = useAnalytics();
  const [showDetails, setShowDetails] = React.useState(false);
  const convertTransactionAmount = row => {
    if (row?.type === 'credit' || row?.type === 'refund') {
      return `$${(row?.amount / 100).toFixed(2)}`;
    } else return `- $${(row?.amount / 100).toFixed(2)}`;
  };

  const toDateString = timestamp => {
    return new Date(timestamp).toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      trackScreen(segment, 'Transaction Row');
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      <Pressable
        onPress={() => {
          try {
            setShowDetails(!showDetails);
            if (
              (props.route?.params?.item ?? defaultProps.item)
                ?.related_transactions?.length > 0
            ) {
              navigation.navigate('ViewPromotionScreen', {
                PromotionId: (props.route?.params?.item ?? defaultProps.item)
                  ?.promotion_id,
              });
            }
          } catch (err) {
            Sentry.captureException(err);
            console.error(err);
          }
        }}
      >
        {/* TransactionView */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: 8,
              paddingBottom: 8,
              paddingRight: 12,
              paddingTop: 8,
            },
            dimensions.width
          )}
        >
          {/* LeftView */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                borderRadius: 14,
                flexDirection: 'row',
                justifyContent: 'center',
              },
              dimensions.width
            )}
          >
            {/* DrawerIcons */}
            <>
              {!(
                (props.route?.params?.item ?? defaultProps.item)
                  ?.related_transactions?.length > 0
              ) ? null : (
                <View>
                  <>
                    {!!showDetails ? null : (
                      <Icon size={24} name={'Entypo/chevron-small-down'} />
                    )}
                  </>
                  {/* Icon 2 */}
                  <>
                    {!showDetails ? null : (
                      <Icon size={24} name={'Entypo/chevron-small-right'} />
                    )}
                  </>
                </View>
              )}
            </>
            <Circle
              {...GlobalStyles.CircleStyles(theme)['Circle'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.CircleStyles(theme)['Circle'].style,
                  {
                    backgroundColor: theme.colors.border.brand,
                    height: 30,
                    width: 30,
                  }
                ),
                dimensions.width
              )}
            >
              {/* CreditIcon */}
              <>
                {!(
                  (props.route?.params?.item ?? defaultProps.item)?.type ===
                  'credit'
                ) ? null : (
                  <Icon
                    color={palettes.App.customGreen}
                    name={'AntDesign/caretdown'}
                    size={12}
                  />
                )}
              </>
              {/* RefundIcon */}
              <>
                {!(
                  (props.route?.params?.item ?? defaultProps.item)?.type ===
                  'refund'
                ) ? null : (
                  <Icon
                    color={palettes.App.customGreen}
                    name={'AntDesign/caretdown'}
                    size={12}
                  />
                )}
              </>
              {/* DebitIcon */}
              <>
                {!(
                  (props.route?.params?.item ?? defaultProps.item)?.type ===
                  'debit'
                ) ? null : (
                  <Icon
                    color={theme.colors.background.danger}
                    name={'AntDesign/caretup'}
                    size={12}
                  />
                )}
              </>
              {/* HoldIcon */}
              <>
                {!(
                  (props.route?.params?.item ?? defaultProps.item)?.type ===
                  'hold'
                ) ? null : (
                  <Icon
                    color={theme.colors.background.danger}
                    name={'AntDesign/caretup'}
                    size={12}
                  />
                )}
              </>
            </Circle>
            {/* TextFields */}
            <View
              style={StyleSheet.applyWidth(
                { marginLeft: 20 },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  dimensions.width
                )}
              >
                {(props.route?.params?.item ?? defaultProps.item)?.description}
              </Text>

              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: 'row' },
                  dimensions.width
                )}
              >
                <>
                  {!(props.route?.params?.item ?? defaultProps.item)
                    ?.promotion_id ? null : (
                    <Link
                      accessible={true}
                      {...GlobalStyles.LinkStyles(theme)['Link'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.LinkStyles(theme)['Link'].style,
                          { fontFamily: 'Poppins_400Regular', fontSize: 12 }
                        ),
                        dimensions.width
                      )}
                      title={`Promo #${
                        (props.route?.params?.item ?? defaultProps.item)
                          ?.promotion_id
                      }`}
                    />
                  )}
                </>
                {/* Text 2 */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: theme.colors.text.light,
                        fontSize: 12,
                        marginLeft: 4,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {(props.route?.params?.item ?? defaultProps.item)?.note}
                </Text>
              </View>
            </View>
          </View>
          {/* RightView */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'flex-end' },
              dimensions.width
            )}
          >
            {/* AmountText */}
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  { textAlign: 'right' }
                ),
                dimensions.width
              )}
            >
              {convertTransactionAmount(
                props.route?.params?.item ?? defaultProps.item
              )}
            </Text>
            {/* Text 2 */}
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  { color: theme.colors.text.light, fontSize: 12 }
                ),
                dimensions.width
              )}
            >
              {toDateString(
                (props.route?.params?.item ?? defaultProps.item)?.created_at
              )}
            </Text>
          </View>
        </View>
      </Pressable>
      <>
        {!showDetails ? null : (
          <FlatList
            data={
              (props.route?.params?.item ?? defaultProps.item)
                ?.related_transactions
            }
            horizontal={false}
            inverted={false}
            keyExtractor={(listData, index) =>
              listData?.id ??
              listData?.uuid ??
              index?.toString() ??
              JSON.stringify(listData)
            }
            keyboardShouldPersistTaps={'never'}
            listKey={'hzupQSze'}
            nestedScrollEnabled={false}
            numColumns={1}
            onEndReachedThreshold={0.5}
            renderItem={({ item, index }) => {
              const listData = item;
              return (
                <>
                  {/* InnerTransactionView */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: '"rgb(252, 255, 221)"',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        padding: 8,
                        paddingBottom: 8,
                        paddingLeft: 12,
                        paddingRight: 12,
                        paddingTop: 8,
                      },
                      dimensions.width
                    )}
                  >
                    {/* LeftView */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          borderRadius: 14,
                          flexDirection: 'row',
                          justifyContent: 'center',
                        },
                        dimensions.width
                      )}
                    >
                      <Circle
                        {...GlobalStyles.CircleStyles(theme)['Circle'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.CircleStyles(theme)['Circle'].style,
                            {
                              backgroundColor: theme.colors.border.brand,
                              height: 30,
                              width: 30,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {/* CreditIcon */}
                        <>
                          {!(listData?.type === 'credit') ? null : (
                            <Icon
                              color={palettes.App.customGreen}
                              name={'AntDesign/caretdown'}
                              size={12}
                            />
                          )}
                        </>
                        {/* RefundIcon */}
                        <>
                          {!(listData?.type === 'refund') ? null : (
                            <Icon
                              color={palettes.App.customGreen}
                              name={'AntDesign/caretdown'}
                              size={12}
                            />
                          )}
                        </>
                        {/* DebitIcon */}
                        <>
                          {!(listData?.type === 'debit') ? null : (
                            <Icon
                              color={theme.colors.background.danger}
                              name={'AntDesign/caretup'}
                              size={12}
                            />
                          )}
                        </>
                        {/* HoldIcon */}
                        <>
                          {!(listData?.type === 'hold') ? null : (
                            <Icon
                              color={theme.colors.background.danger}
                              name={'AntDesign/caretup'}
                              size={12}
                            />
                          )}
                        </>
                      </Circle>
                      {/* View 2 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { marginLeft: 20 },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            dimensions.width
                          )}
                        >
                          {listData?.description}
                        </Text>
                        {/* Text 2 */}
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              { color: theme.colors.text.light, fontSize: 12 }
                            ),
                            dimensions.width
                          )}
                        >
                          {listData?.note}
                        </Text>
                      </View>
                    </View>
                    {/* RightView */}
                    <View>
                      {/* AmountText */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { textAlign: 'right' }
                          ),
                          dimensions.width
                        )}
                      >
                        {convertTransactionAmount(listData)}
                      </Text>
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { color: theme.colors.text.light, fontSize: 12 }
                          ),
                          dimensions.width
                        )}
                      >
                        {toDateString(listData?.created_at)}
                      </Text>
                    </View>
                  </View>
                </>
              );
            }}
            showsHorizontalScrollIndicator={true}
            showsVerticalScrollIndicator={true}
          />
        )}
      </>
      <Divider
        {...GlobalStyles.DividerStyles(theme)['Divider'].props}
        color={theme.colors.border.brand}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.DividerStyles(theme)['Divider'].style,
            { width: '100%' }
          ),
          dimensions.width
        )}
      />
    </ScreenContainer>
  );
};

export default withTheme(ComponentTransactionRowScreen);
