import React from 'react';
import {
  Icon,
  IconButton,
  LinearProgress,
  Pressable,
  withTheme,
} from '@draftbit/ui';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import { BlurView } from 'expo-blur';
import { Modal, Platform, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as Lottie from '../custom-files/Lottie';
import * as UI from '../custom-files/UI';
import trackAction from '../global-functions/trackAction';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  items: [
    {
      id: 1,
      user_id: 4,
      adobe_urn: '',
      attachment: {
        url: 'https://xhrm-5alx-lhb1.n7.xano.io/vault/cH9onT_o/K7SLwPbWCHrWhG6C4m52tq2MObk/Qec51w../file-6debb5.mp4',
        meta: {
          video: {
            dar: false,
            fps: 30,
            par: false,
            res: '1080x1920',
            codec: 'h264 (High) (avc1 / 0x31637661)',
            bitrate: 406000,
            profile: 'yuv420p(tv',
            postWidth: 1080,
            postHeight: 1920,
          },
          duration: 3.87,
        },
        mime: 'video/mp4',
        name: 'file-6debb5.mp4',
        path: '/vault/cH9onT_o/K7SLwPbWCHrWhG6C4m52tq2MObk/Qec51w../file-6debb5.mp4',
        size: 201827,
        type: 'video',
        access: 'public',
      },
      created_at: 1715871025251,
    },
  ],
  onClose: () => {},
  onEndReached: () => {},
  onUnlock: () => {},
};

const ReelsPlayerBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const segment = useAnalytics();
  const [currentItem, setCurrentItem] = React.useState({});
  const [isSaved, setIsSaved] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [lockPercent, setLockPercent] = React.useState(0);
  const [playLottie, setPlayLottie] = React.useState(false);
  const [showLocker, setShowLocker] = React.useState(false);
  const [showUnLockButton, setShowUnLockButton] = React.useState(false);
  const [unlockButtonLoc, setUnlockButtonLoc] = React.useState({});
  const hideFlipScroll = () => {
    setSelectedCategory(-1);
  };
  const [resetCounter, setResetCounter] = React.useState(0); // New state to trigger resets

  // Function to call when you want to restart the timer
  const restartTimer = (lockScreen = true) => {
    setLockPercent(0); // Reset the progress
    setShowLocker(lockScreen); // Show the locker
    setShowUnLockButton(false); // Hide the locker
    setResetCounter(c => c + 1); // Increment the counter to trigger the useEffect
  };

  const generateRandomLocation = () => {
    const buttonWidth = 100;
    const buttonHeight = 100;
    const randomX = Math.floor(
      Math.random() * (dimensions.width - buttonWidth)
    );
    const randomY = Math.floor(
      Math.random() * (dimensions.height - 100 - buttonHeight)
    );
    setUnlockButtonLoc({ x: randomX, y: randomY });
    setShowUnLockButton(true); // Show the locker
  };

  React.useEffect(() => {
    const duration = 5 * 1000; // 5 seconds
    const intervalTime = duration / 100; // Time per increment

    const interval = setInterval(() => {
      setLockPercent(prevPercent => {
        const nextPercent = prevPercent + 1;
        if (nextPercent > 100) {
          clearInterval(interval);
          generateRandomLocation();
          return 100;
        }
        return nextPercent;
      });
    }, intervalTime);

    // Cleanup on unmount or before restarting the timer
    return () => clearInterval(interval);
  }, [resetCounter]); // Dependency on resetCounter to restart the timer

  return (
    <Modal
      animationType={'none'}
      supportedOrientations={['portrait', 'landscape']}
      presentationStyle={'fullScreen'}
      transparent={true}
      visible={(props.items ?? defaultProps.items)?.length > 0}
    >
      {/* ReelsPlayer */}
      <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: palettes.App['Transparent Overlay'],
              borderRadius: 40,
              left: 20,
              position: 'absolute',
              top: 40,
              zIndex: 3,
            },
            dimensions.width
          )}
        >
          <IconButton
            onPress={() => {
              try {
                trackAction(
                  Variables,
                  segment,
                  'Clicked Close Icon Button',
                  undefined
                );
                props.onClose?.();
              } catch (err) {
                Sentry.captureException(err);
                console.error(err);
              }
            }}
            size={32}
            color={palettes.App.White}
            icon={'Ionicons/close'}
          />
        </View>
        {/* LockView */}
        <>
          {!showLocker ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  bottom: 0,
                  flex: 1,
                  left: 0,
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  zIndex: 1,
                },
                dimensions.width
              )}
            >
              <LinearProgress
                animationDuration={500}
                indeterminate={false}
                isAnimated={true}
                showTrack={true}
                color={palettes.App['Custom Color_18']}
                lineCap={'square'}
                thickness={5}
                trackColor={'rgba(0, 0, 0, 0)'}
                trackLineCap={'square'}
                value={lockPercent}
              />
              {/* WebUnlock */}
              <>
                {!(showUnLockButton && Platform.OS === 'web') ? null : (
                  <Pressable
                    onPress={() => {
                      try {
                        trackAction(
                          Variables,
                          segment,
                          'Clicked Unlock Button',
                          undefined
                        );
                        setShowLocker(false);
                        setShowUnLockButton(false);
                        props.onUnlock?.(undefined);
                      } catch (err) {
                        Sentry.captureException(err);
                        console.error(err);
                      }
                    }}
                  >
                    {/* UnLockButton */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: palettes.App['Custom Color_18'],
                          borderRadius: 50,
                          height: 100,
                          justifyContent: 'center',
                          left: unlockButtonLoc?.x,
                          overflow: 'hidden',
                          position: 'absolute',
                          top: unlockButtonLoc?.y,
                          width: 100,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon name={'FontAwesome/unlock'} size={50} />
                    </View>
                  </Pressable>
                )}
              </>
              {/* AppUnlock */}
              <>
                {!(showUnLockButton && !(Platform.OS === 'web')) ? null : (
                  <Pressable
                    onPress={() => {
                      try {
                        trackAction(
                          Variables,
                          segment,
                          'Clicked Unlock Button',
                          undefined
                        );
                        setPlayLottie(!playLottie);
                      } catch (err) {
                        Sentry.captureException(err);
                        console.error(err);
                      }
                    }}
                  >
                    {/* UnLockButton */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          borderRadius: 50,
                          height: 100,
                          justifyContent: 'center',
                          left: unlockButtonLoc?.x,
                          overflow: 'hidden',
                          position: 'absolute',
                          top: unlockButtonLoc?.y,
                          width: 100,
                        },
                        dimensions.width
                      )}
                    >
                      <>
                        {Platform.OS === 'web' ? null : (
                          <Utils.CustomCodeErrorBoundary>
                            <Lottie.UnlockButton
                              width={100}
                              height={100}
                              stateVariables={[playLottie, setPlayLottie]}
                              onComplete={() => {
                                //console.log('onComplete')
                                setShowLocker(false);
                                setShowUnLockButton(false);
                                setPlayLottie(false);
                                props.onUnlock?.(currentItem?.id);
                              }}
                            />
                          </Utils.CustomCodeErrorBoundary>
                        )}
                      </>
                    </View>
                  </Pressable>
                )}
              </>
            </View>
          )}
        </>
        {/* ReelsWrapper */}
        <Utils.CustomCodeErrorBoundary>
          <UI.ReelsWrapper
            items={props.items}
            onPageChange={item => {
              setCurrentItem(item);
              restartTimer(!item._is_consumed_by_user);
            }}
            theme={props.theme}
            onEndReached={props?.onEndReached}
          />
        </Utils.CustomCodeErrorBoundary>
      </View>
    </Modal>
  );
};

export default withTheme(ReelsPlayerBlock);
