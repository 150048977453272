import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import {
  encodeQueryParam,
  renderParam,
  renderQueryString,
} from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

const cleanHeaders = headers =>
  Object.fromEntries(Object.entries(headers).filter(kv => kv[1] != null));

export const addGlobalPromotionPOST = async (
  Constants,
  {
    amountToMailboxx,
    amountToUser,
    assetType,
    budget,
    business_categories_id,
    content,
    detailImageIds,
    expires_at,
    for_category,
    for_explore,
    genders,
    heroImageId,
    max_age,
    min_age,
    redirect_link,
    title,
  },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:0roFfryw/promotion_global`;
  const options = {
    body: JSON.stringify({
      redirect_link: redirect_link,
      title: title,
      content: content,
      expires_at: expires_at,
      asset_type: assetType,
      amount_to_user: amountToUser,
      amount_to_mailboxx: amountToMailboxx,
      hero_image_id: heroImageId,
      detail_image_ids: detailImageIds,
      budget: budget,
      genders: genders,
      age_range: [min_age, max_age],
      for_explore: for_explore,
      for_category: for_category,
      business_categories_id: business_categories_id,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useAddGlobalPromotionPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      addGlobalPromotionPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('promotions', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('promotion');
        queryClient.invalidateQueries('promotions');
      },
    }
  );
};

export const FetchAddGlobalPromotionPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  amountToMailboxx,
  amountToUser,
  assetType,
  budget,
  business_categories_id,
  content,
  detailImageIds,
  expires_at,
  for_category,
  for_explore,
  genders,
  heroImageId,
  max_age,
  min_age,
  redirect_link,
  title,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useAddGlobalPromotionPOST(
    {
      amountToMailboxx,
      amountToUser,
      assetType,
      budget,
      business_categories_id,
      content,
      detailImageIds,
      expires_at,
      for_category,
      for_explore,
      genders,
      heroImageId,
      max_age,
      min_age,
      redirect_link,
      title,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchAddGlobalPromotion: refetch });
};

export const addPromotionPOST = async (
  Constants,
  {
    addresses_id,
    amount_to_mailboxx,
    amount_to_user,
    approved_zipcodes,
    asset_type,
    budget,
    business_categories_id,
    content,
    details_image_ids,
    expires_at,
    for_category,
    for_explore,
    genders,
    hero_image_id,
    max_age,
    min_age,
    radius,
    redirect_link,
    title,
  },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:0roFfryw/promotion`;
  const options = {
    body: JSON.stringify({
      title: title,
      redirect_link: redirect_link,
      content: content,
      expires_at: expires_at,
      asset_type: asset_type,
      budget: budget,
      amount_to_user: amount_to_user,
      amount_to_mailboxx: amount_to_mailboxx,
      approved_zipcodes: approved_zipcodes,
      addresses_id: addresses_id,
      radius: radius,
      hero_image_id: hero_image_id,
      detail_image_ids: details_image_ids,
      genders: genders,
      age_range: [min_age, max_age],
      for_explore: for_explore,
      for_category: for_category,
      business_categories_id: business_categories_id,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useAddPromotionPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => addPromotionPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('promotions', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('promotion');
        queryClient.invalidateQueries('promotions');
      },
    }
  );
};

export const FetchAddPromotionPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  addresses_id,
  amount_to_mailboxx,
  amount_to_user,
  approved_zipcodes,
  asset_type,
  budget,
  business_categories_id,
  content,
  details_image_ids,
  expires_at,
  for_category,
  for_explore,
  genders,
  hero_image_id,
  max_age,
  min_age,
  radius,
  redirect_link,
  title,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useAddPromotionPOST(
    {
      addresses_id,
      amount_to_mailboxx,
      amount_to_user,
      approved_zipcodes,
      asset_type,
      budget,
      business_categories_id,
      content,
      details_image_ids,
      expires_at,
      for_category,
      for_explore,
      genders,
      hero_image_id,
      max_age,
      min_age,
      radius,
      redirect_link,
      title,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchAddPromotion: refetch });
};

export const consumePromotionGET = async (
  Constants,
  { promotion_id },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:0roFfryw/consume_promotion/${encodeQueryParam(
    promotion_id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useConsumePromotionGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['promotionsServiceConsumePromotionGET', args],
    () => consumePromotionGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries([
          'promotionsServiceConsumePromotionGETS',
        ]),
    }
  );
};

export const FetchConsumePromotionGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  promotion_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useConsumePromotionGET(
    { promotion_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchConsumePromotion: refetch });
};

export const deletePromotionDELETE = async (
  Constants,
  { promotion_id },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:0roFfryw/promotion/${encodeQueryParam(
    promotion_id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
    method: 'DELETE',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDeletePromotionDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deletePromotionDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('promotions', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('promotion');
        queryClient.invalidateQueries('promotions');
      },
    }
  );
};

export const getAllPromotionsGET = async (
  Constants,
  { page, per_page },
  handlers = {}
) => {
  const paramsDict = {};
  if (page !== undefined) {
    paramsDict['page'] = renderParam(page);
  }
  if (per_page !== undefined) {
    paramsDict['per_page'] = renderParam(per_page);
  }
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:0roFfryw/promotion${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetAllPromotionsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['promotions', args],
    () => getAllPromotionsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetAllPromotionsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  page,
  per_page,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetAllPromotionsGET(
    { page, per_page },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetAllPromotions: refetch });
};

export const getCategoryPromotionsPOST = async (
  Constants,
  { business_categories_id, page, page_size },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:0roFfryw/explore_promotion`;
  const options = {
    body: JSON.stringify({
      business_categories_id: business_categories_id,
      page_size: page_size,
      page: page,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetCategoryPromotionsPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['promotionsServiceGetCategoryPromotionsPOST', args],
    () => getCategoryPromotionsPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries([
          'promotionsServiceGetCategoryPromotionsPOSTS',
        ]),
    }
  );
};

export const FetchGetCategoryPromotionsPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  business_categories_id,
  page,
  page_size,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetCategoryPromotionsPOST(
    { business_categories_id, page, page_size },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetCategoryPromotions: refetch,
  });
};

export const getGlobalPromotionsGET = async (
  Constants,
  _args,
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:0roFfryw/promotion_global`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetGlobalPromotionsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['promotions', args],
    () => getGlobalPromotionsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetGlobalPromotionsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetGlobalPromotionsGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetGlobalPromotions: refetch,
  });
};

export const getMyPromotionsGET = async (
  Constants,
  { get_expired, refresh },
  handlers = {}
) => {
  const paramsDict = {};
  if (get_expired !== undefined) {
    paramsDict['get_expired'] = renderParam(get_expired);
  }
  if (refresh !== undefined) {
    paramsDict['refresh'] = renderParam(refresh);
  }
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:0roFfryw/get_my_promotions${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetMyPromotionsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['promotions_of_business', args],
    () => getMyPromotionsGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['promotions_of_businesses']),
    }
  );
};

export const FetchGetMyPromotionsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  get_expired,
  refresh,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetMyPromotionsGET(
    { get_expired, refresh },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetMyPromotions: refetch });
};

export const getPromotionDetailsGET = async (
  Constants,
  { id },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:0roFfryw/promotion/${encodeQueryParam(
    id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetPromotionDetailsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['promotionsServiceGetPromotionDetailsGET', args],
    () => getPromotionDetailsGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries([
          'promotionsServiceGetPromotionDetailsGETS',
        ]),
    }
  );
};

export const FetchGetPromotionDetailsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetPromotionDetailsGET(
    { id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetPromotionDetails: refetch,
  });
};

export const getPromotionDetailsNoAuthGET = async (
  Constants,
  { id },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:0roFfryw/promotion_noauth/${encodeQueryParam(
    id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetPromotionDetailsNoAuthGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['promotionsServiceGetPromotionDetailsNoAuthGET', args],
    () => getPromotionDetailsNoAuthGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries([
          'promotionsServiceGetPromotionDetailsNoAuthGETS',
        ]),
    }
  );
};

export const FetchGetPromotionDetailsNoAuthGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetPromotionDetailsNoAuthGET(
    { id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetPromotionDetailsNoAuth: refetch,
  });
};

export const getPromotionStatsGET = async (
  Constants,
  { promotion_id },
  handlers = {}
) => {
  const paramsDict = {};
  if (promotion_id !== undefined) {
    paramsDict['promotion_id'] = renderParam(promotion_id);
  }
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:0roFfryw/get_promotion_stats${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetPromotionStatsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['promotionsServiceGetPromotionStatsGET', args],
    () => getPromotionStatsGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries([
          'promotionsServiceGetPromotionStatsGETS',
        ]),
    }
  );
};

export const FetchGetPromotionStatsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  promotion_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetPromotionStatsGET(
    { promotion_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetPromotionStats: refetch });
};

export const getPromotionsForCategoryGET = async (
  Constants,
  { addresses_id, business_categories_id, limit, scrolling_mode },
  handlers = {}
) => {
  const paramsDict = {};
  if (addresses_id !== undefined) {
    paramsDict['addresses_id'] = renderParam(addresses_id);
  }
  if (business_categories_id !== undefined) {
    paramsDict['business_categories_id'] = renderParam(business_categories_id);
  }
  if (limit !== undefined) {
    paramsDict['limit'] = renderParam(limit);
  }
  if (scrolling_mode !== undefined) {
    paramsDict['scrolling_mode'] = renderParam(scrolling_mode);
  }
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:0roFfryw/promotions_for_category${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetPromotionsForCategoryGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['promotionsServiceGetPromotionsForCategoryGET', args],
    () => getPromotionsForCategoryGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries([
          'promotionsServiceGetPromotionsForCategoryGETS',
        ]),
    }
  );
};

export const FetchGetPromotionsForCategoryGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  addresses_id,
  business_categories_id,
  limit,
  scrolling_mode,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetPromotionsForCategoryGET(
    { addresses_id, business_categories_id, limit, scrolling_mode },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetPromotionsForCategory: refetch,
  });
};

export const getPromotionsForExploreGET = async (
  Constants,
  { addresses_id, limit },
  handlers = {}
) => {
  const paramsDict = {};
  if (addresses_id !== undefined) {
    paramsDict['addresses_id'] = renderParam(addresses_id);
  }
  if (limit !== undefined) {
    paramsDict['limit'] = renderParam(limit);
  }
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:0roFfryw/promotions_for_explore${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetPromotionsForExploreGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['promotionsServiceGetPromotionsForExploreGET', args],
    () => getPromotionsForExploreGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries([
          'promotionsServiceGetPromotionsForExploreGETS',
        ]),
    }
  );
};

export const FetchGetPromotionsForExploreGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  addresses_id,
  limit,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetPromotionsForExploreGET(
    { addresses_id, limit },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetPromotionsForExplore: refetch,
  });
};

export const getPromotionsForInboxGET = async (
  Constants,
  { addresses_id },
  handlers = {}
) => {
  const paramsDict = {};
  if (addresses_id !== undefined) {
    paramsDict['addresses_id'] = renderParam(addresses_id);
  }
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:0roFfryw/promotions_for_inbox${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetPromotionsForInboxGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['promotionsServiceGetPromotionsForInboxGET', args],
    () => getPromotionsForInboxGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries([
          'promotionsServiceGetPromotionsForInboxGETS',
        ]),
    }
  );
};

export const FetchGetPromotionsForInboxGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  addresses_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetPromotionsForInboxGET(
    { addresses_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetPromotionsForInbox: refetch,
  });
};

export const getPromotionsForSearchGET = async (
  Constants,
  _args,
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:0roFfryw/promotions_for_search`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetPromotionsForSearchGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['promotionsServiceGetPromotionsForSearchGET', args],
    () => getPromotionsForSearchGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries([
          'promotionsServiceGetPromotionsForSearchGETS',
        ]),
    }
  );
};

export const FetchGetPromotionsForSearchGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetPromotionsForSearchGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetPromotionsForSearch: refetch,
  });
};

export const getSavedPromotionsGET = async (
  Constants,
  _args,
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:0roFfryw/saved_promotion`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetSavedPromotionsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['promotionsServiceGetSavedPromotionsGET', args],
    () => getSavedPromotionsGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries([
          'promotionsServiceGetSavedPromotionsGETS',
        ]),
    }
  );
};

export const FetchGetSavedPromotionsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetSavedPromotionsGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetSavedPromotions: refetch });
};

export const reCreatePromotionPOST = async (
  Constants,
  { promotion_id },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:0roFfryw/recreate_promotion`;
  const options = {
    body: JSON.stringify({ promotion_id: promotion_id }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useReCreatePromotionPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      reCreatePromotionPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData(
            'promotions_of_business',
            previousValue
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('promotions_of_business');
        queryClient.invalidateQueries('promotions_of_businesses');
      },
    }
  );
};

export const FetchReCreatePromotionPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  promotion_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useReCreatePromotionPOST(
    { promotion_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchReCreatePromotion: refetch });
};

export const savePromotionPOST = async (
  Constants,
  { promotion_id },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:0roFfryw/saved_promotion`;
  const options = {
    body: JSON.stringify({ promotion_id: promotion_id }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useSavePromotionPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['promotionsServiceSavePromotionPOST', args],
    () => savePromotionPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['promotionsServiceSavePromotionPOSTS']),
    }
  );
};

export const FetchSavePromotionPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  promotion_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useSavePromotionPOST(
    { promotion_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchSavePromotion: refetch });
};

export const stopPromotionPATCH = async (
  Constants,
  { promotion_id },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:0roFfryw/promotion/stop/${encodeQueryParam(
    promotion_id
  )}`;
  const options = {
    body: JSON.stringify({}),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useStopPromotionPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      stopPromotionPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('promotions', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('promotion');
        queryClient.invalidateQueries('promotions');
      },
    }
  );
};

export const togglePromotionPATCH = async (
  Constants,
  { promotion_id },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:0roFfryw/promotion/toggle/${encodeQueryParam(
    promotion_id
  )}`;
  const options = {
    body: JSON.stringify({ key: 'value' }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useTogglePromotionPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      togglePromotionPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('promotions', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('promotion');
        queryClient.invalidateQueries('promotions');
      },
    }
  );
};

export const updatePromotionBudgetPATCH = async (
  Constants,
  { balance, promotion_id },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:0roFfryw/promotion/update_budget/${encodeQueryParam(
    promotion_id
  )}`;
  const options = {
    body: JSON.stringify({ balance: balance }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUpdatePromotionBudgetPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      updatePromotionBudgetPATCH(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('promotions', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('promotion');
        queryClient.invalidateQueries('promotions');
      },
    }
  );
};
