import React from 'react';
import {
  Button,
  Divider,
  Icon,
  KeyboardAvoidingView,
  Picker,
  Pressable,
  ScreenContainer,
  SimpleStyleKeyboardAwareScrollView,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { H4 } from '@expo/html-elements';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import { ActivityIndicator, Image, Platform, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoServiceApi from '../apis/XanoServiceApi.js';
import WideHeaderBlock from '../components/WideHeaderBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as ImageGallery from '../custom-files/ImageGallery';
import formatDate from '../global-functions/formatDate';
import isLoggedIn from '../global-functions/isLoggedIn';
import trackAction from '../global-functions/trackAction';
import trackScreen from '../global-functions/trackScreen';
import transformBusinessCategories from '../global-functions/transformBusinessCategories';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const ProfileUpdateScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const [accountImage, setAccountImage] = React.useState(
    Constants['USER_DATA']?.account_image
  );
  const [businessCategoriesId, setBusinessCategoriesId] = React.useState(
    Constants['USER_DATA']?._business_profile_of_user?.business_categories_id
  );
  const [businessEinNumber, setBusinessEinNumber] = React.useState(
    Constants['USER_DATA']?._business_profile_of_user?.ein_number
  );
  const [businessName, setBusinessName] = React.useState(
    Constants['USER_DATA']?._business_profile_of_user?.name
  );
  const [errorMessage, setErrorMessage] = React.useState('');
  const [firstName, setFirstName] = React.useState(
    Constants['USER_DATA']?.first_name
  );
  const [headerImage, setHeaderImage] = React.useState(
    Constants['USER_DATA']?._business_profile_of_user?.header_image?.attachment
      ?.url
  );
  const [isBusiness, setIsBusiness] = React.useState(false);
  const [lastName, setLastName] = React.useState(
    Constants['USER_DATA']?.last_name
  );
  const [loading, setLoading] = React.useState(false);
  const xanoServiceUpdateBusinessProfilePUT =
    XanoServiceApi.useUpdateBusinessProfilePUT();
  const xanoServiceUpdateUserProfilePUT =
    XanoServiceApi.useUpdateUserProfilePUT();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        if (!isLoggedIn(Variables)) {
          navigation.navigate('AuthLoginScreen');
        }
        trackScreen(segment, 'Profile Update');
        const getUserInfoResult = (
          await XanoServiceApi.getUserInfoGET(Constants)
        )?.json;
        setAccountImage(getUserInfoResult?.account_image);
        setHeaderImage(
          getUserInfoResult?._business_profile_of_user?.header_image
        );
        setIsBusiness(Constants['USER_DATA']?.role === 'business');
      } catch (err) {
        Sentry.captureException(err);
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App.CustomGray },
        dimensions.width
      )}
    >
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <WideHeaderBlock currentScreen={'settings'} withGoBack={true} />
        )}
      </>
      {/* Header */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            {...GlobalStyles.ViewStyles(theme)['Header'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['Header'].style,
              dimensions.width
            )}
          >
            {/* AbsoluteTitle 2 */}
            <View
              {...GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].style,
                dimensions.width
              )}
            >
              <Image
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                resizeMode={'contain'}
                source={imageSource(Images['MailboxxGreenLogo'])}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { height: 30, width: 120 }
                  ),
                  dimensions.width
                )}
              />
            </View>
            {/* BackButton */}
            <Pressable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              {...GlobalStyles.PressableStyles(theme)['BackButton'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.PressableStyles(theme)['BackButton'].style,
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={theme.colors.branding.primary}
                  name={'Ionicons/chevron-back'}
                  size={28}
                />
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['WideHeaderText'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['WideHeaderText'].style,
                      { fontSize: 18 }
                    ),
                    dimensions.width
                  )}
                >
                  {'Back'}
                </Text>
              </View>
            </Pressable>
          </View>
        )}
      </>
      <SimpleStyleKeyboardAwareScrollView
        enableOnAndroid={false}
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps={'never'}
        showsVerticalScrollIndicator={true}
        viewIsInsideTabBar={false}
        enableAutomaticScroll={true}
      >
        {/* Body */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
              backgroundColor: theme.colors.background.brand,
              flex: 1,
              maxWidth: 1024,
              width: '100%',
            },
            dimensions.width
          )}
        >
          {/* Main */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignSelf: 'auto',
                marginTop: 30,
                paddingLeft: 20,
                paddingRight: 20,
              },
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  { fontSize: 32, textAlign: 'center' }
                ),
                dimensions.width
              )}
            >
              {'Update profile'}
            </Text>
            {/* Header Image Selector */}
            <>
              {!(Constants['USER_DATA']?.role === 'business') ? null : (
                <View
                  {...GlobalStyles.ViewStyles(theme)['Image Selector'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ViewStyles(theme)['Image Selector'].style,
                      { minHeight: 150 }
                    ),
                    dimensions.width
                  )}
                >
                  {/* ImageGallery */}
                  <Utils.CustomCodeErrorBoundary>
                    <ImageGallery.Picker
                      label="Header Image"
                      file={headerImage}
                      setFile={setHeaderImage}
                      fileType={'image'}
                      multi={false}
                      orientation={'banner'}
                    />
                  </Utils.CustomCodeErrorBoundary>
                </View>
              )}
            </>
            {/* Image Selector */}
            <View
              {...GlobalStyles.ViewStyles(theme)['Image Selector'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ViewStyles(theme)['Image Selector'].style,
                  { minHeight: 150 }
                ),
                dimensions.width
              )}
            >
              {/* ImageGallery */}
              <Utils.CustomCodeErrorBoundary>
                <ImageGallery.Picker
                  label="Profile Image"
                  file={accountImage}
                  setFile={setAccountImage}
                  fileType={'image'}
                  multi={false}
                  orientation={'portrait'}
                />
              </Utils.CustomCodeErrorBoundary>
            </View>
            {/* Email */}
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
              defaultValue={Constants['USER_DATA']?.email}
              disabled={true}
              editable={false}
              placeholder={'Email'}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                  {
                    color: theme.colors.text.light,
                    marginTop: 12,
                    paddingLeft: [
                      { minWidth: Breakpoints.Mobile, value: 8 },
                      {
                        minWidth: Breakpoints.Mobile,
                        value:
                          Platform.OS === 'android' ? undefined : undefined,
                      },
                    ],
                  }
                ),
                dimensions.width
              )}
            />
            {/* Firstname Input */}
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newFirstnameInputValue => {
                try {
                  setFirstName(newFirstnameInputValue);
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
              placeholder={(isBusiness
                ? 'Representative First Name'
                : 'First Name'
              ).toString()}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                  { marginTop: 12 }
                ),
                dimensions.width
              )}
              value={firstName}
            />
            {/* Lastname Input */}
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newLastnameInputValue => {
                try {
                  setLastName(newLastnameInputValue);
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
              placeholder={(isBusiness
                ? 'Representative Last Name'
                : 'Lastname'
              ).toString()}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                  { marginTop: 20 }
                ),
                dimensions.width
              )}
              value={lastName}
            />
            {/* Dob */}
            <>
              {isBusiness ? null : (
                <TextInput
                  autoCapitalize={'none'}
                  autoCorrect={true}
                  changeTextDelay={500}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
                  defaultValue={formatDate(Constants['USER_DATA']?.dob)}
                  disabled={true}
                  editable={false}
                  placeholder={'Date Of Birth'}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                      { color: theme.colors.text.light, marginTop: 12 }
                    ),
                    dimensions.width
                  )}
                />
              )}
            </>
            {/* Gender */}
            <>
              {isBusiness ? null : (
                <TextInput
                  autoCapitalize={'none'}
                  autoCorrect={true}
                  changeTextDelay={500}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
                  defaultValue={
                    Constants['USER_DATA']?.gender === 'male'
                      ? 'Male'
                      : Constants['USER_DATA']?.gender === 'female'
                      ? 'Female'
                      : Constants['USER_DATA']?.gender === 'other'
                      ? 'Other'
                      : 'Prefer Not To Say'
                  }
                  disabled={true}
                  editable={false}
                  placeholder={'Gender'}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                      { color: theme.colors.text.light, marginTop: 12 }
                    ),
                    dimensions.width
                  )}
                />
              )}
            </>
            <>
              {!isBusiness ? null : (
                <Divider
                  color={theme.colors.border.brand}
                  {...GlobalStyles.DividerStyles(theme)['Divider'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.DividerStyles(theme)['Divider'].style,
                      { marginTop: 20 }
                    ),
                    dimensions.width
                  )}
                />
              )}
            </>
            <H4
              selectable={false}
              {...GlobalStyles.H4Styles(theme)['H4'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.H4Styles(theme)['H4'].style, {
                  marginBottom: 10,
                }),
                dimensions.width
              )}
            >
              {'Business Profile Information'}
            </H4>
            {/* BusinessName */}
            <>
              {!isBusiness ? null : (
                <TextInput
                  autoCapitalize={'none'}
                  autoCorrect={true}
                  changeTextDelay={500}
                  onChangeText={newBusinessNameValue => {
                    try {
                      setBusinessName(newBusinessNameValue);
                    } catch (err) {
                      Sentry.captureException(err);
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
                  placeholder={'Business Name'}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                      { marginTop: 12 }
                    ),
                    dimensions.width
                  )}
                  value={businessName}
                />
              )}
            </>
            {/* BusinessEinNumber */}
            <>
              {!isBusiness ? null : (
                <TextInput
                  autoCapitalize={'none'}
                  autoCorrect={true}
                  changeTextDelay={500}
                  onChangeText={newBusinessEinNumberValue => {
                    try {
                      setBusinessEinNumber(newBusinessEinNumberValue);
                    } catch (err) {
                      Sentry.captureException(err);
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
                  placeholder={'EIN Number'}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                      { marginTop: 12 }
                    ),
                    dimensions.width
                  )}
                  value={businessEinNumber}
                />
              )}
            </>
            <XanoServiceApi.FetchGetBussinessCategoriesGET excludeEmpty={false}>
              {({ loading, error, data, refetchGetBussinessCategories }) => {
                const fetchData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <View>
                    {/* BusinessCategoryPicker */}
                    <Picker
                      autoDismissKeyboard={true}
                      dropDownBackgroundColor={theme.colors.background.brand}
                      dropDownBorderColor={theme.colors.border.brand}
                      dropDownBorderRadius={8}
                      dropDownBorderWidth={1}
                      dropDownTextColor={theme.colors.text.strong}
                      iconSize={24}
                      leftIconMode={'inset'}
                      mode={'native'}
                      onValueChange={newBusinessCategoryPickerValue => {
                        try {
                          setBusinessCategoriesId(
                            newBusinessCategoryPickerValue
                          );
                        } catch (err) {
                          Sentry.captureException(err);
                          console.error(err);
                        }
                      }}
                      selectedIconColor={theme.colors.text.strong}
                      selectedIconName={'Feather/check'}
                      selectedIconSize={20}
                      options={transformBusinessCategories(fetchData)}
                      placeholder={'Business Category'}
                      placeholderTextColor={theme.colors.text.light}
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: palettes.App.CustomGray,
                          borderRadius: 8,
                          borderWidth: 0,
                          fontFamily: 'Poppins_200ExtraLight',
                          fontSize: 13,
                          padding: 8,
                        },
                        dimensions.width
                      )}
                      type={'underline'}
                      value={businessCategoriesId}
                    />
                  </View>
                );
              }}
            </XanoServiceApi.FetchGetBussinessCategoriesGET>
            {/* SaveButton */}
            <Button
              iconPosition={'left'}
              onPress={() => {
                const handler = async () => {
                  try {
                    setLoading(true);
                    if (isBusiness) {
                      const updatBusinessProfileResult = (
                        await xanoServiceUpdateBusinessProfilePUT.mutateAsync({
                          account_image: accountImage?.id,
                          business_categories_id: businessCategoriesId,
                          business_name: businessName,
                          ein_number: businessEinNumber,
                          first_name: firstName,
                          header_image: headerImage?.id,
                          last_name: lastName,
                        })
                      )?.json;
                      console.log(updatBusinessProfileResult);
                      setGlobalVariableValue({
                        key: 'USER_DATA',
                        value: updatBusinessProfileResult,
                      });
                    } else {
                      const updateUserProfileResult = (
                        await xanoServiceUpdateUserProfilePUT.mutateAsync({
                          account_image: accountImage?.id,
                          first_name: firstName,
                          last_name: lastName,
                        })
                      )?.json;
                      if (!updateUserProfileResult?.message) {
                        setGlobalVariableValue({
                          key: 'USER_DATA',
                          value: updateUserProfileResult,
                        });
                      }
                    }

                    setLoading(false);
                    trackAction(
                      Variables,
                      segment,
                      'Profile updated',
                      undefined
                    );
                    navigation.goBack();
                  } catch (err) {
                    Sentry.captureException(err);
                    console.error(err);
                  }
                };
                handler();
              }}
              {...GlobalStyles.ButtonStyles(theme)['Button'].props}
              disabled={loading}
              loading={loading}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['Button'].style,
                  {
                    backgroundColor: theme.colors.branding.primary,
                    marginTop: 20,
                  }
                ),
                dimensions.width
              )}
              title={'Save'}
            />
            {/* errorView */}
            <>
              {!errorMessage ? null : (
                <View
                  {...GlobalStyles.ViewStyles(theme)['errorView'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ViewStyles(theme)['errorView'].style,
                      {
                        backgroundColor: theme.colors.border.brand,
                        borderRadius: 13,
                        marginBottom: -40,
                        padding: 11,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {/* errorText */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: theme.colors.background.danger,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {errorMessage}
                  </Text>
                </View>
              )}
            </>
          </View>
        </View>
      </SimpleStyleKeyboardAwareScrollView>
    </ScreenContainer>
  );
};

export default withTheme(ProfileUpdateScreen);
