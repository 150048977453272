import React from 'react';
import {
  Icon,
  IconButton,
  Pressable,
  ScreenContainer,
  withTheme,
} from '@draftbit/ui';
import { H3 } from '@expo/html-elements';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import { ActivityIndicator, Image, Keyboard, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoServiceApi from '../apis/XanoServiceApi.js';
import WideHeaderBlock from '../components/WideHeaderBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as DrawerScreen from '../custom-files/DrawerScreen';
import * as MiniCharts from '../custom-files/MiniCharts';
import isLoggedIn from '../global-functions/isLoggedIn';
import trackScreen from '../global-functions/trackScreen';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const AdminDashboardScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (!isLoggedIn(Variables)) {
        navigation.navigate('AuthLoginScreen');
      }
      if (Constants['USER_DATA']?.role !== 'admin') {
        navigation.navigate('AuthLoginScreen');
      }
      trackScreen(segment, 'Admin - Dashboard');
      setGlobalVariableValue({
        key: 'SHOW_DRAWER',
        value: false,
      });
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasSafeArea={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App.CustomGray },
        dimensions.width
      )}
    >
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <WideHeaderBlock currentScreen={'dashboard'} />
        )}
      </>
      {/* DrawerNew */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <Utils.CustomCodeErrorBoundary>
            <DrawerScreen.DrawerView
              currentScreen="dashboard"
              navigation={props.navigation}
            />
          </Utils.CustomCodeErrorBoundary>
        )}
      </>
      {/* Header */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            {...GlobalStyles.ViewStyles(theme)['Header'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['Header'].style,
              dimensions.width
            )}
          >
            {/* MenuButton */}
            <IconButton
              onPress={() => {
                try {
                  Keyboard.dismiss();
                  setGlobalVariableValue({
                    key: 'SHOW_DRAWER',
                    value: true,
                  });
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              size={32}
              {...GlobalStyles.IconButtonStyles(theme)['MenuButton'].props}
              icon={'Entypo/menu'}
              style={StyleSheet.applyWidth(
                GlobalStyles.IconButtonStyles(theme)['MenuButton'].style,
                dimensions.width
              )}
            />
            {/* AbsoluteTitle */}
            <View
              {...GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].style,
                dimensions.width
              )}
            >
              <Image
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                resizeMode={'contain'}
                source={imageSource(Images['MailboxxGreenLogo'])}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { height: 30, width: 120 }
                  ),
                  dimensions.width
                )}
              />
            </View>
          </View>
        )}
      </>
      <View
        style={StyleSheet.applyWidth(
          {
            alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
            backgroundColor: [
              { minWidth: Breakpoints.Mobile, value: palettes.App.CustomGray },
              {
                minWidth: Breakpoints.Desktop,
                value: theme.colors.background.brand,
              },
            ],
            flex: 1,
            maxWidth: 1024,
            width: { minWidth: Breakpoints.Laptop, value: '100%' },
          },
          dimensions.width
        )}
      >
        {/* Header */}
        <View
          {...GlobalStyles.ViewStyles(theme)['DashboardRow'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.ViewStyles(theme)['DashboardRow'].style,
              { alignItems: 'flex-start', flexDirection: 'column' }
            ),
            dimensions.width
          )}
        >
          <H3
            selectable={false}
            {...GlobalStyles.H3Styles(theme)['H3'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.H3Styles(theme)['H3'].style,
              dimensions.width
            )}
          >
            {'Users Statistics'}
          </H3>

          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.TextStyles(theme)['Text'].style,
              dimensions.width
            )}
          >
            {'Tap on cards to get detailed reports'}
          </Text>
        </View>

        <XanoServiceApi.FetchAdminStatsGET>
          {({ loading, error, data, refetchAdminStats }) => {
            const fetchData = data?.json;
            if (loading) {
              return <ActivityIndicator />;
            }

            if (error || data?.status < 200 || data?.status >= 300) {
              return <ActivityIndicator />;
            }

            return (
              <>
                {/* Row */}
                <View
                  {...GlobalStyles.ViewStyles(theme)['DashboardRow'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ViewStyles(theme)['DashboardRow'].style,
                    dimensions.width
                  )}
                >
                  {/* Col */}
                  <View
                    {...GlobalStyles.ViewStyles(theme)['DashboardCol'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ViewStyles(theme)['DashboardCol'].style,
                        { marginRight: 6 }
                      ),
                      dimensions.width
                    )}
                  >
                    <Pressable>
                      {/* CardTitle */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              color: palettes.App['Custom black'],
                              fontFamily: 'Poppins_600SemiBold',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Total Users'}
                      </Text>
                      {/* CardAmount */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              fontFamily: 'Poppins_600SemiBold',
                              fontSize: 24,
                              marginBottom: 8,
                              marginTop: 8,
                              textAlign: 'center',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {fetchData?.total_users}
                      </Text>

                      <View
                        style={StyleSheet.applyWidth(
                          { flexDirection: 'row' },
                          dimensions.width
                        )}
                      >
                        <Icon
                          color={palettes.App.customGreen}
                          name={'MaterialCommunityIcons/arrow-top-right'}
                          size={20}
                        />
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            dimensions.width
                          )}
                        >
                          {fetchData?.total_users_lastweek}
                        </Text>
                      </View>

                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { color: palettes.App.TextPlaceholder }
                          ),
                          dimensions.width
                        )}
                      >
                        {' since Last Week'}
                      </Text>
                    </Pressable>
                  </View>
                  {/* Col 3 */}
                  <View
                    {...GlobalStyles.ViewStyles(theme)['DashboardCol'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ViewStyles(theme)['DashboardCol'].style,
                        { marginRight: 6 }
                      ),
                      dimensions.width
                    )}
                  >
                    <Pressable>
                      {/* CardTitle */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              color: palettes.App['Custom black'],
                              fontFamily: 'Poppins_600SemiBold',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Total Business Users'}
                      </Text>
                      {/* CardAmount */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              fontFamily: 'Poppins_600SemiBold',
                              fontSize: 24,
                              marginBottom: 8,
                              marginTop: 8,
                              textAlign: 'center',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {fetchData?.total_business_users}
                      </Text>

                      <View
                        style={StyleSheet.applyWidth(
                          { flexDirection: 'row' },
                          dimensions.width
                        )}
                      >
                        <Icon
                          color={palettes.App.customGreen}
                          name={'MaterialCommunityIcons/arrow-top-right'}
                          size={20}
                        />
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            dimensions.width
                          )}
                        >
                          {fetchData?.total_business_users_lastweek}
                        </Text>
                      </View>

                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { color: palettes.App.TextPlaceholder }
                          ),
                          dimensions.width
                        )}
                      >
                        {' since Last Week'}
                      </Text>
                    </Pressable>
                  </View>
                </View>
                {/* Row 2 */}
                <View
                  {...GlobalStyles.ViewStyles(theme)['DashboardRow'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ViewStyles(theme)['DashboardRow'].style,
                    dimensions.width
                  )}
                >
                  {/* Col */}
                  <View
                    {...GlobalStyles.ViewStyles(theme)['DashboardCol'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ViewStyles(theme)['DashboardCol'].style,
                      dimensions.width
                    )}
                  >
                    <Pressable>
                      {/* CardTitle */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              color: palettes.App['Custom black'],
                              fontFamily: 'Poppins_600SemiBold',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Active Promotion'}
                      </Text>
                      {/* CardSubTitle */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                          },
                          dimensions.width
                        )}
                      >
                        {/* CardAmount */}
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                fontFamily: 'Poppins_600SemiBold',
                                fontSize: 24,
                                marginBottom: 8,
                                marginTop: 8,
                                textAlign: 'center',
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {fetchData?.total_active_promotions}
                        </Text>
                        {/* View 2 */}
                        <View
                          style={StyleSheet.applyWidth(
                            { flexDirection: 'row' },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              { flexDirection: 'row' },
                              dimensions.width
                            )}
                          >
                            <Icon
                              color={theme.colors.branding.primary}
                              name={'Feather/arrow-up-right'}
                              size={20}
                            />
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                dimensions.width
                              )}
                            >
                              {fetchData?.total_active_promotions_lastweek}
                            </Text>
                          </View>

                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                { color: palettes.App.TextPlaceholder }
                              ),
                              dimensions.width
                            )}
                          >
                            {' since Last Week'}
                          </Text>
                        </View>
                      </View>
                    </Pressable>
                  </View>
                </View>
              </>
            );
          }}
        </XanoServiceApi.FetchAdminStatsGET>
        {/* Row 3 */}
        <View
          {...GlobalStyles.ViewStyles(theme)['DashboardRow'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['DashboardRow'].style,
            dimensions.width
          )}
        />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(AdminDashboardScreen);
