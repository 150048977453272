// This import is required if you are defining react components in this module.
import React, { useState, useEffect } from 'react';
import { Text, Image, View, Button, Pressable } from 'react-native';

const CDN_URL = 'https://cc-embed.adobe.com/sdk/v4/CCEverywhere.js';

export const AdobeWrapper = ({
  children,
  action = '',
  assetType = 'image',
  resetAction,
  uploadsId,
  orientation,
  urn,
  initialImageAsset,
  onSave,
}) => {
  const [instance, setInstance] = useState(null);

  const canvasSize =
    orientation === 'portrait'
      ? { width: 1080, height: 1920 }
      : orientation === 'landscape'
      ? { width: 1080, height: 1920 }
      : { width: 960, height: 480 };

  useEffect(() => {
    //Clear the existing CCEverywhere from the window object
    if (window.CCEverywhere) {
      window.CCEverywhere.terminate();
      console.log('Cleared existing CCEverywhere from window object');
    }

    // Initialize the CCEverywhere SDK
    initializeSdk();
  }, []);

  useEffect(() => {
    if (!instance) return;

    if (action === '') return;

    handlePress();
  }, [action, instance]);

  const loadScript = src => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.async = true;

      script.onload = () => resolve();
      script.onerror = err => reject(err);

      document.head.appendChild(script);
    });
  };

  const initializeSdk = async () => {
    try {
      console.log('Initializing SDK...');
      await loadScript(CDN_URL);
      console.log('Script loaded');

      const ccEverywhere = await window.CCEverywhere.initialize({
        clientId: 'caff47aedf7f4f91b2417702a473f445',
        appName: 'Mailboxx',
      });

      console.log('CCEverywhere initialized', ccEverywhere);

      const { editor } = ccEverywhere;
      if (!editor) {
        throw new Error('Editor instance is null after initialization');
      }
      setInstance(ccEverywhere);

      return editor;
    } catch (err) {
      console.error('Error initializing CCEverywhere', err);
      throw err;
    }
  };

  const callbacks = {
    onCancel: () => {
      console.log('Project creation canceled');
      resetAction?.();
    },
    onPublish: (intent, publishParams) => {
      const localData = {
        project: publishParams.projectId,
        image: publishParams.asset[0].data,
        uploadsId,
      };
      console.log('Project published with params:', localData);
      onSave?.(localData);
    },
    onError: err => {
      console.error('Error received:', err.toString());
      resetAction?.();
    },
  };

  const exportConfig = [
    {
      id: 'download',
      label: 'Download',
      action: {
        target: 'download',
      },
      style: {
        uiType: 'button',
      },
    },
    {
      action: {
        target: 'publish',
        outputype: 'URL',
        closeTargetOnExport: true,
      },
      id: 'saveToHostApp',
      label: 'Save in App',
      style: {
        uiType: 'button',
      },
    },
  ];

  const containerConfig = {
    zIndex: 10000,
  };

  const createProject = async () => {
    console.log('Attempting to create project...', instance);

    try {
      const { editor } = instance;
      if (!editor) {
        return;
      }

      console.log('Creating project...');
      editor.create(
        {
          canvasSize: {
            width: canvasSize.width,
            height: canvasSize.height,
            unit: 'px',
          },
        },
        {
          selectedCategory: 'templates',
          templateType: 'brochure',
          allowedFileTypes:
            assetType === 'image'
              ? ['image/png', 'image/jpeg', 'application/pdf']
              : ['video/mp4'],
          multiPage: false,
          callbacks,
        },
        exportConfig,
        containerConfig
      );
      console.log('Editor.create called successfully');
    } catch (err) {
      console.error('Error creating project', err);
    }
  };

  const createProjectWithAsset = async base64Asset => {
    console.log('Attempting to create project with asset...');
    let appConfig = {
      callbacks: callbacks,
      allowedFileTypes:
        assetType === 'image'
          ? ['image/png', 'image/jpeg', 'application/pdf']
          : ['video/mp4'],
    };
    let docConfig = {
      asset: {
        data: base64Asset,
        dataType: assetType === 'image' ? 'base64' : 'url',
        type: assetType,
      },
      canvasSize: {
        width: canvasSize.width,
        height: canvasSize.height,
        unit: 'px',
      },
    };

    const { editor } = instance;
    if (!editor) {
      return;
    }

    editor.createWithAsset(docConfig, appConfig, exportConfig, containerConfig);
  };

  const editProject = async () => {
    console.log('Attempting to edit project...');

    let docConfig = {
      documentId: urn,
    };
    let appConfig = {
      selectedCategory: 'media',
      callbacks: callbacks,
      allowedFileTypes:
        assetType === 'image'
          ? ['image/png', 'image/jpeg', 'application/pdf']
          : ['video/mp4'],
    };

    const { editor } = instance;
    if (!editor) {
      return;
    }

    editor.edit(docConfig, appConfig, exportConfig, containerConfig);
  };

  const handlePress = async () => {
    if (action === 'create') {
      await createProject();
      return;
    } else if (action === 'edit' && urn) {
      await editProject();
      return;
    } else if (action === 'createWithAsset' && initialImageAsset) {
      await createProjectWithAsset(initialImageAsset);
      return;
    }
    resetAction?.();
    console.log('No action specified');
  };

  return children;
};
