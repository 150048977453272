import React from 'react';
import { Pressable, withTheme } from '@draftbit/ui';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import { View } from 'react-native';
import * as PromotionsServiceApi from '../apis/PromotionsServiceApi.js';
import ReelsPlayerBlock from '../components/ReelsPlayerBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import trackAction from '../global-functions/trackAction';
import palettes from '../themes/palettes';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  address_id: 1,
  business_categories_id: 2,
  limit: 10,
  onClose: () => {},
  startingPromotionId: null,
};

const CategoryPromotionsBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const segment = useAnalytics();
  const [items, setItems] = React.useState([]);
  const onUnlockFunction = async currentItemId => {
    // console.log('unlock pressed and current items are ', currentItemId, items.map(i => i.id))

    try {
      const result = (
        await PromotionsServiceApi.consumePromotionGET(Constants, {
          promotion_id: currentItemId,
        })
      )?.json;

      // console.log('result', result )

      setItems(prev => {
        prev.forEach(item => {
          if (item.id === currentItemId) {
            item._is_consumed_by_user = true;
          }
        });
        return prev;
      });
    } catch (err) {
      console.error(err);
    }
  };

  const onEndReachedFunction = async () => {
    try {
      if (props.address_id) {
        const promotionsResult =
          (
            await PromotionsServiceApi.getPromotionsForCategoryGET(Constants, {
              addresses_id: props.address_id || 1,
              business_categories_id: props.business_categories_id ?? 2,
              limit: props.limit || 10,
              scrolling_mode: true,
            })
          )?.json || [];

        setItems(prev => [...prev, ...promotionsResult]);
      }
    } catch (err) {
      console.error(err);
    }
  };
  React.useEffect(() => {
    const handler = async () => {
      try {
        const promotionsResult = (
          await PromotionsServiceApi.getPromotionsForCategoryGET(Constants, {
            addresses_id: props.address_id ?? defaultProps.address_id,
            business_categories_id:
              props.business_categories_id ??
              defaultProps.business_categories_id,
            limit: props.limit ?? defaultProps.limit,
            scrolling_mode: true,
          })
        )?.json;
        setItems(promotionsResult);
      } catch (err) {
        Sentry.captureException(err);
        console.error(err);
      }
    };
    handler();
  }, []);

  return (
    <View>
      <ReelsPlayerBlock
        items={items}
        onClose={props.onClose}
        onEndReached={() => onEndReachedFunction()}
        onUnlock={currentItemId => onUnlockFunction(currentItemId)}
      />
      {/* PressableForApiEndpoint */}
      <Pressable
        onPress={() => {
          const handler = async () => {
            try {
              trackAction(Variables, segment, 'Promotion Consumed', undefined);
              (
                await PromotionsServiceApi.consumePromotionGET(Constants, {
                  promotion_id: 1116,
                })
              )?.json;
            } catch (err) {
              Sentry.captureException(err);
              console.error(err);
            }
          };
          handler();
        }}
      />
    </View>
  );
};

export default withTheme(CategoryPromotionsBlock);
