import React from 'react';
import {
  Circle,
  LinearGradient,
  Link,
  Pressable,
  SimpleStyleScrollView,
  withTheme,
} from '@draftbit/ui';
import { useNavigation } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import { Image, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import trackAction from '../global-functions/trackAction';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  onToggle: () => {},
  promotion: {
    id: 7,
    logo: {
      id: 96,
      user_id: 77,
      adobe_urn: '',
      attachment: {
        url: 'https://xhrm-5alx-lhb1.n7.xano.io/vault/cH9onT_o/39Bzavh7F-2uoGshWd7mv9w_oNo/uQ1-HA../file-382c55.jpeg',
        meta: { width: 1024, height: 1024 },
        mime: 'image/jpeg',
        name: 'file-382c55.jpeg',
        path: '/vault/cH9onT_o/39Bzavh7F-2uoGshWd7mv9w_oNo/uQ1-HA../file-382c55.jpeg',
        size: 95755,
        type: 'image',
        access: 'public',
      },
      created_at: 1725363120970,
    },
    title: 'Winter Collection Launch',
    budget: 4000,
    gender: ['male', 'female', 'other', 'prefer-not-to-say'],
    radius: 0,
    content:
      "See the latest winter styles at FashionHub. From breezy dresses to trendy swimwear, we've got you covered.",
    max_age: 65,
    min_age: 0,
    is_active: true,
    asset_type: 'video',
    created_at: 1725373518330,
    creator_id: 77,
    expires_at: 1735568040000,
    hero_image: {
      id: 140,
      user_id: 77,
      adobe_urn: '',
      attachment: {
        url: 'https://xhrm-5alx-lhb1.n7.xano.io/vault/cH9onT_o/qyqxaGytIQxntnfr65FoASTX3Xs/7isUJg../file-627f88.jpeg',
        meta: { width: 1024, height: 512 },
        mime: 'image/jpeg',
        name: 'file-627f88.jpeg',
        path: '/vault/cH9onT_o/qyqxaGytIQxntnfr65FoASTX3Xs/7isUJg../file-627f88.jpeg',
        size: 207118,
        type: 'image',
        access: 'public',
      },
      created_at: 1725373048382,
    },
    business_id: 13,
    for_explore: true,
    addresses_id: null,
    company_name: 'FashionHub',
    for_category: false,
    coverage_type: 'global',
    redirect_link: 'https://fashionhub.com/winter-collection',
    amount_to_user: 5,
    details_images: [
      {
        id: 141,
        user_id: 77,
        adobe_urn: '',
        attachment: {
          url: 'https://xhrm-5alx-lhb1.n7.xano.io/vault/cH9onT_o/Vj-mtyPrxUy5ITrp5Ce9oDVRKUE/wsje7Q../file-d9a94e.mp4',
          meta: {
            video: {
              dar: false,
              fps: 30,
              par: false,
              res: '1080x1080',
              codec: 'h264 (High) (avc1 / 0x31637661)',
              bitrate: 274000,
              profile: 'yuv420p(tv',
              postWidth: 1080,
              postHeight: 1080,
            },
            duration: 4,
          },
          mime: 'video/mp4',
          name: 'file-d9a94e.mp4',
          path: '/vault/cH9onT_o/Vj-mtyPrxUy5ITrp5Ce9oDVRKUE/wsje7Q../file-d9a94e.mp4',
          size: 142786,
          type: 'video',
          access: 'public',
        },
        created_at: 1725373496005,
      },
    ],
    remaining_budget: 4000,
    amount_to_mailboxx: 5,
    _business_categories: {
      id: 1,
      name: 'Clothing',
      order: 1,
      created_at: 1692966559753,
    },
    _is_consumed_by_user: null,
    _saved_promotion_by_user: false,
  },
};

const ReelsPlayerBottomOverlayBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const navigation = useNavigation();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const segment = useAnalytics();
  const [currentPromotion, setCurrentPromotion] = React.useState({});
  const [toggleContent, setToggleContent] = React.useState(false);
  React.useEffect(() => {
    try {
      setCurrentPromotion(props.promotion ?? defaultProps.promotion);
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
    }
  }, []);

  return (
    <LinearGradient
      startY={0}
      {...GlobalStyles.LinearGradientStyles(theme)['Linear Gradient'].props}
      color1={'rgba(0, 0, 0, 0)'}
      color2={palettes.App.TransparentBlack}
      endX={50}
      endY={80}
      startX={50}
      style={StyleSheet.applyWidth(
        StyleSheet.compose(
          GlobalStyles.LinearGradientStyles(theme)['Linear Gradient'].style,
          {
            bottom: 0,
            paddingBottom: 120,
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
            position: 'absolute',
            width: '100%',
            zIndex: 2,
          }
        ),
        dimensions.width
      )}
    >
      {/* InformationRow */}
      <View
        style={StyleSheet.applyWidth(
          { alignItems: 'center', flexDirection: 'row' },
          dimensions.width
        )}
      >
        <Pressable
          onPress={() => {
            try {
              navigation.navigate('BusinessProfileScreen', {
                business_id: (props.promotion ?? defaultProps.promotion)
                  ?.business_id,
              });
            } catch (err) {
              Sentry.captureException(err);
              console.error(err);
            }
          }}
        >
          <Circle
            {...GlobalStyles.CircleStyles(theme)['Circle'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.CircleStyles(theme)['Circle'].style,
                {
                  backgroundColor: palettes.App.White,
                  height: 40,
                  marginRight: 8,
                  overflow: 'hidden',
                  width: 40,
                }
              ),
              dimensions.width
            )}
          >
            <Image
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              resizeMode={'contain'}
              source={{ uri: `${currentPromotion?.logo?.attachment?.url}` }}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  { height: '100%', width: '100%' }
                ),
                dimensions.width
              )}
            />
          </Circle>
        </Pressable>
        <Link
          accessible={true}
          onPress={() => {
            try {
              navigation.navigate('BusinessProfileScreen', {
                business_id: (props.promotion ?? defaultProps.promotion)
                  ?.business_id,
              });
            } catch (err) {
              Sentry.captureException(err);
              console.error(err);
            }
          }}
          title={'Lorem ipsum dolor sit amet'}
          {...GlobalStyles.LinkStyles(theme)['Link'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'].style, {
              color: palettes.App.White,
              fontFamily: 'Poppins_500Medium',
            }),
            dimensions.width
          )}
        />
      </View>

      <View style={StyleSheet.applyWidth({ marginTop: 8 }, dimensions.width)}>
        <Pressable
          onPress={() => {
            try {
              trackAction(
                Variables,
                segment,
                'Clicked Content Text',
                (props.promotion ?? defaultProps.promotion)?.id
              );
              setToggleContent(!toggleContent);
              props.onToggle?.();
            } catch (err) {
              Sentry.captureException(err);
              console.error(err);
            }
          }}
        >
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                color: palettes.App.White,
                fontFamily: 'Poppins_500Medium',
              }),
              dimensions.width
            )}
          >
            {currentPromotion?.title}
          </Text>
          {/* ContentText */}
          <>
            {toggleContent ? null : (
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                numberOfLines={1}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: palettes.App.White,
                      fontFamily: 'Poppins_300Light',
                    }
                  ),
                  dimensions.width
                )}
              >
                {currentPromotion?.content}
              </Text>
            )}
          </>
        </Pressable>
        <>
          {!toggleContent ? null : (
            <SimpleStyleScrollView
              bounces={true}
              horizontal={false}
              keyboardShouldPersistTaps={'never'}
              nestedScrollEnabled={false}
              showsHorizontalScrollIndicator={true}
              showsVerticalScrollIndicator={true}
              style={StyleSheet.applyWidth(
                { marginRight: 35, maxHeight: 200 },
                dimensions.width
              )}
            >
              <Pressable
                onPress={() => {
                  try {
                    setToggleContent(false);
                    props.onToggle?.();
                  } catch (err) {
                    Sentry.captureException(err);
                    console.error(err);
                  }
                }}
              >
                {/* ContentText */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App.White,
                        fontFamily: 'Poppins_300Light',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {currentPromotion?.content}
                </Text>
              </Pressable>
            </SimpleStyleScrollView>
          )}
        </>
      </View>
    </LinearGradient>
  );
};

export default withTheme(ReelsPlayerBottomOverlayBlock);
