import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import {
  encodeQueryParam,
  renderParam,
  renderQueryString,
} from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

const cleanHeaders = headers =>
  Object.fromEntries(Object.entries(headers).filter(kv => kv[1] != null));

export const addAddressPOST = async (
  Constants,
  { city, country, house_number, lat, lng, road, state, title, zip },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/addresses`;
  const options = {
    body: JSON.stringify({
      title: title,
      zip: zip,
      road: road,
      house_number: house_number,
      city: city,
      state: state,
      country: country,
      coords: { type: 'point', data: { lat: lat, lng: lng } },
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useAddAddressPOST = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => addAddressPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('address', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('address');
        queryClient.invalidateQueries('addresses');
      },
    }
  );
};

export const FetchAddAddressPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  city,
  country,
  house_number,
  lat,
  lng,
  road,
  state,
  title,
  zip,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useAddAddressPOST(
    { city, country, house_number, lat, lng, road, state, title, zip },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchAddAddress: refetch });
};

export const addBankAccountsPOST = async (
  Constants,
  { accounts },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/bank_accounts`;
  const options = {
    body: JSON.stringify({ accounts: accounts }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useAddBankAccountsPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      addBankAccountsPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('bank_accounts', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('bank_account');
        queryClient.invalidateQueries('bank_accounts');
      },
    }
  );
};

export const FetchAddBankAccountsPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  accounts,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useAddBankAccountsPOST(
    { accounts },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchAddBankAccounts: refetch });
};

export const adminStatsGET = async (Constants, _args, handlers = {}) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/admin_stats`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useAdminStatsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['stat', args],
    () => adminStatsGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['stats']),
    }
  );
};

export const FetchAdminStatsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useAdminStatsGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchAdminStats: refetch });
};

export const approveAddressesPOST = async (
  Constants,
  { ids },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/approve_addresses`;
  const options = {
    body: JSON.stringify({ ids: ids }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useApproveAddressesPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      approveAddressesPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('address', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('address');
        queryClient.invalidateQueries('addresses');
      },
    }
  );
};

export const FetchApproveAddressesPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  ids,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useApproveAddressesPOST(
    { ids },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchApproveAddresses: refetch });
};

export const businessSignupPOST = async (
  Constants,
  {
    account_image,
    business_categories_id,
    business_ein,
    business_name,
    email,
    first_name,
    header_image,
    last_name,
    password,
    phone,
    secondary_email,
  },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/auth/signup_business`;
  const options = {
    body: JSON.stringify({
      email: email,
      password: password,
      first_name: first_name,
      last_name: last_name,
      secondary_email: secondary_email,
      phone_number: phone,
      business_ein: business_ein,
      business_categories_id: business_categories_id,
      business_name: business_name,
      account_image: account_image,
      header_image: header_image,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useBusinessSignupPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      businessSignupPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('auth', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('auth');
        queryClient.invalidateQueries('auths');
      },
    }
  );
};

export const FetchBusinessSignupPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  account_image,
  business_categories_id,
  business_ein,
  business_name,
  email,
  first_name,
  header_image,
  last_name,
  password,
  phone,
  secondary_email,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useBusinessSignupPOST(
    {
      account_image,
      business_categories_id,
      business_ein,
      business_name,
      email,
      first_name,
      header_image,
      last_name,
      password,
      phone,
      secondary_email,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchBusinessSignup: refetch });
};

export const changePasswordPUT = async (
  Constants,
  { password },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/change_password`;
  const options = {
    body: JSON.stringify({ password: password }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
    method: 'PUT',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useChangePasswordPUT = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => changePasswordPUT(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('auth', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('auth');
        queryClient.invalidateQueries('auths');
      },
    }
  );
};

export const createBusinessCategoryPOST = async (
  Constants,
  { name },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/business_categories`;
  const options = {
    body: JSON.stringify({ name: name }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useCreateBusinessCategoryPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      createBusinessCategoryPOST(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('business_categories', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('business_category');
        queryClient.invalidateQueries('business_categories');
      },
    }
  );
};

export const FetchCreateBusinessCategoryPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  name,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateBusinessCategoryPOST(
    { name },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchCreateBusinessCategory: refetch,
  });
};

export const deleteAccountDELETE = async (
  Constants,
  { email, name, password },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/delete_account`;
  const options = {
    body: JSON.stringify({ email: email, password: password, name: name }),
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
    method: 'DELETE',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDeleteAccountDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deleteAccountDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('user', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('user');
        queryClient.invalidateQueries('users');
      },
    }
  );
};

export const deleteAddressDELETE = async (
  Constants,
  { address_id },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/addresses/${encodeQueryParam(
    address_id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
    method: 'DELETE',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDeleteAddressDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deleteAddressDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('address', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('address');
        queryClient.invalidateQueries('addresses');
      },
    }
  );
};

export const deleteBankAccountDELETE = async (
  Constants,
  { bank_account_id },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/bank_accounts/${encodeQueryParam(
    bank_account_id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
    method: 'DELETE',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDeleteBankAccountDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deleteBankAccountDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('bank_accounts', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('bank_account');
        queryClient.invalidateQueries('bank_accounts');
      },
    }
  );
};

export const deleteBusinessCategoryDELETE = async (
  Constants,
  { business_category_id },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/business_categories/${encodeQueryParam(
    business_category_id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
    method: 'DELETE',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDeleteBusinessCategoryDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deleteBusinessCategoryDELETE(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('business_categories', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('business_category');
        queryClient.invalidateQueries('business_categories');
      },
    }
  );
};

export const deleteUploadDELETE = async (
  Constants,
  { uploads_id },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/uploads/${encodeQueryParam(
    uploads_id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
    method: 'DELETE',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDeleteUploadDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deleteUploadDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('uploads', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('upload');
        queryClient.invalidateQueries('uploads');
      },
    }
  );
};

export const deleteUserDELETE = async (Constants, _args, handlers = {}) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/user`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
    method: 'DELETE',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDeleteUserDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => deleteUserDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('user', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('user');
        queryClient.invalidateQueries('users');
      },
    }
  );
};

export const forgetPasswordInitiatePOST = async (
  Constants,
  { email },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/forgot_password/initiate`;
  const options = {
    body: JSON.stringify({ email: email }),
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useForgetPasswordInitiatePOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      forgetPasswordInitiatePOST(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('auth', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('auth');
        queryClient.invalidateQueries('auths');
      },
    }
  );
};

export const FetchForgetPasswordInitiatePOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  email,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useForgetPasswordInitiatePOST(
    { email },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchForgetPasswordInitiate: refetch,
  });
};

export const forgotPasswordUpdatePOST = async (
  Constants,
  { password },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/forgot_password/update`;
  const options = {
    body: JSON.stringify({ password: password }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useForgotPasswordUpdatePOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      forgotPasswordUpdatePOST(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('auth', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('auth');
        queryClient.invalidateQueries('auths');
      },
    }
  );
};

export const FetchForgotPasswordUpdatePOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  password,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useForgotPasswordUpdatePOST(
    { password },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchForgotPasswordUpdate: refetch,
  });
};

export const forgotPasswordVerifyPOST = async (
  Constants,
  { code, email },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/forgot_password/verify`;
  const options = {
    body: JSON.stringify({ email: email, code: code }),
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useForgotPasswordVerifyPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      forgotPasswordVerifyPOST(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('auth', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('auth');
        queryClient.invalidateQueries('auths');
      },
    }
  );
};

export const FetchForgotPasswordVerifyPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  code,
  email,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useForgotPasswordVerifyPOST(
    { code, email },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchForgotPasswordVerify: refetch,
  });
};

export const getAddressesGET = async (Constants, _args, handlers = {}) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/addresses`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetAddressesGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['addresses', args],
    () => getAddressesGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetAddressesGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetAddressesGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetAddresses: refetch });
};

export const getApprovedZipCodesByCityGET = async (
  Constants,
  { city },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/approved_zipcode/city/${encodeQueryParam(
    city
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetApprovedZipCodesByCityGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['approved_zipcodes', args],
    () => getApprovedZipCodesByCityGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetApprovedZipCodesByCityGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  city,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetApprovedZipCodesByCityGET(
    { city },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetApprovedZipCodesByCity: refetch,
  });
};

export const getApprovedZipCodesPOST = async (
  Constants,
  { cities },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/approved_zipcode/cities/`;
  const options = {
    body: JSON.stringify({ cities: cities }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetApprovedZipCodesPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      getApprovedZipCodesPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('approved_zipcodes', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('approved_zipcode');
        queryClient.invalidateQueries('approved_zipcodes');
      },
    }
  );
};

export const FetchGetApprovedZipCodesPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  cities,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useGetApprovedZipCodesPOST(
    { cities },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetApprovedZipCodes: refetch,
  });
};

export const getBankAccountsGET = async (Constants, _args, handlers = {}) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/bank_accounts`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetBankAccountsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['bank_accounts', args],
    () => getBankAccountsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetBankAccountsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetBankAccountsGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetBankAccounts: refetch });
};

export const getBusinessProfileGET = async (
  Constants,
  { business_id },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/business/${encodeQueryParam(
    business_id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetBusinessProfileGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['business profile', args],
    () => getBusinessProfileGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['business profiles']),
    }
  );
};

export const FetchGetBusinessProfileGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  business_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetBusinessProfileGET(
    { business_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetBusinessProfile: refetch });
};

export const getBusinessesGET = async (
  Constants,
  { search },
  handlers = {}
) => {
  const paramsDict = {};
  if (search !== undefined) {
    paramsDict['search'] = renderParam(search);
  }
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/business${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetBusinessesGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['businesses', args],
    () => getBusinessesGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetBusinessesGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  search,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetBusinessesGET(
    { search },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetBusinesses: refetch });
};

export const getBusinessesByCategoryGET = async (
  Constants,
  { business_categories_id },
  handlers = {}
) => {
  const paramsDict = {};
  if (business_categories_id !== undefined) {
    paramsDict['business_categories_id'] = renderParam(business_categories_id);
  }
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/business_by_category${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetBusinessesByCategoryGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['businesses', args],
    () => getBusinessesByCategoryGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetBusinessesByCategoryGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  business_categories_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetBusinessesByCategoryGET(
    { business_categories_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetBusinessesByCategory: refetch,
  });
};

export const getBussinessCategoriesGET = async (
  Constants,
  { excludeEmpty },
  handlers = {}
) => {
  const paramsDict = {};
  if (excludeEmpty !== undefined) {
    paramsDict['exclude_empty'] = renderParam(excludeEmpty);
  }
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/business_categories${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetBussinessCategoriesGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['business_category', args],
    () => getBussinessCategoriesGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['business_categories']),
    }
  );
};

export const FetchGetBussinessCategoriesGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  excludeEmpty,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetBussinessCategoriesGET(
    { excludeEmpty },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetBussinessCategories: refetch,
  });
};

export const getConnectAccountGET = async (Constants, _args, handlers = {}) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/connect_accounts`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetConnectAccountGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['connect_account', args],
    () => getConnectAccountGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['connect_accounts']),
    }
  );
};

export const FetchGetConnectAccountGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetConnectAccountGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetConnectAccount: refetch });
};

export const getFollowingBusinessesGET = async (
  Constants,
  _args,
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/get_following_businesses`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetFollowingBusinessesGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoServiceGetFollowingBusinessesGET', args],
    () => getFollowingBusinessesGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries([
          'xanoServiceGetFollowingBusinessesGETS',
        ]),
    }
  );
};

export const FetchGetFollowingBusinessesGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetFollowingBusinessesGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetFollowingBusinesses: refetch,
  });
};

export const getPendingAddressesGET = async (
  Constants,
  { refresh },
  handlers = {}
) => {
  const paramsDict = {};
  if (refresh !== undefined) {
    paramsDict['refresh'] = renderParam(refresh);
  }
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/get_pending_addresses${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetPendingAddressesGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoServiceGetPendingAddressesGET', args],
    () => getPendingAddressesGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoServiceGetPendingAddressesGETS']),
    }
  );
};

export const FetchGetPendingAddressesGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refresh,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetPendingAddressesGET(
    { refresh },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetPendingAddresses: refetch,
  });
};

export const getPrimaryAddressGET = async (Constants, _args, handlers = {}) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/primary_address`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetPrimaryAddressGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['address', args],
    () => getPrimaryAddressGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['addresses']),
    }
  );
};

export const FetchGetPrimaryAddressGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetPrimaryAddressGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetPrimaryAddress: refetch });
};

export const getPromotionsNearbyPOST = async (
  Constants,
  { business_category_ids, lat, lng, page_size, query, radius },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/near_by/promotions`;
  const options = {
    body: JSON.stringify({
      center: { type: 'point', data: { lat: lat, lng: lng } },
      radius: radius,
      page_size: page_size,
      business_categories_ids: business_category_ids,
      query: query,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetPromotionsNearbyPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['NearbyPromotions', args],
    () => getPromotionsNearbyPOST(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetPromotionsNearbyPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  business_category_ids,
  lat,
  lng,
  page_size,
  query,
  radius,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetPromotionsNearbyPOST(
    { business_category_ids, lat, lng, page_size, query, radius },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetPromotionsNearby: refetch,
  });
};

export const getUploadsGET = async (Constants, { fileType }, handlers = {}) => {
  const paramsDict = {};
  if (fileType !== undefined) {
    paramsDict['fileType'] = renderParam(fileType);
  }
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/uploads${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetUploadsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['uploads', args],
    () => getUploadsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetUploadsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  fileType,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetUploadsGET(
    { fileType },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetUploads: refetch });
};

export const getUserGET = async (Constants, { id }, handlers = {}) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/user/${encodeQueryParam(
    id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetUserGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(['user', args], () => getUserGET(Constants, args, handlers), {
    refetchInterval,
    onSuccess: () => queryClient.invalidateQueries(['users']),
  });
};

export const FetchGetUserGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetUserGET(
    { id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetUser: refetch });
};

export const getUserInfoGET = async (Constants, _args, handlers = {}) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/auth/me`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetUserInfoGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['auth', args],
    () => getUserInfoGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['auths']),
    }
  );
};

export const FetchGetUserInfoGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetUserInfoGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetUserInfo: refetch });
};

export const getUsersNearbyPOST = async (
  Constants,
  { genders, lat, lng, max_age, min_age, radius, zip_codes },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/near_by/users`;
  const options = {
    body: JSON.stringify({
      zip_codes: zip_codes,
      center: { type: 'point', data: { lng: lng, lat: lat } },
      radius: radius,
      genders: genders,
      age_range: [min_age, max_age],
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetUsersNearbyPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoServiceGetUsersNearbyPOST', args],
    () => getUsersNearbyPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoServiceGetUsersNearbyPOSTS']),
    }
  );
};

export const FetchGetUsersNearbyPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  genders,
  lat,
  lng,
  max_age,
  min_age,
  radius,
  zip_codes,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetUsersNearbyPOST(
    { genders, lat, lng, max_age, min_age, radius, zip_codes },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetUsersNearby: refetch });
};

export const toggleFollowGET = async (
  Constants,
  { business_id },
  handlers = {}
) => {
  const paramsDict = {};
  paramsDict[''] = '';
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/toggle_follow_business/${encodeQueryParam(
    business_id
  )}${renderQueryString(paramsDict)}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useToggleFollowGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoServiceToggleFollowGET', args],
    () => toggleFollowGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoServiceToggleFollowGETS']),
    }
  );
};

export const FetchToggleFollowGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  business_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useToggleFollowGET(
    { business_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchToggleFollow: refetch });
};

export const togglePushTokenPOST = async (
  Constants,
  { push_token },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/toggle_notification`;
  const options = {
    body: JSON.stringify({ push_token: push_token }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useTogglePushTokenPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      togglePushTokenPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('user', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('user');
        queryClient.invalidateQueries('users');
      },
    }
  );
};

export const FetchTogglePushTokenPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  push_token,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useTogglePushTokenPOST(
    { push_token },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchTogglePushToken: refetch });
};

export const updateBusinessCategoryNamePOST = async (
  Constants,
  { business_category_id, name },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/business_categories/${encodeQueryParam(
    business_category_id
  )}`;
  const options = {
    body: JSON.stringify({ name: name }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUpdateBusinessCategoryNamePOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      updateBusinessCategoryNamePOST(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('business_categories', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('business_category');
        queryClient.invalidateQueries('business_categories');
      },
    }
  );
};

export const FetchUpdateBusinessCategoryNamePOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  business_category_id,
  name,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useUpdateBusinessCategoryNamePOST(
    { business_category_id, name },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchUpdateBusinessCategoryName: refetch,
  });
};

export const updateBusinessProfilePUT = async (
  Constants,
  {
    account_image,
    business_categories_id,
    business_name,
    ein_number,
    first_name,
    header_image,
    last_name,
  },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/update_profile_business`;
  const options = {
    body: JSON.stringify({
      first_name: first_name,
      last_name: last_name,
      account_image: account_image,
      header_image: header_image,
      business_name: business_name,
      ein_number: ein_number,
      business_categories_id: business_categories_id,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
    method: 'PUT',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUpdateBusinessProfilePUT = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      updateBusinessProfilePUT(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('user', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('user');
        queryClient.invalidateQueries('users');
      },
    }
  );
};

export const updatePrimaryAddressPOST = async (
  Constants,
  { address_id },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/primary_address`;
  const options = {
    body: JSON.stringify({ address_id: address_id }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUpdatePrimaryAddressPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      updatePrimaryAddressPOST(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('address', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('address');
        queryClient.invalidateQueries('addresses');
      },
    }
  );
};

export const FetchUpdatePrimaryAddressPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  address_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useUpdatePrimaryAddressPOST(
    { address_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchUpdatePrimaryAddress: refetch,
  });
};

export const updateUploadPOST = async (
  Constants,
  { file, uploadsId, urn },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/uploads/${encodeQueryParam(
    uploadsId
  )}`;
  const options = {
    body: JSON.stringify({ file: file, urn: urn }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUpdateUploadPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => updateUploadPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('uploads', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('upload');
        queryClient.invalidateQueries('uploads');
      },
    }
  );
};

export const FetchUpdateUploadPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  file,
  uploadsId,
  urn,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useUpdateUploadPOST(
    { file, uploadsId, urn },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchUpdateUpload: refetch });
};

export const updateUserProfilePUT = async (
  Constants,
  { account_image, first_name, last_name },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/update_user`;
  const options = {
    body: JSON.stringify({
      first_name: first_name,
      last_name: last_name,
      account_image: account_image,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
    method: 'PUT',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUpdateUserProfilePUT = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      updateUserProfilePUT(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('user', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('user');
        queryClient.invalidateQueries('users');
      },
    }
  );
};

export const uploadAddressApprovalPOST = async (
  Constants,
  { addresses_id, approval_image },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/upload_address_approval`;
  const options = {
    body: JSON.stringify({
      addresses_id: addresses_id,
      approval_image: approval_image,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUploadAddressApprovalPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      uploadAddressApprovalPOST(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('address', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('address');
        queryClient.invalidateQueries('addresses');
      },
    }
  );
};

export const FetchUploadAddressApprovalPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  addresses_id,
  approval_image,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useUploadAddressApprovalPOST(
    { addresses_id, approval_image },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchUploadAddressApproval: refetch,
  });
};

export const uploadFilePOST = async (
  Constants,
  { file, urn },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/uploads`;
  const options = {
    body: JSON.stringify({ file: file, urn: urn }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUploadFilePOST = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => uploadFilePOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('uploads', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('upload');
        queryClient.invalidateQueries('uploads');
      },
    }
  );
};

export const FetchUploadFilePOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  file,
  urn,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useUploadFilePOST(
    { file, urn },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchUploadFile: refetch });
};

export const userSignupPOST = async (
  Constants,
  {
    account_image,
    dob,
    email,
    first_name,
    gender,
    last_name,
    password,
    phone,
    secondary_email,
  },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/auth/signup`;
  const options = {
    body: JSON.stringify({
      email: email,
      password: password,
      first_name: first_name,
      last_name: last_name,
      secondary_email: secondary_email,
      phone_number: phone,
      gender: gender,
      dob: dob,
      account_image: account_image,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUserSignupPOST = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => userSignupPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('auth', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('auth');
        queryClient.invalidateQueries('auths');
      },
    }
  );
};

export const FetchUserSignupPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  account_image,
  dob,
  email,
  first_name,
  gender,
  last_name,
  password,
  phone,
  secondary_email,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useUserSignupPOST(
    {
      account_image,
      dob,
      email,
      first_name,
      gender,
      last_name,
      password,
      phone,
      secondary_email,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchUserSignup: refetch });
};

export const loginPOST = async (
  Constants,
  { email, password },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/auth/login`;
  const options = {
    body: JSON.stringify({ email: email, password: password }),
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useLoginPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoServiceLoginPOST', args],
    () => loginPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['xanoServiceLoginPOSTS']),
    }
  );
};

export const FetchLoginPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  email,
  password,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useLoginPOST(
    { email, password },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchLogin: refetch });
};

export const redeemedGiftPOST = async (
  Constants,
  { amount, name, userId },
  handlers = {}
) => {
  const url = `https://xhrm-5alx-lhb1.n7.xano.io/api:oEazLGQ-/redeemed_gift_card`;
  const options = {
    body: JSON.stringify({ user_id: userId, amount: amount, name: name }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['DATASOURCE'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useRedeemedGiftPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => redeemedGiftPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('promotion', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('promotion');
        queryClient.invalidateQueries('promotions');
      },
    }
  );
};

export const FetchRedeemedGiftPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  amount,
  name,
  userId,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useRedeemedGiftPOST(
    { amount, name, userId },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchRedeemedGift: refetch });
};
