import React from 'react';
import { MapCircle, MapMarker, MapView } from '@draftbit/maps';
import {
  Button,
  Checkbox,
  DatePicker,
  Icon,
  IconButton,
  LinearProgress,
  NumberInput,
  Picker,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleKeyboardAwareScrollView,
  SimpleStyleScrollView,
  Slider,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import { ActivityIndicator, Image, Modal, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BalanceServiceApi from '../apis/BalanceServiceApi.js';
import * as PromotionsServiceApi from '../apis/PromotionsServiceApi.js';
import * as XanoServiceApi from '../apis/XanoServiceApi.js';
import PromotionAssetTypePickerBlock from '../components/PromotionAssetTypePickerBlock';
import WideHeaderBlock from '../components/WideHeaderBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as ImageGallery from '../custom-files/ImageGallery';
import centToDollar from '../global-functions/centToDollar';
import divide from '../global-functions/divide';
import dollarToCent from '../global-functions/dollarToCent';
import formatDate from '../global-functions/formatDate';
import getPercent from '../global-functions/getPercent';
import includes from '../global-functions/includes';
import isLoggedIn from '../global-functions/isLoggedIn';
import trackAction from '../global-functions/trackAction';
import trackScreen from '../global-functions/trackScreen';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';
import waitUtil from '../utils/wait';

const defaultProps = {
  for_category: true,
  for_explore: false,
  promoType: 'global',
  selectedAddress: null,
};

const AddPromotionFormScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const segment = useAnalytics();
  const getNDaysFromNow = n => {
    var date = new Date();
    date.setDate(date.getDate() + n);
    return date;
  };
  const [allZipCodes, setAllZipCodes] = React.useState([]);
  const [amountToMailboxx, setAmountToMailboxx] = React.useState(0);
  const [amountToUser, setAmountToUser] = React.useState(0);
  const [availableBalance, setAvailableBalance] = React.useState(0);
  const [budgetValue, setBudgetValue] = React.useState(500);
  const [businessCategoryValue, setBusinessCategoryValue] = React.useState('');
  const [contentValue, setContentValue] = React.useState('');
  const [detailImages, setDetailImages] = React.useState([]);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [expiryDateValue, setExpiryDateValue] = React.useState(
    getNDaysFromNow(10)
  );
  const [heroImage, setHeroImage] = React.useState({});
  const [isPosting, setIsPosting] = React.useState(false);
  const [maxAgeValue, setMaxAgeValue] = React.useState(65);
  const [maxUserInRegion, setMaxUserInRegion] = React.useState([]);
  const [minAgeValue, setMinAgeValue] = React.useState(0);
  const [pricePerClickValue, setPricePerClickValue] = React.useState(100);
  const [progress, setProgress] = React.useState(25);
  const [promotionAssetType, setPromotionAssetType] = React.useState('');
  const [promotionType, setPromotionType] = React.useState('');
  const [radiusValue, setRadiusValue] = React.useState(25);
  const [redirectLinkValue, setRedirectLinkValue] = React.useState('https://');
  const [selectedGenders, setSelectedGenders] = React.useState([
    'male',
    'female',
    'other',
    'prefer-not-to-say',
  ]);
  const [selectedZipCodes, setSelectedZipCodes] = React.useState([]);
  const [showCircle, setShowCircle] = React.useState(true);
  const [showModal, setShowModal] = React.useState(false);
  const [step, setStep] = React.useState('address');
  const [titleValue, setTitleValue] = React.useState('');
  const maxPossible = (number, divider) => {
    // Find the remainder of the number when divided by the divider
    const remainder = number % divider;
    // Subtract the remainder from the original number to get the largest number divisible by the divider
    const result = number - remainder;
    return result;
  };

  const getNMonthsFromNow = n => {
    var date = new Date();
    date.setMonth(date.getMonth() + n);
    return date;
  };

  const toggleZipCode = id => {
    if (selectedZipCodes.includes(id)) {
      setSelectedZipCodes(prev => prev.filter(p => p !== id));
    } else {
      setSelectedZipCodes(prev => [...prev, id]);
    }
  };

  const goTo = selectedStep => {
    setStep(selectedStep);
    if (selectedStep === 'address') setProgress(25);

    if (selectedStep === 'assetType') setProgress(50);

    if (selectedStep === 'form') setProgress(75);
  };

  const isAllSelected = () => {
    return (
      allZipCodes.length === selectedZipCodes.length && allZipCodes.length > 0
    );
  };

  // Function to convert miles to meters
  const milesToMeters = miles => {
    return Math.floor(miles * 1609.34);
  };

  const toggleCircle = () => {
    setShowCircle(false);

    setTimeout(() => {
      setShowCircle(true);
    }, 100);
  };

  const estimateClicks = () => {
    const pricePerClick = amountToUser + amountToMailboxx; //1.1:2.2
    const clickTrashold = promotionAssetType === 'image' ? 0.5 : 1;
    //budgetValue is the budget that user entered : eg 1300

    if (maxUserInRegion > 0) {
      const estimatedClicks = Math.floor((budgetValue * 100) / pricePerClick);
      const estimatedImpressions = Math.floor(estimatedClicks / clickTrashold);
      return Math.round(estimatedImpressions);
    } else {
      return Math.round((budgetValue * 100) / pricePerClick);
    }
  };

  const transformBusinessCategories = arr => {
    return arr.map(a => {
      return {
        label: a?.name || '',
        value: a?.id || '',
      };
    });
  };

  const toggleItem = (arr, item) => {
    if (arr.includes(item)) return arr.filter(i => i !== item);
    else return [...arr, item];
  };

  const extractIds = arr => {
    console.log(arr);

    if (Array.isArray(arr)) return (arr || []).map(i => i.id);
    else if (arr.id) return [arr.id];
  };

  const arrayOfCities = () => {
    if ((props.route?.params?.selectedAddress ?? null)?.city)
      return [(props.route?.params?.selectedAddress ?? null).city];

    return [];
  };

  const validateForm = () => {
    if (titleValue == '') {
      setErrorMessage('Please enter a title');
      return false;
    }

    if (contentValue == '') {
      setErrorMessage('Please enter a content');
      return false;
    }

    if (redirectLinkValue == '') {
      setErrorMessage('Please enter a redirect link');
      return false;
    }

    if (/^(http|https):\/\/[^ "]+$/.test(redirectLinkValue) == false) {
      setErrorMessage('Please enter link in https://www.example.com format');
      return false;
    }
    //check if expiry date is in the future

    if (new Date(expiryDateValue) <= new Date()) {
      setErrorMessage('Please select a future date');
      return false;
    }

    if (!heroImage?.id) {
      setErrorMessage('Please select a banner image');
      return false;
    }

    if (detailImages.length === 0) {
      setErrorMessage('Please select at least one detail image or video');
      return false;
    }

    if (showModal && budgetValue * 100 > availableBalance) {
      setErrorMessage(
        `You don't have enough balance. The maximum budget you can set is ${
          availableBalance / 100
        }`
      );
      return false;
    }

    setErrorMessage('');
    return true;
  };

  const setAmountsToValues = () => {
    if (titleValue == '') {
      setErrorMessage('Please enter a title');
      return false;
    }

    if (contentValue == '') {
      setErrorMessage('Please enter a content');
      return false;
    }

    if (redirectLinkValue == '') {
      setErrorMessage('Please enter a redirect link');
      return false;
    }

    if (/^(http|https):\/\/[^ "]+$/.test(redirectLinkValue) == false) {
      setErrorMessage('Please enter link in https://www.example.com format');
      return false;
    }
    //check if expiry date is in the future

    if (new Date(expiryDateValue) <= new Date()) {
      setErrorMessage('Please select a future date');
      return false;
    }

    if (!heroImage?.id) {
      setErrorMessage('Please select a banner image');
      return false;
    }

    if (detailImages.length === 0) {
      setErrorMessage('Please select at least one detail image or video');
      return false;
    }

    if (showModal && budgetValue * 100 > availableBalance) {
      setErrorMessage(
        `You don't have enough balance. The maximum budget you can set is ${
          availableBalance / 100
        }`
      );
      return false;
    }

    setErrorMessage('');
    return true;
  };

  const onPromotionAssetTypeSelect = promotionAssetType => {
    setPromotionAssetType(promotionAssetType);

    //If an Inbox promotion
    if (['zip', 'gps', 'global'].includes(promotionType)) {
      setAmountToUser(promotionAssetType === 'image' ? 50 : 100);
      setAmountToMailboxx(10);
    }

    if (props.route?.params?.for_explore ?? false) {
      setAmountToUser(promotionAssetType === 'image' ? 5 : 5); // both image and video will pay 5 cent to user
      setAmountToMailboxx(5); // mailboxx will get 5 cents for both image and video
    }

    if (props.route?.params?.for_category ?? false) {
      setAmountToUser(0); // no price will be paid to user per click
      setAmountToMailboxx(0); // no comission will be taken per click
    }

    setErrorMessage('');
    goTo('form');
  };
  const promotionsServiceAddPromotionPOST =
    PromotionsServiceApi.useAddPromotionPOST();
  const promotionsServiceAddGlobalPromotionPOST =
    PromotionsServiceApi.useAddGlobalPromotionPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (!isLoggedIn(Variables)) {
        navigation.navigate('AuthLoginScreen');
      }
      if (Constants['USER_DATA']?.role === 'user') {
        navigation.navigate('AuthLoginScreen');
      }
      trackScreen(segment, 'Add Promotion Form');
      setPromotionType(
        props.route?.params?.promoType ?? defaultProps.promoType
      );
      setBusinessCategoryValue(Constants['USER_DATA']?.business_categories_id);
      if (step === 'form') {
        return;
      }
      goTo('assetType');
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer scrollable={false} hasSafeArea={true}>
      {/* Fetch 2 */}
      <BalanceServiceApi.FetchGetBalanceGET
        handlers={{
          onData: fetch2Data => {
            try {
              setAvailableBalance(fetch2Data?.balance);
              if (
                props.route?.params?.for_category ??
                defaultProps.for_category
              ) {
                setBudgetValue(5);
                setExpiryDateValue(getNMonthsFromNow(1));
              } else {
                setBudgetValue(getPercent(fetch2Data?.balance, 1));
              }
            } catch (err) {
              Sentry.captureException(err);
              console.error(err);
            }
          },
        }}
      >
        {({ loading, error, data, refetchGetBalance }) => {
          const fetch2Data = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <WideHeaderBlock
                  balance={fetch2Data?.balance}
                  currentScreen={'mypromotions'}
                  withGoBack={true}
                />
              )}
            </>
          );
        }}
      </BalanceServiceApi.FetchGetBalanceGET>
      {/* Header */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            {...GlobalStyles.ViewStyles(theme)['Header'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['Header'].style,
              dimensions.width
            )}
          >
            {/* AbsoluteTitle */}
            <View
              {...GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].style,
                dimensions.width
              )}
            >
              <Image
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                resizeMode={'contain'}
                source={imageSource(Images['MailboxxGreenLogo'])}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { height: 30, width: 120 }
                  ),
                  dimensions.width
                )}
              />
            </View>
            {/* BackButton */}
            <Pressable
              onPress={() => {
                try {
                  if (progress === 50) {
                    navigation.goBack();
                  } else {
                    if (progress === 75) {
                      goTo('assetType');
                    } else {
                    }
                  }
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              {...GlobalStyles.PressableStyles(theme)['BackButton'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.PressableStyles(theme)['BackButton'].style,
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={palettes.App['Custom Color_9']}
                  name={'Ionicons/chevron-back'}
                  size={28}
                />
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['HeaderTitleText'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['HeaderTitleText'].style,
                    dimensions.width
                  )}
                >
                  {'Back'}
                </Text>
              </View>
            </Pressable>
            {/* BalanceButton */}
            <Pressable
              onPress={() => {
                try {
                  trackAction(
                    Variables,
                    segment,
                    'Clicked Balance Button',
                    undefined
                  );
                  console.log('csdfsdfs');
                  navigation.navigate('BalanceScreen');
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                { marginRight: 10 },
                dimensions.width
              )}
            >
              <BalanceServiceApi.FetchGetBalanceGET
                handlers={{
                  onData: fetchData => {
                    try {
                      setAvailableBalance(fetchData?.balance);
                      if (
                        props.route?.params?.for_category ??
                        defaultProps.for_category
                      ) {
                        setBudgetValue(5);
                        setExpiryDateValue(getNMonthsFromNow(1));
                      } else {
                        setBudgetValue(getPercent(fetchData?.balance, 1));
                      }
                    } catch (err) {
                      Sentry.captureException(err);
                      console.error(err);
                    }
                  },
                }}
              >
                {({ loading, error, data, refetchGetBalance }) => {
                  const fetchData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={theme.colors.text.strong}
                        name={'Entypo/wallet'}
                        size={16}
                        style={StyleSheet.applyWidth(
                          { marginRight: 4 },
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['HeaderTitleText']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['HeaderTitleText']
                              .style,
                            { color: theme.colors.text.strong, fontSize: 14 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'$'}
                        {centToDollar(fetchData?.balance)}
                      </Text>
                    </View>
                  );
                }}
              </BalanceServiceApi.FetchGetBalanceGET>
            </Pressable>
          </View>
        )}
      </>
      <LinearProgress
        animationDuration={500}
        color={theme.colors.branding.primary}
        indeterminate={false}
        isAnimated={true}
        showTrack={true}
        thickness={10}
        trackColor={theme.colors.border.brand}
        lineCap={'square'}
        trackLineCap={'square'}
        value={progress}
      />
      {/* Body */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            backgroundColor: palettes.App.CustomGray,
            flex: 1,
          },
          dimensions.width
        )}
      >
        {/* InnerBody */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: theme.colors.background.brand,
              flex: 1,
              maxWidth: 1024,
              width: '100%',
            },
            dimensions.width
          )}
        >
          {/* PromotionAssetTypePicker 2 */}
          <>
            {!(step === 'assetType') ? null : (
              <PromotionAssetTypePickerBlock
                onSelect={promotionAssetType => {
                  try {
                    console.log(promotionAssetType);
                    onPromotionAssetTypeSelect(promotionAssetType);
                  } catch (err) {
                    Sentry.captureException(err);
                    console.error(err);
                  }
                }}
                imagePerClickPrice={
                  props.route?.params?.for_category ?? defaultProps.for_category
                    ? ''
                    : props.route?.params?.for_explore ??
                      defaultProps.for_explore
                    ? '0.10'
                    : '0.60'
                }
                title={
                  props.route?.params?.for_category ?? defaultProps.for_category
                    ? 'Category Promotion:  You will be charged 5$/month for listing your promotion under a business category. The users will not get paid for reading your promotion. You will not be charged extra.'
                    : undefined
                }
                videoPerClickPrice={
                  props.route?.params?.for_category ?? defaultProps.for_category
                    ? ''
                    : props.route?.params?.for_explore ??
                      defaultProps.for_explore
                    ? '0.10'
                    : '1.10'
                }
              />
            )}
          </>
          {/* Form */}
          <>
            {!(step === 'form') ? null : (
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, padding: 20 },
                  dimensions.width
                )}
              >
                <SimpleStyleKeyboardAwareScrollView
                  enableAutomaticScroll={false}
                  enableOnAndroid={false}
                  enableResetScrollToCoords={false}
                  keyboardShouldPersistTaps={'never'}
                  showsVerticalScrollIndicator={true}
                  viewIsInsideTabBar={false}
                >
                  {/* Logo */}
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', marginTop: 10 },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: palettes.App.CustomGray,
                          borderRadius: 8,
                          flexDirection: 'row',
                          height: 70,
                          justifyContent: 'center',
                          width: 70,
                        },
                        dimensions.width
                      )}
                    >
                      <Image
                        resizeMode={'cover'}
                        {...GlobalStyles.ImageStyles(theme)['Image'].props}
                        source={{
                          uri: `${Constants['USER_DATA']?.account_image?.attachment?.url}`,
                        }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ImageStyles(theme)['Image'].style,
                            { borderRadius: 8, height: 70, width: 70 }
                          ),
                          dimensions.width
                        )}
                      />
                    </View>
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderColor: theme.colors.border.brand,
                        borderRadius: 8,
                        borderWidth: 1,
                        marginTop: 12,
                        padding: 4,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        dimensions.width
                      )}
                    >
                      {
                        'Promotion Category: Please select the category where you want to display your promotion.'
                      }
                    </Text>

                    <XanoServiceApi.FetchGetBussinessCategoriesGET
                      excludeEmpty={false}
                    >
                      {({
                        loading,
                        error,
                        data,
                        refetchGetBussinessCategories,
                      }) => {
                        const fetchData = data?.json;
                        if (loading) {
                          return <ActivityIndicator />;
                        }

                        if (
                          error ||
                          data?.status < 200 ||
                          data?.status >= 300
                        ) {
                          return <ActivityIndicator />;
                        }

                        return (
                          <View>
                            {/* BusinessCategoryPicker */}
                            <Picker
                              autoDismissKeyboard={true}
                              dropDownBackgroundColor={
                                theme.colors.background.brand
                              }
                              dropDownBorderColor={theme.colors.border.brand}
                              dropDownBorderRadius={8}
                              dropDownBorderWidth={1}
                              dropDownTextColor={theme.colors.text.strong}
                              iconSize={24}
                              leftIconMode={'inset'}
                              mode={'native'}
                              onValueChange={newBusinessCategoryPickerValue => {
                                try {
                                  setBusinessCategoryValue(
                                    newBusinessCategoryPickerValue
                                  );
                                } catch (err) {
                                  Sentry.captureException(err);
                                  console.error(err);
                                }
                              }}
                              selectedIconColor={theme.colors.text.strong}
                              selectedIconName={'Feather/check'}
                              selectedIconSize={20}
                              options={transformBusinessCategories(fetchData)}
                              placeholder={'Business Category'}
                              placeholderTextColor={theme.colors.text.light}
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor: palettes.App.CustomGray,
                                  borderRadius: 8,
                                  borderWidth: 0,
                                  fontFamily: 'Poppins_400Regular',
                                  fontSize: 13,
                                  padding: 8,
                                },
                                dimensions.width
                              )}
                              type={'underline'}
                              value={businessCategoryValue}
                            />
                          </View>
                        );
                      }}
                    </XanoServiceApi.FetchGetBussinessCategoriesGET>
                  </View>
                  {/* TitleInput */}
                  <TextInput
                    autoCapitalize={'none'}
                    autoCorrect={true}
                    changeTextDelay={500}
                    onChangeText={newTitleInputValue => {
                      try {
                        setTitleValue(newTitleInputValue);
                      } catch (err) {
                        Sentry.captureException(err);
                        console.error(err);
                      }
                    }}
                    webShowOutline={true}
                    {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
                    placeholder={'Title'}
                    placeholderTextColor={theme.colors.text.light}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                        { marginTop: 20 }
                      ),
                      dimensions.width
                    )}
                    value={titleValue}
                  />
                  {/* ContentTextarea */}
                  <TextInput
                    autoCorrect={true}
                    changeTextDelay={500}
                    multiline={true}
                    numberOfLines={4}
                    onChangeText={newContentTextareaValue => {
                      try {
                        setContentValue(newContentTextareaValue);
                      } catch (err) {
                        Sentry.captureException(err);
                        console.error(err);
                      }
                    }}
                    textAlignVertical={'top'}
                    webShowOutline={true}
                    {...GlobalStyles.TextInputStyles(theme)['Text Area'].props}
                    placeholder={'Content'}
                    placeholderTextColor={theme.colors.text.light}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextInputStyles(theme)['Text Area'].style,
                        { marginTop: 20, minHeight: 90 }
                      ),
                      dimensions.width
                    )}
                    value={contentValue}
                  />
                  {/* RedirectLinkInput */}
                  <TextInput
                    autoCapitalize={'none'}
                    autoCorrect={true}
                    changeTextDelay={500}
                    onChangeText={newRedirectLinkInputValue => {
                      try {
                        setRedirectLinkValue(newRedirectLinkInputValue);
                      } catch (err) {
                        Sentry.captureException(err);
                        console.error(err);
                      }
                    }}
                    webShowOutline={true}
                    {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
                    placeholder={'Redirect Link'}
                    placeholderTextColor={theme.colors.text.light}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                        { marginTop: 20 }
                      ),
                      dimensions.width
                    )}
                    value={redirectLinkValue}
                  />
                  {/* ExpirydatePicker */}
                  <>
                    {props.route?.params?.for_category ??
                    defaultProps.for_category ? null : (
                      <View
                        {...GlobalStyles.ViewStyles(theme)['DobView'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ViewStyles(theme)['DobView'].style,
                            { flex: 1, minHeight: 60 }
                          ),
                          dimensions.width
                        )}
                      >
                        <DatePicker
                          autoDismissKeyboard={true}
                          disabled={false}
                          hideLabel={false}
                          mode={'date'}
                          onDateChange={newDatePickerValue => {
                            try {
                              setExpiryDateValue(newDatePickerValue);
                            } catch (err) {
                              Sentry.captureException(err);
                              console.error(err);
                            }
                          }}
                          borderColorActive={'"rgba(0, 0, 0, 0)"'}
                          date={expiryDateValue}
                          label={'Expiration Date'}
                          labelColor={theme.colors.text.strong}
                          leftIconMode={'outset'}
                          minimumDate={getNDaysFromNow(10)}
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: palettes.App.CustomGray,
                              borderColor: '"rgba(0, 0, 0, 0)"',
                              fontFamily: 'Poppins_200ExtraLight',
                              marginTop: 8,
                            },
                            dimensions.width
                          )}
                          type={'underline'}
                        />
                      </View>
                    )}
                  </>
                  {/* HeroImage */}
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', marginTop: 10, width: 70 },
                      dimensions.width
                    )}
                  >
                    {/* ImagePicker */}
                    <Utils.CustomCodeErrorBoundary>
                      <ImageGallery.Picker
                        label="Banner"
                        file={heroImage}
                        setFile={setHeroImage}
                        fileType="image"
                        multi={false}
                        orientation={'banner'}
                      />
                    </Utils.CustomCodeErrorBoundary>
                  </View>
                  {/* DetailImage */}
                  <View
                    style={StyleSheet.applyWidth(
                      { minWidth: 70 },
                      dimensions.width
                    )}
                  >
                    {/* ImagePicker */}
                    <Utils.CustomCodeErrorBoundary>
                      <ImageGallery.Picker
                        label="Details"
                        file={detailImages}
                        setFile={setDetailImages}
                        fileType={promotionAssetType}
                        multi={promotionAssetType === 'image'}
                        orientation={
                          props.route?.params?.for_explore ?? true
                            ? 'portrait'
                            : ''
                        }
                      />
                    </Utils.CustomCodeErrorBoundary>
                  </View>
                </SimpleStyleKeyboardAwareScrollView>
                {/* errorText */}
                <>
                  {!errorMessage ? null : (
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: theme.colors.background.danger,
                            textAlign: 'center',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {errorMessage}
                    </Text>
                  )}
                </>
                {/* Buttons */}
                <View
                  style={StyleSheet.applyWidth(
                    { flexDirection: 'row', justifyContent: 'space-between' },
                    dimensions.width
                  )}
                >
                  {/* PreviewButton */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        trackAction(
                          Variables,
                          segment,
                          'Clicked Preview Button',
                          undefined
                        );
                        if (
                          props.route?.params?.for_explore ??
                          defaultProps.for_explore
                        ) {
                          navigation.navigate('PreviewExplorePromotionScreen', {
                            promoDiscount: 50,
                            subTitle: Constants['USER_DATA']?.business_name,
                            title: titleValue,
                            logo: Constants['USER_DATA']?.account_image,
                            content: contentValue,
                            details_images:
                              promotionAssetType === 'image'
                                ? detailImages
                                : [].concat([detailImages]),
                            asset_type: promotionAssetType,
                          });
                        } else {
                          navigation.navigate('PreviewPromotionScreen', {
                            promoDiscount: 50,
                            subTitle: Constants['USER_DATA']?.business_name,
                            title: titleValue,
                            content: contentValue,
                            logo: Constants['USER_DATA']?.account_image
                              ?.attachment?.url,
                            details_images:
                              promotionAssetType === 'image'
                                ? detailImages
                                : [].concat([detailImages]),
                          });
                        }
                      } catch (err) {
                        Sentry.captureException(err);
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        {
                          backgroundColor: theme.colors.branding.primary,
                          marginBottom: 10,
                          marginTop: 10,
                          width: '40%',
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Preview'}
                  />
                  {/* ProceedButton */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        if (!validateForm()) {
                          return;
                        }
                        setShowModal(true);
                        trackAction(
                          Variables,
                          segment,
                          'Clicked Proceed Button',
                          undefined
                        );
                      } catch (err) {
                        Sentry.captureException(err);
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        {
                          backgroundColor: theme.colors.branding.primary,
                          marginBottom: 10,
                          marginTop: 10,
                          width: '40%',
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Proceed'}
                  />
                </View>
              </View>
            )}
          </>
        </View>
      </View>

      <Modal
        supportedOrientations={['portrait', 'landscape']}
        transparent={false}
        animationType={'slide'}
        presentationStyle={'formSheet'}
        visible={showModal}
      >
        {/* Modal Container */}
        <View
          style={StyleSheet.applyWidth(
            { flex: 1, padding: 12 },
            dimensions.width
          )}
        >
          {/* Modal Header */}
          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 20,
              },
              dimensions.width
            )}
          >
            {/* SelectAllButton */}
            <>
              {!(promotionType === 'zip') ? null : (
                <Pressable
                  onPress={() => {
                    try {
                      if (!isAllSelected()) {
                        setSelectedZipCodes(allZipCodes);
                      }
                      if (isAllSelected()) {
                        setSelectedZipCodes([]);
                      }
                    } catch (err) {
                      Sentry.captureException(err);
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.PressableStyles(theme)['SelectAllButton']
                    .props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.PressableStyles(theme)['SelectAllButton']
                      .style,
                    dimensions.width
                  )}
                >
                  {/* SecondRow */}
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', flexDirection: 'row' },
                      dimensions.width
                    )}
                  >
                    <Checkbox defaultValue={isAllSelected()} disabled={true} />
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          { marginLeft: 8 }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Select All'}
                    </Text>
                  </View>
                </Pressable>
              )}
            </>
            <IconButton
              onPress={() => {
                try {
                  setShowModal(false);
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              size={32}
              color={theme.colors.branding.primary}
              icon={'Ionicons/close-circle-outline'}
            />
          </View>
          {/* Modal Body */}
          <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
            {/* GpsPromoView */}
            <>
              {!(
                promotionType === 'gps' &&
                !(
                  props.route?.params?.for_category ?? defaultProps.for_category
                )
              ) ? null : (
                <View
                  style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
                >
                  <>
                    {!showCircle ? null : (
                      <MapView
                        apiKey={'AIzaSyBzktToWosjNgrrUawZnbslB9NSXSXCkwo'}
                        autoClusterMarkers={false}
                        autoClusterMarkersDistanceMeters={10000}
                        customMapStyle={'Lorem ipsum dolor sit amet'}
                        loadingEnabled={true}
                        moveOnMarkerPress={true}
                        rotateEnabled={true}
                        scrollEnabled={true}
                        showsCompass={false}
                        showsPointsOfInterest={true}
                        showsUserLocation={false}
                        zoom={8}
                        zoomEnabled={true}
                        {...GlobalStyles.MapViewStyles(theme)['Map View'].props}
                        latitude={
                          (
                            props.route?.params?.selectedAddress ??
                            defaultProps.selectedAddress
                          )?.coords?.data?.lat
                        }
                        longitude={
                          (
                            props.route?.params?.selectedAddress ??
                            defaultProps.selectedAddress
                          )?.coords?.data?.lng
                        }
                        provider={'google'}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.MapViewStyles(theme)['Map View'].style,
                          dimensions.width
                        )}
                      >
                        <MapMarker
                          androidUseDefaultIconImplementation={false}
                          flat={false}
                          pinImageSize={50}
                          tracksViewChanges={true}
                          latitude={
                            (
                              props.route?.params?.selectedAddress ??
                              defaultProps.selectedAddress
                            )?.coords?.data?.lat
                          }
                          longitude={
                            (
                              props.route?.params?.selectedAddress ??
                              defaultProps.selectedAddress
                            )?.coords?.data?.lng
                          }
                          pinImage={imageSource(Images['Pngaaacom5421128'])}
                          title={
                            (
                              props.route?.params?.selectedAddress ??
                              defaultProps.selectedAddress
                            )?.title
                          }
                        />
                        <MapCircle
                          fillColor={theme.colors.branding.primary}
                          strokeColor={theme.colors.branding.primary}
                          latitude={
                            (
                              props.route?.params?.selectedAddress ??
                              defaultProps.selectedAddress
                            )?.coords?.data?.lat
                          }
                          longitude={
                            (
                              props.route?.params?.selectedAddress ??
                              defaultProps.selectedAddress
                            )?.coords?.data?.lng
                          }
                          radius={milesToMeters(radiusValue)}
                        />
                      </MapView>
                    )}
                  </>
                  {/* ConvertToGlobal */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        setPromotionType('global');
                      } catch (err) {
                        Sentry.captureException(err);
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      dimensions.width
                    )}
                    title={'Convert to National Promotion'}
                  />
                </View>
              )}
            </>
            {/* ZipPromoView */}
            <>
              {!(
                promotionType === 'zip' &&
                !(
                  props.route?.params?.for_category ?? defaultProps.for_category
                )
              ) ? null : (
                <View
                  style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
                >
                  <SimpleStyleScrollView
                    bounces={true}
                    horizontal={false}
                    keyboardShouldPersistTaps={'never'}
                    nestedScrollEnabled={false}
                    showsHorizontalScrollIndicator={true}
                    showsVerticalScrollIndicator={true}
                  >
                    {/* ApprovedZipCodes */}
                    <XanoServiceApi.FetchGetApprovedZipCodesPOST
                      cities={arrayOfCities()}
                      handlers={{
                        onData: approvedZipCodesData => {
                          try {
                            setAllZipCodes(extractIds(approvedZipCodesData));
                            setSelectedZipCodes(
                              extractIds(approvedZipCodesData)
                            );
                          } catch (err) {
                            Sentry.captureException(err);
                            console.error(err);
                          }
                        },
                      }}
                    >
                      {({
                        loading,
                        error,
                        data,
                        refetchGetApprovedZipCodes,
                      }) => {
                        const approvedZipCodesData = data?.json;
                        if (loading) {
                          return <ActivityIndicator />;
                        }

                        if (
                          error ||
                          data?.status < 200 ||
                          data?.status >= 300
                        ) {
                          return <ActivityIndicator />;
                        }

                        return (
                          <SimpleStyleFlatList
                            data={approvedZipCodesData}
                            horizontal={false}
                            inverted={false}
                            keyExtractor={(listData, index) =>
                              listData?.id ??
                              listData?.uuid ??
                              index?.toString() ??
                              JSON.stringify(listData)
                            }
                            keyboardShouldPersistTaps={'never'}
                            listKey={'ppstb9eN'}
                            nestedScrollEnabled={false}
                            numColumns={1}
                            onEndReachedThreshold={0.5}
                            renderItem={({ item, index }) => {
                              const listData = item;
                              return (
                                <Pressable
                                  onPress={() => {
                                    try {
                                      toggleZipCode(listData?.id);
                                    } catch (err) {
                                      Sentry.captureException(err);
                                      console.error(err);
                                    }
                                  }}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        marginBottom: 15,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Checkbox
                                      color={palettes.App.customGreen}
                                      defaultValue={includes(
                                        listData?.id,
                                        selectedZipCodes
                                      )}
                                      disabled={true}
                                      uncheckedColor={palettes.App.customGreen}
                                    />
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          { marginLeft: 16 }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.zip_code}
                                      {' - '}
                                      {listData?.city}
                                    </Text>
                                  </View>
                                </Pressable>
                              );
                            }}
                            showsHorizontalScrollIndicator={true}
                            showsVerticalScrollIndicator={true}
                          />
                        );
                      }}
                    </XanoServiceApi.FetchGetApprovedZipCodesPOST>
                  </SimpleStyleScrollView>
                  {/* ConvertToGlobal */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        trackAction(
                          Variables,
                          segment,
                          'Clicked Convert to National Promotion Button',
                          undefined
                        );
                        setPromotionType('global');
                      } catch (err) {
                        Sentry.captureException(err);
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      dimensions.width
                    )}
                    title={'Convert to National Promotion'}
                  />
                </View>
              )}
            </>
            {/* GlobalPromoView */}
            <>
              {!(promotionType === 'global') ? null : (
                <View>
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      dimensions.width
                    )}
                  >
                    {'This promotion will be distributed to all of our users'}
                  </Text>
                  <>
                    {!(
                      props.route?.params?.selectedAddress ??
                      defaultProps.selectedAddress
                    )?.id ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { flexDirection: 'row', margin: 8 },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { flex: 1, marginRight: 4 },
                            dimensions.width
                          )}
                        >
                          {/* ConvertToGps */}
                          <Button
                            iconPosition={'left'}
                            onPress={() => {
                              try {
                                trackAction(
                                  Variables,
                                  segment,
                                  'Clicked Convert to GPS Promotion Button',
                                  undefined
                                );
                                setPromotionType('gps');
                              } catch (err) {
                                Sentry.captureException(err);
                                console.error(err);
                              }
                            }}
                            {...GlobalStyles.ButtonStyles(theme)['Button']
                              .props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.ButtonStyles(theme)['Button'].style,
                              dimensions.width
                            )}
                            title={'Convert to GPS Promotion'}
                          />
                        </View>
                        {/* View 2 */}
                        <View
                          style={StyleSheet.applyWidth(
                            { flex: 1, marginLeft: 4 },
                            dimensions.width
                          )}
                        >
                          {/* ConvertToZip */}
                          <Button
                            iconPosition={'left'}
                            onPress={() => {
                              try {
                                trackAction(
                                  Variables,
                                  segment,
                                  'Clicked Convert to Zip Promotion Button',
                                  undefined
                                );
                                setPromotionType('zip');
                              } catch (err) {
                                Sentry.captureException(err);
                                console.error(err);
                              }
                            }}
                            {...GlobalStyles.ButtonStyles(theme)['Button']
                              .props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.ButtonStyles(theme)['Button'].style,
                              dimensions.width
                            )}
                            title={'Convert to Zip Promotion'}
                          />
                        </View>
                      </View>
                    )}
                  </>
                </View>
              )}
            </>
            {/* FormView */}
            <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
              <SimpleStyleKeyboardAwareScrollView
                enableAutomaticScroll={false}
                enableOnAndroid={false}
                enableResetScrollToCoords={false}
                keyboardShouldPersistTaps={'never'}
                showsVerticalScrollIndicator={true}
                viewIsInsideTabBar={false}
              >
                {/* FormView */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'flex-start',
                      flexDirection: 'column',
                      marginTop: 20,
                    },
                    dimensions.width
                  )}
                >
                  {/* RadiusView */}
                  <>
                    {!(
                      promotionType === 'gps' &&
                      !(
                        props.route?.params?.for_category ??
                        defaultProps.for_category
                      )
                    ) ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        {/* LabelView */}
                        <View
                          style={StyleSheet.applyWidth(
                            { flex: 0.25 },
                            dimensions.width
                          )}
                        >
                          {/* Label */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              dimensions.width
                            )}
                          >
                            {'Radius :'}
                          </Text>
                        </View>
                        {/* SliderView */}
                        <View
                          style={StyleSheet.applyWidth(
                            { flex: 0.55, flexDirection: 'row' },
                            dimensions.width
                          )}
                        >
                          <Slider
                            onValueChange={newSliderValue => {
                              try {
                                setRadiusValue(newSliderValue);
                                toggleCircle();
                              } catch (err) {
                                Sentry.captureException(err);
                                console.error(err);
                              }
                            }}
                            {...GlobalStyles.SliderStyles(theme)['Slider']
                              .props}
                            maximumValue={100}
                            minimumValue={5}
                            step={5}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.SliderStyles(theme)['Slider']
                                  .style,
                                { marginLeft: 0, width: '100%' }
                              ),
                              dimensions.width
                            )}
                            value={radiusValue}
                          />
                        </View>
                        {/* InputView */}
                        <View
                          style={StyleSheet.applyWidth(
                            { flex: 0.2, marginLeft: 8, width: 50 },
                            dimensions.width
                          )}
                        >
                          {/* RadiusInput */}
                          <NumberInput
                            changeTextDelay={500}
                            onChangeText={newRadiusInputValue => {
                              try {
                                setRadiusValue(newRadiusInputValue);
                                toggleCircle();
                              } catch (err) {
                                Sentry.captureException(err);
                                console.error(err);
                              }
                            }}
                            placeholder={'Enter a number...'}
                            webShowOutline={true}
                            {...GlobalStyles.NumberInputStyles(theme)[
                              'Number Input'
                            ].props}
                            editable={true}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.NumberInputStyles(theme)[
                                'Number Input'
                              ].style,
                              dimensions.width
                            )}
                            value={radiusValue}
                          />
                        </View>
                        {/* Text 2 */}
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            dimensions.width
                          )}
                        >
                          {'  miles'}
                        </Text>
                      </View>
                    )}
                  </>
                  {/* GenderFiltersView */}
                  <>
                    {props.route?.params?.for_explore ??
                    defaultProps.for_explore ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { flexDirection: 'row', marginTop: 15 },
                          dimensions.width
                        )}
                      >
                        {/* LabelView */}
                        <View
                          style={StyleSheet.applyWidth(
                            { flex: 0.25, marginRight: 8 },
                            dimensions.width
                          )}
                        >
                          {/* Label */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              dimensions.width
                            )}
                          >
                            {'Gender :'}
                          </Text>
                        </View>

                        <View
                          style={StyleSheet.applyWidth(
                            { flex: 1, flexDirection: 'row' },
                            dimensions.width
                          )}
                        >
                          <SimpleStyleFlatList
                            data={[
                              { label: 'Male', value: 'male' },
                              { label: 'Female', value: 'female' },
                              { label: 'Other', value: 'other' },
                              {
                                label: 'Prefer not to say',
                                value: 'prefer-not-to-say',
                              },
                            ]}
                            horizontal={false}
                            inverted={false}
                            keyExtractor={(listData, index) =>
                              listData?.id ??
                              listData?.uuid ??
                              index?.toString() ??
                              JSON.stringify(listData)
                            }
                            keyboardShouldPersistTaps={'never'}
                            listKey={'jj81kT2a'}
                            nestedScrollEnabled={false}
                            onEndReachedThreshold={0.5}
                            renderItem={({ item, index }) => {
                              const listData = item;
                              return (
                                <Pressable
                                  onPress={() => {
                                    try {
                                      const newList = toggleItem(
                                        selectedGenders,
                                        listData?.value
                                      );
                                      setSelectedGenders(newList);
                                    } catch (err) {
                                      Sentry.captureException(err);
                                      console.error(err);
                                    }
                                  }}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        marginBottom: 12,
                                        marginRight: 6,
                                        minWidth: 100,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          height: '100%',
                                          position: 'absolute',
                                          width: '100%',
                                          zIndex: 3,
                                        },
                                        dimensions.width
                                      )}
                                    />
                                    <Checkbox
                                      onPress={newCheckboxValue => {
                                        const checkboxValue = newCheckboxValue;
                                        try {
                                          console.log();
                                          console.log(selectedGenders);
                                        } catch (err) {
                                          Sentry.captureException(err);
                                          console.error(err);
                                        }
                                      }}
                                      defaultValue={selectedGenders.includes(
                                        listData?.value
                                      )}
                                      disabled={true}
                                    />
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.label}
                                    </Text>
                                  </View>
                                </Pressable>
                              );
                            }}
                            showsHorizontalScrollIndicator={true}
                            showsVerticalScrollIndicator={true}
                            numColumns={2}
                          />
                        </View>
                      </View>
                    )}
                  </>
                  {/* MinimumAgeView */}
                  <>
                    {props.route?.params?.for_explore ??
                    defaultProps.for_explore ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        {/* LabelView */}
                        <View
                          style={StyleSheet.applyWidth(
                            { flex: 0.25 },
                            dimensions.width
                          )}
                        >
                          {/* Label */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              dimensions.width
                            )}
                          >
                            {'Min Age :'}
                          </Text>
                        </View>
                        {/* SliderView */}
                        <View
                          style={StyleSheet.applyWidth(
                            { flex: 0.55 },
                            dimensions.width
                          )}
                        >
                          {/* MinimumAgeSlider */}
                          <Slider
                            onValueChange={newMinimumAgeSliderValue => {
                              const handler = async () => {
                                try {
                                  /* hidden 'Log to Console' action */
                                  setMinAgeValue(newMinimumAgeSliderValue);
                                  await waitUtil({ milliseconds: 50 });
                                  if (newMinimumAgeSliderValue >= maxAgeValue) {
                                    console.log('should be called');
                                    setMinAgeValue(maxAgeValue);
                                  } else {
                                    setMinAgeValue(newMinimumAgeSliderValue);
                                  }
                                } catch (err) {
                                  Sentry.captureException(err);
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            {...GlobalStyles.SliderStyles(theme)['Slider']
                              .props}
                            maximumValue={99 ?? 5000}
                            minimumValue={0}
                            step={1}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.SliderStyles(theme)['Slider']
                                  .style,
                                { marginLeft: 0, width: '100%' }
                              ),
                              dimensions.width
                            )}
                            value={minAgeValue}
                          />
                        </View>
                        {/* InputView */}
                        <View
                          style={StyleSheet.applyWidth(
                            { flex: 0.2 },
                            dimensions.width
                          )}
                        >
                          {/* MinAgeInput */}
                          <NumberInput
                            changeTextDelay={500}
                            onChangeText={newMinAgeInputValue => {
                              try {
                                setMinAgeValue(newMinAgeInputValue);
                              } catch (err) {
                                Sentry.captureException(err);
                                console.error(err);
                              }
                            }}
                            webShowOutline={true}
                            {...GlobalStyles.NumberInputStyles(theme)[
                              'Number Input'
                            ].props}
                            editable={true}
                            keyboardType={'numeric'}
                            placeholder={'Enter your budget...'}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.NumberInputStyles(theme)[
                                  'Number Input'
                                ].style,
                                { width: 60 }
                              ),
                              dimensions.width
                            )}
                            value={minAgeValue}
                          />
                        </View>
                      </View>
                    )}
                  </>
                  {/* MaximumAgeValue */}
                  <>
                    {props.route?.params?.for_explore ??
                    defaultProps.for_explore ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        {/* LabelView */}
                        <View
                          style={StyleSheet.applyWidth(
                            { flex: 0.25 },
                            dimensions.width
                          )}
                        >
                          {/* Label */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              dimensions.width
                            )}
                          >
                            {'Max Age :'}
                          </Text>
                        </View>
                        {/* SliderView */}
                        <View
                          style={StyleSheet.applyWidth(
                            { flex: 0.55 },
                            dimensions.width
                          )}
                        >
                          {/* MaximumAgeSlider */}
                          <Slider
                            onValueChange={newMaximumAgeSliderValue => {
                              const handler = async () => {
                                try {
                                  setMaxAgeValue(newMaximumAgeSliderValue);
                                  await waitUtil({ milliseconds: 50 });
                                  if (newMaximumAgeSliderValue < minAgeValue) {
                                    setMaxAgeValue(minAgeValue);
                                  } else {
                                    setMaxAgeValue(newMaximumAgeSliderValue);
                                  }
                                } catch (err) {
                                  Sentry.captureException(err);
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            {...GlobalStyles.SliderStyles(theme)['Slider']
                              .props}
                            maximumValue={99 ?? 5000}
                            minimumValue={0}
                            step={1}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.SliderStyles(theme)['Slider']
                                  .style,
                                { marginLeft: 0, width: '100%' }
                              ),
                              dimensions.width
                            )}
                            value={maxAgeValue}
                          />
                        </View>
                        {/* InputView */}
                        <View
                          style={StyleSheet.applyWidth(
                            { flex: 0.2 },
                            dimensions.width
                          )}
                        >
                          {/* MaxAgeInput */}
                          <NumberInput
                            changeTextDelay={500}
                            onChangeText={newMaxAgeInputValue => {
                              try {
                                setMaxAgeValue(newMaxAgeInputValue);
                              } catch (err) {
                                Sentry.captureException(err);
                                console.error(err);
                              }
                            }}
                            webShowOutline={true}
                            {...GlobalStyles.NumberInputStyles(theme)[
                              'Number Input'
                            ].props}
                            editable={true}
                            keyboardType={'numeric'}
                            placeholder={'Enter your budget...'}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.NumberInputStyles(theme)[
                                  'Number Input'
                                ].style,
                                { width: 60 }
                              ),
                              dimensions.width
                            )}
                            value={maxAgeValue}
                          />
                        </View>
                      </View>
                    )}
                  </>
                  {/* NearByUsersGPSFetch */}
                  <>
                    {!(
                      promotionType === 'gps' &&
                      !(
                        props.route?.params?.for_category ??
                        defaultProps.for_category
                      )
                    ) ? null : (
                      <XanoServiceApi.FetchGetUsersNearbyPOST
                        genders={selectedGenders}
                        handlers={{
                          onData: nearByUsersGPSFetchData => {
                            try {
                              setMaxUserInRegion(
                                nearByUsersGPSFetchData?.estimate &&
                                  (nearByUsersGPSFetchData?.estimate)[1]
                              );
                            } catch (err) {
                              Sentry.captureException(err);
                              console.error(err);
                            }
                          },
                        }}
                        lat={
                          (
                            props.route?.params?.selectedAddress ??
                            defaultProps.selectedAddress
                          )?.coords?.data?.lat
                        }
                        lng={
                          (
                            props.route?.params?.selectedAddress ??
                            defaultProps.selectedAddress
                          )?.coords?.data?.lng
                        }
                        max_age={maxAgeValue}
                        min_age={minAgeValue}
                        radius={milesToMeters(radiusValue)}
                        zip_codes={[]}
                      >
                        {({ loading, error, data, refetchGetUsersNearby }) => {
                          const nearByUsersGPSFetchData = data?.json;
                          if (loading) {
                            return (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                  },
                                  dimensions.width
                                )}
                              >
                                <ActivityIndicator
                                  animating={true}
                                  hidesWhenStopped={true}
                                  size={'small'}
                                  {...GlobalStyles.ActivityIndicatorStyles(
                                    theme
                                  )['Activity Indicator'].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.ActivityIndicatorStyles(theme)[
                                      'Activity Indicator'
                                    ].style,
                                    dimensions.width
                                  )}
                                />
                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    dimensions.width
                                  )}
                                >
                                  {'Searching...'}
                                </Text>
                              </View>
                            );
                          }

                          if (
                            error ||
                            data?.status < 200 ||
                            data?.status >= 300
                          ) {
                            return (
                              <>
                                {/* View 2 */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      flexDirection: 'row',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    accessible={true}
                                    {...GlobalStyles.TextStyles(theme)['Text']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        {
                                          color: theme.colors.background.danger,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {'Please provide a valid radius'}
                                  </Text>
                                </View>
                              </>
                            );
                          }

                          return (
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    fontFamily: 'Poppins_400Regular_Italic',
                                    marginBottom: 8,
                                    marginTop: 8,
                                    textAlign: 'center',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'There are between '}
                              {nearByUsersGPSFetchData?.estimate?.[0]}
                              {' - '}
                              {nearByUsersGPSFetchData?.estimate &&
                                (nearByUsersGPSFetchData?.estimate)[1]}
                              {' users with in this area.'}
                              {null}
                            </Text>
                          );
                        }}
                      </XanoServiceApi.FetchGetUsersNearbyPOST>
                    )}
                  </>
                  {/* NearByUsersZIPFetch */}
                  <>
                    {!(
                      promotionType === 'zip' &&
                      !(
                        props.route?.params?.for_category ??
                        defaultProps.for_category
                      )
                    ) ? null : (
                      <XanoServiceApi.FetchGetUsersNearbyPOST
                        genders={selectedGenders}
                        handlers={{
                          onData: nearByUsersZIPFetchData => {
                            try {
                              setMaxUserInRegion(
                                nearByUsersZIPFetchData?.estimate &&
                                  (nearByUsersZIPFetchData?.estimate)[1]
                              );
                            } catch (err) {
                              Sentry.captureException(err);
                              console.error(err);
                            }
                          },
                        }}
                        lat={
                          (
                            props.route?.params?.selectedAddress ??
                            defaultProps.selectedAddress
                          )?.coords?.data?.lat
                        }
                        lng={
                          (
                            props.route?.params?.selectedAddress ??
                            defaultProps.selectedAddress
                          )?.coords?.data?.lng
                        }
                        max_age={maxAgeValue}
                        min_age={minAgeValue}
                        radius={0}
                        zip_codes={selectedZipCodes}
                      >
                        {({ loading, error, data, refetchGetUsersNearby }) => {
                          const nearByUsersZIPFetchData = data?.json;
                          if (loading) {
                            return (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                  },
                                  dimensions.width
                                )}
                              >
                                <ActivityIndicator
                                  animating={true}
                                  hidesWhenStopped={true}
                                  size={'small'}
                                  {...GlobalStyles.ActivityIndicatorStyles(
                                    theme
                                  )['Activity Indicator'].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.ActivityIndicatorStyles(theme)[
                                      'Activity Indicator'
                                    ].style,
                                    dimensions.width
                                  )}
                                />
                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    dimensions.width
                                  )}
                                >
                                  {'Searching...'}
                                </Text>
                              </View>
                            );
                          }

                          if (
                            error ||
                            data?.status < 200 ||
                            data?.status >= 300
                          ) {
                            return (
                              <>
                                {/* View 2 */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      flexDirection: 'row',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    accessible={true}
                                    {...GlobalStyles.TextStyles(theme)['Text']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        {
                                          color: theme.colors.background.danger,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {'Please provide a valid radius'}
                                  </Text>
                                </View>
                              </>
                            );
                          }

                          return (
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    fontFamily: 'Poppins_400Regular_Italic',
                                    marginBottom: 8,
                                    marginTop: 8,
                                    textAlign: 'center',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'There are between '}
                              {nearByUsersZIPFetchData?.estimate?.[0]}
                              {' - '}
                              {nearByUsersZIPFetchData?.estimate &&
                                (nearByUsersZIPFetchData?.estimate)[1]}
                              {' users with in this area.'}
                            </Text>
                          );
                        }}
                      </XanoServiceApi.FetchGetUsersNearbyPOST>
                    )}
                  </>
                  {/* BudgetView */}
                  <>
                    {props.route?.params?.for_category ??
                    defaultProps.for_category ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        {/* LabelView */}
                        <View
                          style={StyleSheet.applyWidth(
                            { flex: 0.25 },
                            dimensions.width
                          )}
                        >
                          {/* Label */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              dimensions.width
                            )}
                          >
                            {'Budget :'}
                          </Text>
                        </View>
                        {/* SliderView */}
                        <View
                          style={StyleSheet.applyWidth(
                            { flex: 0.55 },
                            dimensions.width
                          )}
                        >
                          {/* BudgetSlider */}
                          <Slider
                            onValueChange={newBudgetSliderValue => {
                              try {
                                setBudgetValue(newBudgetSliderValue);
                              } catch (err) {
                                Sentry.captureException(err);
                                console.error(err);
                              }
                            }}
                            {...GlobalStyles.SliderStyles(theme)['Slider']
                              .props}
                            maximumValue={
                              getPercent(availableBalance, 1) ?? 5000
                            }
                            minimumValue={10}
                            step={1}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.SliderStyles(theme)['Slider']
                                  .style,
                                { marginLeft: 0, width: '100%' }
                              ),
                              dimensions.width
                            )}
                            value={budgetValue}
                          />
                        </View>
                        {/* InputView */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flex: 0.2,
                              flexDirection: 'row',
                            },
                            dimensions.width
                          )}
                        >
                          {/* BudgetInput */}
                          <NumberInput
                            changeTextDelay={500}
                            onChangeText={newBudgetInputValue => {
                              try {
                                setBudgetValue(newBudgetInputValue);
                              } catch (err) {
                                Sentry.captureException(err);
                                console.error(err);
                              }
                            }}
                            webShowOutline={true}
                            {...GlobalStyles.NumberInputStyles(theme)[
                              'Number Input'
                            ].props}
                            editable={true}
                            keyboardType={'numeric'}
                            placeholder={'Enter your budget...'}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.NumberInputStyles(theme)[
                                  'Number Input'
                                ].style,
                                { width: 60 }
                              ),
                              dimensions.width
                            )}
                            value={budgetValue}
                          />
                          {/* Text 3 */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                { marginLeft: 4 }
                              ),
                              dimensions.width
                            )}
                          >
                            {'$'}
                          </Text>
                        </View>
                      </View>
                    )}
                  </>
                  {/* CategoryPromoBudgetSlider */}
                  <>
                    {!(
                      props.route?.params?.for_category ??
                      defaultProps.for_category
                    ) ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        {/* LabelView */}
                        <View
                          style={StyleSheet.applyWidth(
                            { flex: 0.25 },
                            dimensions.width
                          )}
                        >
                          {/* Label */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              dimensions.width
                            )}
                          >
                            {'Budget :'}
                          </Text>
                        </View>
                        {/* SliderView */}
                        <View
                          style={StyleSheet.applyWidth(
                            { flex: 0.55 },
                            dimensions.width
                          )}
                        >
                          {/* BudgetSlider */}
                          <Slider
                            onValueChange={newBudgetSliderValue => {
                              try {
                                setBudgetValue(newBudgetSliderValue);
                                setExpiryDateValue(
                                  getNMonthsFromNow(newBudgetSliderValue / 5)
                                );
                              } catch (err) {
                                Sentry.captureException(err);
                                console.error(err);
                              }
                            }}
                            {...GlobalStyles.SliderStyles(theme)['Slider']
                              .props}
                            maximumValue={
                              maxPossible(getPercent(availableBalance, 1), 5) ??
                              5000
                            }
                            minimumValue={5}
                            step={5}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.SliderStyles(theme)['Slider']
                                  .style,
                                { marginLeft: 0, width: '100%' }
                              ),
                              dimensions.width
                            )}
                            value={budgetValue}
                          />
                        </View>
                        {/* InputView */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flex: 0.2,
                              flexDirection: 'row',
                            },
                            dimensions.width
                          )}
                        >
                          {/* BudgetInput */}
                          <NumberInput
                            changeTextDelay={500}
                            onChangeText={newBudgetInputValue => {
                              try {
                                setBudgetValue(newBudgetInputValue);
                              } catch (err) {
                                Sentry.captureException(err);
                                console.error(err);
                              }
                            }}
                            webShowOutline={true}
                            {...GlobalStyles.NumberInputStyles(theme)[
                              'Number Input'
                            ].props}
                            editable={false ?? true}
                            keyboardType={'numeric'}
                            placeholder={'Enter your budget...'}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.NumberInputStyles(theme)[
                                  'Number Input'
                                ].style,
                                { width: 60 }
                              ),
                              dimensions.width
                            )}
                            value={budgetValue}
                          />
                          {/* Text 3 */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                { marginLeft: 4 }
                              ),
                              dimensions.width
                            )}
                          >
                            {'$'}
                          </Text>
                        </View>
                      </View>
                    )}
                  </>
                  {/* EstimationText */}
                  <>
                    {!!(
                      props.route?.params?.for_category ??
                      defaultProps.for_category
                    ) ? null : (
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          dimensions.width
                        )}
                      >
                        {'Estimated Clicks will be : '}
                        {estimateClicks()}
                        {'.\n\nUsers will receive : '}
                        {amountToUser}
                        {'¢ per click\nMailboxx will receive : '}
                        {amountToMailboxx}
                        {'¢ per click'}
                      </Text>
                    )}
                  </>
                  {/* CategoryPromoEstimationText */}
                  <>
                    {!(
                      props.route?.params?.for_category ??
                      defaultProps.for_category
                    ) ? null : (
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          dimensions.width
                        )}
                      >
                        {'Your promotion will be displayed for '}
                        {divide(budgetValue, 5)}{' '}
                        {divide(budgetValue, 5) === 1 ? 'month' : 'months'}
                        {'. It will automatically expire at '}
                        {formatDate(expiryDateValue)}
                        {'\n\nUsers will receive : '}
                        {amountToUser}
                        {'¢ per click\nMailboxx will receive : '}
                        {amountToMailboxx}
                        {'¢ per click'}
                      </Text>
                    )}
                  </>
                </View>
              </SimpleStyleKeyboardAwareScrollView>
            </View>
          </View>
          {/* Modal Footer */}
          <View>
            {/* errorText */}
            <>
              {!errorMessage ? null : (
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: theme.colors.background.danger,
                        fontFamily: 'Poppins_400Regular',
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {errorMessage}
                </Text>
              )}
            </>
            {/* AddPromotionButton */}
            <Button
              iconPosition={'left'}
              onPress={() => {
                const handler = async () => {
                  try {
                    if (!validateForm()) {
                      return;
                    }
                    trackAction(
                      Variables,
                      segment,
                      'Clicked Submit Button',
                      undefined
                    );
                    setIsPosting(true);
                    if (promotionType === 'gps') {
                      const gpsPromotionResult = (
                        await promotionsServiceAddPromotionPOST.mutateAsync({
                          addresses_id: (
                            props.route?.params?.selectedAddress ??
                            defaultProps.selectedAddress
                          )?.id,
                          amount_to_mailboxx: amountToMailboxx,
                          amount_to_user: amountToUser,
                          approved_zipcodes: null,
                          asset_type: promotionAssetType,
                          budget: dollarToCent(budgetValue),
                          business_categories_id: businessCategoryValue,
                          content: contentValue,
                          details_image_ids: extractIds(detailImages),
                          expires_at: expiryDateValue,
                          for_category:
                            props.route?.params?.for_category ??
                            defaultProps.for_category,
                          for_explore:
                            props.route?.params?.for_explore ??
                            defaultProps.for_explore,
                          genders: selectedGenders,
                          hero_image_id: heroImage?.id,
                          max_age: maxAgeValue,
                          min_age: minAgeValue,
                          radius: milesToMeters(radiusValue),
                          redirect_link: redirectLinkValue,
                          title: titleValue,
                        })
                      )?.json;
                      setIsPosting(false);
                      if (gpsPromotionResult?.message) {
                        setErrorMessage(gpsPromotionResult?.message);
                      }
                      if (gpsPromotionResult?.message) {
                        return;
                      }
                    } else {
                      if (promotionType === 'zip') {
                        const zipPromotionResult = (
                          await promotionsServiceAddPromotionPOST.mutateAsync({
                            addresses_id: (
                              props.route?.params?.selectedAddress ??
                              defaultProps.selectedAddress
                            )?.id,
                            amount_to_mailboxx: amountToMailboxx,
                            amount_to_user: amountToUser,
                            approved_zipcodes: selectedZipCodes,
                            asset_type: promotionAssetType,
                            budget: dollarToCent(budgetValue),
                            business_categories_id: businessCategoryValue,
                            content: contentValue,
                            details_image_ids: extractIds(detailImages),
                            expires_at: expiryDateValue,
                            for_category:
                              props.route?.params?.for_category ??
                              defaultProps.for_category,
                            for_explore:
                              props.route?.params?.for_explore ??
                              defaultProps.for_explore,
                            genders: selectedGenders,
                            hero_image_id: heroImage?.id,
                            max_age: maxAgeValue,
                            min_age: minAgeValue,
                            radius: 0,
                            redirect_link: redirectLinkValue,
                            title: titleValue,
                          })
                        )?.json;
                        setIsPosting(false);
                        if (zipPromotionResult?.message) {
                          setErrorMessage(zipPromotionResult?.message);
                        }
                        if (zipPromotionResult?.message) {
                          return;
                        }
                      } else {
                        const globalPromotionResult = (
                          await promotionsServiceAddGlobalPromotionPOST.mutateAsync(
                            {
                              amountToMailboxx: amountToMailboxx,
                              amountToUser: amountToUser,
                              assetType: promotionAssetType,
                              budget: dollarToCent(budgetValue),
                              business_categories_id: businessCategoryValue,
                              content: contentValue,
                              detailImageIds: extractIds(detailImages),
                              expires_at: expiryDateValue,
                              for_category:
                                props.route?.params?.for_category ??
                                defaultProps.for_category,
                              for_explore:
                                props.route?.params?.for_explore ??
                                defaultProps.for_explore,
                              genders: selectedGenders,
                              heroImageId: heroImage?.id,
                              max_age: maxAgeValue,
                              min_age: minAgeValue,
                              redirect_link: redirectLinkValue,
                              title: titleValue,
                            }
                          )
                        )?.json;
                        setIsPosting(false);
                        if (globalPromotionResult?.message) {
                          setErrorMessage(globalPromotionResult);
                        }
                        if (globalPromotionResult?.message) {
                          return;
                        }
                      }
                    }

                    setShowModal(false);
                    navigation.navigate('MyPromotionsScreen');
                  } catch (err) {
                    Sentry.captureException(err);
                    console.error(err);
                  }
                };
                handler();
              }}
              {...GlobalStyles.ButtonStyles(theme)['Button'].props}
              loading={isPosting}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['Button'].style,
                  {
                    backgroundColor: palettes.App.customGreen,
                    marginBottom: 10,
                    marginTop: 10,
                  }
                ),
                dimensions.width
              )}
              title={'Submit'}
            />
          </View>
        </View>
      </Modal>
    </ScreenContainer>
  );
};

export default withTheme(AddPromotionFormScreen);